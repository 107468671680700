import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import Loading from "./common/Loading";
import LoadError from "./common/LoadError";
import { Api } from "../utils/Api";
import { withRouter } from "react-router-dom";
import Dashboard from "./audit/report/Dashboard";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Gsc from "./audit/report/reports/Gsc";
import Seo from "./audit/report/reports/Seo";
import Schema from "./audit/report/reports/Schema";
import Google from "./audit/report/reports/Google";
import Social from "./audit/report/reports/Social";
import Sitemaps from "./audit/report/reports/Sitemaps";
import Performance from "./audit/report/reports/Performance";
import FeedbackModal from "./common/feedback/FeedbackModal";
import RoleFilterModal from "./common/RoleFilter/RoleFilterModal";
import UseAuthWrapper from "./common/UseAuthWrapper";
import MainContent from "./common/MainContent";

class Audit extends Component {
  state = {
    availableReportsLoaded: false,
    availableReportsLoadError: false,
    auditLoaded: false,
    auditLoadError: false,
    auditFound: true,
    selectedReports: null,
    reports: null,
    auditData: null,
    showUpdateFailedAlert: false,
    getAuditAsyncProcess: null,
    auditId: this.props.match.params.auditId,
    pageURL: "",
    sitemapsId: null,
    sitemapData: null,
    getSitemapsAsyncProcess: null,
    totalApiCalls: 0,
    timedOuts: 0,
    sideMenuCollapseButtonHover: false
  };
  ROUTES = [
    {
      key: "dashboard",
      path: "/audit/:auditId/dashboard",
      component: () => (
        <Dashboard auditId={this.state.auditId} history={this.props.history} />
      )
    },
    {
      key: "google",
      path: "/audit/:auditId/google",
      component: () => (
        <Google auditId={this.state.auditId} history={this.props.history} />
      )
    },
    {
      key: "analytics",
      path: "/audit/:auditId/analytics",
      component: () => (
        <Gsc auditId={this.state.auditId} history={this.props.history} />
      )
    },
    {
      key: "social",
      path: "/audit/:auditId/social",
      component: () => (
        <Social auditId={this.state.auditId} history={this.props.history} />
      )
    },
    {
      key: "seo",
      path: "/audit/:auditId/seo",
      component: () => (
        <Seo auditId={this.state.auditId} history={this.props.history} />
      )
    },
    {
      key: "schema",
      path: "/audit/:auditId/schema",
      component: () => (
        <Schema
          auditId={this.state.auditId}
          history={this.props.history}
          url={this.state.auditData.audit.auditUrl}
        />
      )
    },
    {
      key: "performance",
      path: "/audit/:auditId/performance",
      component: () => (
        <Performance
          auditId={this.state.auditId}
          history={this.props.history}
        />
      )
    },
    {
      key: "sitemaps",
      path: "/audit/:auditId/sitemaps",
      component: () => (
        <Sitemaps history={this.props.history} auditId={this.state.auditId} />
      )
    },
    {
      key: "speed",
      path: "/audit/:auditId/speed",
      component: () => (
        <Dashboard
          auditId={this.props.match.params.auditId}
          history={this.props.history}
        />
      )
    },
    {
      key: "mobile",
      path: "/audit/:auditId/mobile",
      component: () => (
        <Dashboard
          auditId={this.props.match.params.auditId}
          history={this.props.history}
        />
      )
    }
  ];

  constructor(props) {
    super(props);
    this.feedbackModalComponent = React.createRef();
    this.userRoleModalComponent = React.createRef();
  }

  componentDidMount() {
    let {
      auditId,
      availableReportsLoaded,
      auditLoaded,
      availableReportsLoadError,
      auditLoadError
    } = this.state;
    if (auditId !== this.props.match.params.auditId) {
      this.loadAudit(this.props.match.params.auditId);
    } else if (
      !availableReportsLoaded ||
      !auditLoaded ||
      availableReportsLoadError ||
      auditLoadError
    ) {
      this.loadAudit(auditId);
    }
  }

  componentDidUpdate() {
    if (this.state.auditId !== this.props.match.params.auditId) {
      window.location.reload();
    }
  }

  loadAudit = id => {
    Promise.all([this.getAvailableReports(), this.getAuditData(true, id)]).then(
      ([getAvailableReportsResult, getAuditDataResult]) => {
        this.setState({
          ...this.state,
          ...getAvailableReportsResult,
          ...getAuditDataResult,
          auditId: id
        });
      }
    );
  };

  onReportSelectionChange = selectedReports => {
    this.setState({ selectedReports });
  };

  goBackToStart = () => {
    this.props.history.push(`/start`);
  };

  getAuditData = (firstLoad, auditId) => {
    return Api.getAuditData(auditId).then(
      auditData => {
        return {
          auditLoaded: true,
          auditData: auditData,
          showUpdateFailedAlert: false
        };
      },
      error => {
        if (firstLoad) {
          if (error.code === 404) {
            this.props.history.replace("/404");
          } else {
            return {
              auditLoaded: true,
              auditLoadError: true
            };
          }
        } else {
          if (error.code >= 500 && error.code < 600) {
            this.setState({
              auditLoaded: true,
              auditLoadError: true
            });
          } else {
            // on update just show update failed Alert
            return { showUpdateFailedAlert: true };
          }
        }
      }
    );
  };

  getAvailableReports = () => {
    return Api.getAvailableReports().then(
      availableReports => {
        return {
          availableReportsLoaded: true,
          selectedReports: availableReports.selectedReports,
          reports: availableReports.reports
        };
      },
      error => {
        return {
          availableReportsLoaded: true,
          availableReportsLoadError: true
        };
      }
    );
  };

  getReportData = (reports, reportType, performanceScore) => {
    const report = reports.find(report => {
      return report.type === reportType;
    });
    return {
      score:
        report.type === "performance" ? performanceScore : report.rules.score,
      failed: report.rules.failed,
      isProcessed: report.isProcessed,
      totalRules: report.rules.total
    };
  };

  render() {
    const {
      auditId,
      availableReportsLoadError,
      auditLoadError,
      availableReportsLoaded,
      auditLoaded,
      auditFound
    } = this.state;
    if (availableReportsLoadError || auditLoadError) return <LoadError />;

    if (!availableReportsLoaded || !auditLoaded) return <Loading />;

    if (!auditFound) {
      return (
        <div id="audit-not-found">
          Solicited audit with id
          <strong> {auditId}</strong> was not found
        </div>
      );
    }

    const routes = this.ROUTES.map(route => (
      <Route key={route.key} path={route.path} component={route.component} />
    ));
    return (
      <Router>
        <div>
          {this.state.showUpdateFailedAlert ? (
            <Alert
              style={styles.updateFailedAlert}
              message="Could not update Audit due to unstable Internet connection, retrying"
              banner
            />
          ) : null}
          <MainContent
            auditData={this.state.auditData}
            auditId={this.props.match.params.auditId}
            routes={routes}
            goBackToStart={this.goBackToStart}
            reports={this.state.reports}
            selectedReports={this.state.selectedReports}
            onReportSelectionChange={this.onReportSelectionChange}
            loadAudit={this.loadAudit}
            userRoleModalComponent={this.userRoleModalComponent}
            feedbackModalComponent={this.feedbackModalComponent}
          />
          <UseAuthWrapper>
            <FeedbackModal ref={this.feedbackModalComponent} />
          </UseAuthWrapper>
          <UseAuthWrapper>
            <RoleFilterModal ref={this.userRoleModalComponent} />
          </UseAuthWrapper>
        </div>
      </Router>
    );
  }
}

Audit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      auditId: PropTypes.string.isRequired
    })
  })
};

const styles = {
  sideMenuContainer: {
    marginTop: 34,
    width: "100%"
  },
  logoHidden: {
    display: "none"
  },
  scoreSummary: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 30,
    flexWrap: "wrap"
  },
  updateFailedAlert: {
    position: "fixed",
    top: 0,
    zIndex: 2,
    right: 0
  }
};

export default withRouter(Audit);
