import React, { Component } from "react";
import PropTypes from "prop-types";

import Detail from "../../Detail";
import HowToFix from "../HowToFix/HowToFix";

class SchemaMarkupDetail extends Component {
  render() {
    const { rule } = this.props;
    return (
      <div style={styles.panelContent}>
        <div>
          <div style={styles.details}>
            {rule.details.map((detail, index) => {
              return (
                <Detail
                  key={detail.id || index}
                  type={detail.type}
                  data={detail.data}
                  name={rule.name}
                  exportButton={{ enable: true, url: rule.url }}
                />
              );
            })}
          </div>
          <HowToFix howToFixArray={rule.howToFix} />
        </div>
      </div>
    );
  }
}

const styles = {
  details: {
    marginTop: "25px"
  },
  panelContent: {
    border: 0,
    overflow: "hidden",
    backgroundColor: "rgb(237, 240, 242)",
    margin: "8px",
    padding: "10px"
  }
};

export default SchemaMarkupDetail;
