import { Icon } from "antd";
import React from "react";
import colors from "../../../../../../constants/colors";

const styles = {
  failedRuleIcon: {
    color: colors.failed
  }
};

const FailedIcon = () => <Icon type="stop" style={styles.failedRuleIcon} />;

export default FailedIcon;
