import React, { Component } from "react";
import { Collapse } from "antd";

class CommonCollapse extends Component {
  render() {
    const { children } = this.props;
    return (
      <Collapse bordered={false} {...this.props}>
        {children}
      </Collapse>
    );
  }
}

export default CommonCollapse;
