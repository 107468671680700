import React from "react";

const copyButton = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m13.8809406 11.4740361.9752356-2.48371031 3.1438238 6.24213971-6.6169207 2.7675345.9374845-2.4677764s-8.67330449-4.1876511-8.30942629-11.5322236c2.84810724 5.91449708 9.86980309 7.4740361 9.86980309 7.4740361zm8.1190594-9.378798v-2.0952381h-22v22h2.0952381v-19.9047619zm-16.51465.9047619c.46305.79485 1.0059 1.4931 1.59705 2.1h14.8176v16.8h-16.8v-8.9754c-.74865-.7896-1.47-1.69575-2.1-2.7048v13.7802h21v-21z"
      fill="#030075"
      fillRule="evenodd"
    />
  </svg>
);

export { copyButton };
