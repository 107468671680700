import React, { Component } from "react";
import moment from "moment";
import numeral from "numeral";
import PropTypes from "prop-types";
import {
  LineChart,
  XAxis,
  Tooltip,
  Line,
  YAxis,
  CartesianGrid,
  Label
} from "recharts";

import "./AnalyticsGraph.css";

const NUMBER_SIMPLIFIER = {
  millions: {
    value: 1000000,
    appendString: "M",
    formatFunction: value => numeral(value / 1000000).format("0")
  },
  miles: {
    value: 1000,
    appendString: "K",
    formatFunction: value => numeral(value / 1000).format("0, 0")
  },
  hundreds: {
    value: 1,
    appendString: "",
    formatFunction: value => value.toFixed(2)
  },
  decimals: {
    value: 0,
    appendString: "",
    formatFunction: value => numeral(value).format("0%")
  }
};
const CHART_DATE_FORMAT = "D/M/YY";
const LABAEL_DATE_FORMAT = "MMM DD, YYYY";
const AnalyticsGraphTooltipTitle = label => (
  <span
    className="recharts-tooltip-label"
    style={{ margin: "0px", fontSize: "14px" }}
  >
    {moment(label).format(LABAEL_DATE_FORMAT)}
  </span>
);
const AnalyticsGraphTooltipItem = value => {
  const simplifier = Object.keys(NUMBER_SIMPLIFIER).find(
    simplifierKey => value >= NUMBER_SIMPLIFIER[simplifierKey].value
  );
  value =
    NUMBER_SIMPLIFIER[simplifier].formatFunction(value) +
    NUMBER_SIMPLIFIER[simplifier].appendString;
  return (
    <span className="recharts-tooltip-item-value" style={{ fontSize: "14px" }}>
      {value}
    </span>
  );
};

class AnalyticsGraph extends Component {
  render() {
    const { data, lines, errorLoadingMoreData } = this.props;
    const includesTotalClicks = lines.map(line => line.key).includes("clicks");
    const includesTotalImpressions = lines
      .map(line => line.key)
      .includes("impressions");

    if (errorLoadingMoreData) {
      return (
        <div className="reportContainer">
          <span>This URL does not have data yet</span>
        </div>
      );
    }

    return (
      <LineChart
        width={865}
        height={266}
        data={data}
        style={{
          backgroundColor: "white",
          fontFamily: "Avenir",
          fontSize: "12px"
        }}
      >
        <XAxis
          dataKey="date"
          minTickGap={15}
          tickSize={5}
          axisLine={false}
          tickFormatter={value => moment(value).format(CHART_DATE_FORMAT)}
          padding={{ top: "100px" }}
        />
        <Tooltip
          labelFormatter={AnalyticsGraphTooltipTitle}
          formatter={AnalyticsGraphTooltipItem}
        />
        {lines.length <= 2 && includesTotalClicks && (
          <YAxis
            type="number"
            tickFormatter={value => {
              const simplifier = Object.keys(NUMBER_SIMPLIFIER).find(
                simplifierKey => value >= NUMBER_SIMPLIFIER[simplifierKey].value
              );
              return (
                NUMBER_SIMPLIFIER[simplifier].formatFunction(value) +
                NUMBER_SIMPLIFIER[simplifier].appendString
              );
            }}
            yAxisId="totalClicks"
            orientation="left"
            width={100}
            padding={{ top: 50 }}
          >
            <Label value="Total Clicks" position="centerTop" angle={-90} />
          </YAxis>
        )}
        {lines.length <= 2 && includesTotalImpressions && (
          <YAxis
            type="number"
            orientation={includesTotalClicks ? "right" : "left"}
            width={100}
            yAxisId="totalImpressions"
            tickFormatter={value => {
              const simplifier = Object.keys(NUMBER_SIMPLIFIER).find(
                simplifierKey => value >= NUMBER_SIMPLIFIER[simplifierKey].value
              );
              return (
                NUMBER_SIMPLIFIER[simplifier].formatFunction(value) +
                NUMBER_SIMPLIFIER[simplifier].appendString
              );
            }}
          >
            <Label value="Total Impressions" position="centerTop" angle={-90} />
          </YAxis>
        )}
        <CartesianGrid strokeDasharray="3 3" />
        {lines &&
          lines.map(dataLine => (
            <Line
              key={dataLine.key}
              type="linear"
              name={dataLine.name}
              dataKey={dataLine.key}
              stroke={dataLine.color}
              dot={false}
              height={2000}
              width={2000}
              strokeWidth={3}
              yAxisId={dataLine.yAxisId}
            />
          ))}
      </LineChart>
    );
  }
}

AnalyticsGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      clicks: PropTypes.number,
      impressions: PropTypes.number,
      ctr: PropTypes.number,
      position: PropTypes.number
    })
  ).isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      color: PropTypes.string
    })
  ),
  errorLoadingMoreData: PropTypes.bool
};

export default AnalyticsGraph;
