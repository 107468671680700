import PropTypes from "prop-types";

const ImageObjectPropTypes = PropTypes.shape({
  url: PropTypes.string.isRequired
});

export default PropTypes.shape({
  headline: PropTypes.string.isRequired,
  publisher: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  datePublished: PropTypes.string,
  image: PropTypes.oneOfType([
    ImageObjectPropTypes,
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
    PropTypes.arrayOf(ImageObjectPropTypes).isRequired
  ]).isRequired
}).isRequired;
