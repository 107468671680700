import React, { Component } from "react";
import { Card, Icon } from "antd";
import PropTypes from "prop-types";
import utils from "./utils";
import envVars from "../../../envVars";
import { Api } from "../../../utils/Api";
import Loading from "../../common/Loading";
import LoadError from "../../common/LoadError";
import DashboardCard from "../../common/DashboardCard";
import CommonProgress from "../../common/CommonProgress";
import sadFaceIcon from "../../../assets/images/sad_face.svg";
import happyFaceIcon from "../../../assets/images/happy_face.svg";
import normalFaceIcon from "../../../assets/images/normal_face.svg";
import AuditServices from "../services/AuditDataSource/AuditDataSource";
import { isDevURL } from "../../../utils/reportUtils";

const { MAX_API_CALLS } = envVars();
const ASYNC_CALL_INTERVAL = 8000;
const DASHBOARD_CARDS = {
  serp: {
    descriptionText:
      "See how your article renders in Google SERPS and optimize with live data from Google Trends.",
    reportName: "Google SERPS",
    reportLink: "google",
    enable: true
  },
  gsc: {
    descriptionText:
      "See how your article URL performs using Google Search Console analytics.",
    reportName: "Search Console",
    reportLink: "analytics",
    enable: true,
    disableForDev: true
  },
  seo: {
    descriptionText: "Verify the URL's tags and evaluate its SEO hygiene.",
    reportName: "Page Health",
    reportLink: "seo",
    enable: true
  },
  sitemaps: {
    descriptionText: "See which Sitemaps contain this page.",
    reportName: "Sitemaps",
    reportLink: "sitemaps",
    enable: true,
    disableForDev: true
  },
  performance: {
    descriptionText: "Check your page speed and performance.",
    reportName: "Core Web Vitals",
    reportLink: "performance",
    enable: true,
    disableForDev: true
  },
  social: {
    descriptionText: "Review your social media tags and look at the preview.",
    reportName: "Social Tags",
    reportLink: "social",
    enable: true
  }
};
const excludeReportForDev = ["performance", "sitemaps", "gsc"];
class Dashboard extends Component {
  state = {
    auditData: null,
    auditId: this.props.auditId,
    auditLoaded: false,
    auditLoadError: false,
    getAuditAsyncProcess: null,
    timedOuts: 0,
    totalApiCalls: 0,
    showUpdateFailedAlert: false
  };

  componentDidMount() {
    this.getAuditData(true).then(getAuditDataResult => {
      this.setState({ ...this.state, ...getAuditDataResult });
    });
  }

  componentWillUnmount() {
    const { getAuditAsyncProcess } = this.state;
    clearTimeout(getAuditAsyncProcess);
  }

  render() {
    if (this.state.auditLoadError) return <LoadError />;

    if (!this.state.auditLoaded) return <Loading />;

    const {
      auditData: {
        audit: {
          auditUrl,
          score,
          reports: { nodes }
        }
      },
      showUpdateFailedAlert,
      totalApiCalls,
      auditId
    } = this.state;

    const testScoreStrokeColor = getStrokeColor(score);
    const faceIcon = getFaceIcon(score);
    const testDescriptionText = getTestDescriptionText(score);
    const isDev = isDevURL(auditUrl);

    const needsReload =
      !showUpdateFailedAlert && totalApiCalls >= MAX_API_CALLS;
    const reportNodes = isDev
      ? nodes.filter(node => !excludeReportForDev.includes(node.type))
      : nodes;

    const reports = AuditServices.sortReportsByType(reportNodes);
    const PerformanceReport = utils.getReportByName(
      reports,
      "Performance Report"
    );
    const dashboardTiles = Object.keys(DASHBOARD_CARDS)
      .filter(reportKey => !isDev || !DASHBOARD_CARDS[reportKey].disableForDev)
      .map(reportKey => {
        const cardInfo = DASHBOARD_CARDS[reportKey];
        let cardScore = {};
        if (cardInfo.enable) {
          if (reportKey === "performance") {
            cardScore = utils.getReportData(
              reports,
              "performance",
              PerformanceReport.extras.score
            );
          } else {
            cardScore = utils.getReportData(reports, reportKey);
          }
        }
        return (
          <DashboardCard
            key={`${reportKey}-card`}
            id={`${reportKey}-card`}
            descriptionText={cardInfo.descriptionText}
            reportName={cardInfo.reportName}
            reportLink={cardInfo.reportLink}
            auditId={auditId}
            reportScore={cardScore}
            needsReload={needsReload}
            enable={cardInfo.enable}
          />
        );
      });
    const {
      total: totalRules,
      failed: totalRulesFailed,
      loading: DashboardIsLoading
    } = utils.getAuditRulesData(reports);

    return (
      <div>
        <div style={styles.overallDiv}>
          <div style={styles.scoreDash}>
            <CommonProgress
              type="dashboard"
              loading={DashboardIsLoading}
              score={score}
              strokeColor={testScoreStrokeColor}
              width={135}
              strokeWidth={10}
              style={styles.progressScore}
              format={percent => (percent === 0 ? "" : `${percent}%`)}
              styleType="custom-progress-dashboard"
            />
            {DashboardIsLoading ? null : (
              <img
                src={faceIcon}
                alt="status"
                style={styles.faceIcon}
                height={24}
              />
            )}
          </div>
          <div style={styles.scoreList}>
            {DashboardIsLoading ? (
              <div>
                <p style={styles.header1}>
                  Calculating your overall article score...
                </p>
                {needsReload ? (
                  <p>
                    (Sorry, we're not able to calculate an overall score if any
                    of these tests are not being completed.)
                  </p>
                ) : null}
              </div>
            ) : (
              <div>
                <p style={styles.header1}>
                  Your overall article score is {testDescriptionText}
                </p>
                {needsReload ? (
                  <p>(Based on the tests we were able to complete)</p>
                ) : null}
                <p style={styles.header2}>
                  You have <b>{totalRulesFailed}</b> ( out of a possible
                  <b> {totalRules}</b> ) issues you can address to optimize this
                  page.
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <div style={styles.reloadDiv}>
            {needsReload ? (
              <div
                style={styles.reloadButton}
                onClick={e => {
                  e.preventDefault();
                  this.reloadTotalApiCalls();
                }}
              >
                <Icon type="reload" style={styles.iconStyles} />
                Audit stopped because some tests are taking too long. Click here
                to resume loading.
              </div>
            ) : null}
          </div>
          <Card
            title={
              <span style={styles.cardTitle}>
                Click a card below for more details on issues and suggestions to
                improve your search results.
              </span>
            }
            style={{ background: "#EDF0F2" }}
          >
            {dashboardTiles}
          </Card>
        </div>
      </div>
    );
  }

  getAuditData = firstLoad => {
    return Api.getAuditData(this.state.auditId).then(
      auditData => {
        const { status } = auditData.audit;
        let getAuditDataAsyncResult = {};
        if (status === "processing") {
          getAuditDataAsyncResult = this.getAuditDataAsync();
        } else {
          clearTimeout(this.state.getAuditAsyncProcess);
        }
        return {
          auditLoaded: true,
          auditData: auditData,
          showUpdateFailedAlert: false,
          ...getAuditDataAsyncResult
        };
      },
      error => {
        if (firstLoad) {
          if (error.code === 404) {
            this.props.history.replace("/404");
          } else {
            return {
              auditLoaded: true,
              auditLoadError: true
            };
          }
        } else {
          if (error.code >= 500 && error.code < 600) {
            return {
              auditLoaded: true,
              auditLoadError: true
            };
          } else {
            // on update just show update failed Alert
            const getAuditDataAsyncResult = this.getAuditDataAsync();
            return { showUpdateFailedAlert: true, ...getAuditDataAsyncResult };
          }
        }
      }
    );
  };

  getAuditDataAsync = (restart = false) => {
    const apiCalls = this.state.totalApiCalls;
    if (apiCalls < MAX_API_CALLS || restart) {
      const getAuditAsyncProcess = setTimeout(() => {
        this.getAuditData(false).then(getAuditDataResult => {
          this.setState({ ...getAuditDataResult });
        });
      }, ASYNC_CALL_INTERVAL);
      return {
        getAuditAsyncProcess,
        totalApiCalls: apiCalls + 1
      };
    }
    return {};
  };

  reloadTotalApiCalls = () => {
    const { timedOuts } = this.state;
    this.setState({
      totalApiCalls: 0,
      timedOuts: timedOuts + 1
    });
  };
}

const getStrokeColor = score => {
  const progressScoreColor = {
    passed: "#51C41B",
    warning: "#FFAC00",
    failed: "#FD093F"
  };
  if (score >= 0 && score <= 30) return progressScoreColor.failed;
  else if (score > 30 && score <= 80) return progressScoreColor.warning;
  else return progressScoreColor.passed;
};

const getFaceIcon = score => {
  const faceIcon = {
    passed: happyFaceIcon,
    warning: normalFaceIcon,
    failed: sadFaceIcon
  };
  if (score >= 0 && score <= 30) return faceIcon.failed;
  else if (score > 30 && score <= 80) return faceIcon.warning;
  else return faceIcon.passed;
};

const getTestDescriptionText = score => {
  const testDescriptionText = {
    passed: "excellent!",
    warning: "fair",
    failed: "poor",
    perfect: "perfect!"
  };
  if (score >= 0 && score <= 30) return testDescriptionText.failed;
  else if (score > 30 && score <= 80) return testDescriptionText.warning;
  else if (score === 100) return testDescriptionText.perfect;
  else return testDescriptionText.passed;
};

Dashboard.propTypes = {
  auditId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

const styles = {
  overallDiv: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: "16px",
    flexWrap: "wrap"
  },
  scoreDash: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
    flexDirection: "column",
    minWidth: 150,
    marginRight: 20
  },
  scoreList: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    minWidth: 400,
    maxWidth: 460
  },
  header1: {
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: "26px",
    fontWeight: 800,
    marginBottom: 0
  },
  header2: {
    color: "#777677",
    fontFamily: "Avenir",
    fontSize: "20px"
  },
  faceIcon: {
    marginTop: -25
  },
  progressScore: {
    fontSize: 32
  },
  reloadDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0",
    width: "100%"
  },
  reloadButton: {
    height: "24px",
    width: "auto",
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "22px",
    cursor: "pointer",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "25px",
    paddingRight: "16px",
    borderColor: "#8A8E9A"
  },
  iconStyles: {
    margin: "0 16px",
    fontSize: "16px",
    fontWeight: "900"
  },
  cardTitle: {
    color: "#5F6065",
    fontFamily: "Avenir",
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
    lineHeight: "20px"
  }
};

export default Dashboard;
