const createTitleValidation = ({ maxTitleLength }) => {
  return rawTitle => {
    const titleIsNotLarge = rawTitle.length <= maxTitleLength;
    const titleIsNotBlank = rawTitle.length > 0;
    const isValid = titleIsNotLarge && titleIsNotBlank;
    let title = rawTitle;
    if (!titleIsNotLarge) {
      while (title.length > maxTitleLength) {
        title = title.substr(0, title.lastIndexOf(" "));
      }
      title = `${title}...`;
    }

    return {
      text: title.trim(),
      isValid,
      charactersRemaining: maxTitleLength - rawTitle.length
    };
  };
};
export default createTitleValidation;
