import React from "react";
import cc from "classnames";
import SchemaPropTypes from "../../services/SchemaPropTypes";
import "./AMPCarouselItem.css";
import getRelativeTimeFromNow from "../../../common/services/getRelativeTimeFromNow";
import getImageSrcFromSchema from "../../services/getImageSrcFromSchema";

const AMPCarouselItem = props => {
  const { className, schema, hasAMP } = props;

  const {
    headline,
    publisher: {
      name: publisherName,
      logo: { url: logo }
    },
    image,
    datePublished
  } = schema;

  const imageUrl = getImageSrcFromSchema(image);

  const timeSincePublished = getRelativeTimeFromNow(datePublished);
  return (
    <div className={cc("amp-carousel", className)}>
      <div className="amp-carousel__card-container">
        <div
          className="qIYiue ZTxvd amp_re"
          data-init-vis="true"
          style={{ width: "232px" }}
          data-hveid="CAcQCQ"
        >
          <g-inner-card class="xE0xid kno-fb-ctx QCSNec VoEfsd">
            <div className="amp_r">
              <div className="amp-carousel__top-section">
                <div className="MaNG8b">
                  <g-img class="o5GQac">
                    <img
                      id="dimg_4"
                      src={logo}
                      className="rISBZc zr758c M4dUYb"
                      width="150"
                      alt={publisherName}
                      data-atf="1"
                    />
                  </g-img>
                  {hasAMP ? (
                    <span className="uz9HCf">
                      <span className="ZseVEf QzoJOb" aria-label="AMP logo" />
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="amp-carousel__image-section">
                <div className="qV9w7d" style={{ height: "130px" }}>
                  <div
                    className="KNcnob"
                    style={{
                      height: "130px",
                      width: "232px",
                      backgroundColor: "#0e0706"
                    }}
                  >
                    <g-img>
                      <img
                        id="dimg_24"
                        src={imageUrl}
                        className="rISBZc M4dUYb"
                        width="232"
                        alt=""
                        data-atf="1"
                        data-testid="article-image"
                      />
                    </g-img>
                  </div>
                </div>
              </div>
              <div
                className="amp-carousel__title-section QgUve nDgy9d"
                style={{ height: "3.75em", WebkitLineClamp: "3" }}
                aria-level="3"
                role="heading"
              >
                {headline}
              </div>
              <div className="amp-carousel__time-published-section">
                <p className="S1FAPd">
                  <span>{timeSincePublished}</span>
                </p>
              </div>
            </div>
          </g-inner-card>
        </div>
      </div>
    </div>
  );
};

AMPCarouselItem.propTypes = {
  schema: SchemaPropTypes
};

export default AMPCarouselItem;
