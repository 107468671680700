import StringUtils from "../../../utils/StringUtils";
import ObjectUtils from "../../../utils/ObjectUtils";

const getSrcAttributeFromObject = imageObject => {
  if (imageObject.hasOwnProperty("url")) {
    return imageObject.url;
  }
};

const getImageSrcFromSchema = imageSchemaAttribute => {
  if (StringUtils.isString(imageSchemaAttribute)) {
    return imageSchemaAttribute;
  }

  if (Array.isArray(imageSchemaAttribute)) {
    const firstImage = imageSchemaAttribute[0];
    if (StringUtils.isString(firstImage)) {
      return firstImage;
    }

    if (ObjectUtils.isObject(firstImage)) {
      return getSrcAttributeFromObject(firstImage);
    }
  }

  if (ObjectUtils.isObject(imageSchemaAttribute)) {
    return getSrcAttributeFromObject(imageSchemaAttribute);
  }
};

export default getImageSrcFromSchema;
