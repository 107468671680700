import React, { Component } from "react";
import { Modal, Row } from "antd";
import RoleFilterCard from "./RoleFilterCard";
import CommonButton from "../CommonButton";
import PropTypes from "prop-types";
import { Api } from "../../../utils/Api";

const ALL = 1111;
const SEO = 1000;
const DEVELOPER = 100;
const EDITORIAL = 10;
const MARKETING = 1;

class RoleFilterModal extends Component {
  state = {
    isVisible: false,
    activeCard: 0,
    errorMessageVisible: false,
    setOnce: false,
    userRole: 0
  };

  constructor(props) {
    super(props);
    this.setModalVisible = this.setModalVisible.bind(this);
  }

  async componentDidMount() {
    const metadata = await Api.getUserMetadata();
    const currentRole = parseInt(metadata.role, 10);
    this.setState({ activeCard: currentRole });
    if (!currentRole || currentRole === "undefined") {
      if (!this.state.userRoleChecked) {
        this.setVisibleOnce();
      }
    } else if (this.state.userRole === 0) {
      this.setState({
        userRole: currentRole
      });
    }
  }

  setVisibleOnce = () => {
    if (!this.state.setOnce) {
      this.setState({
        isVisible: true,
        activeCard: this.state.userRole,
        setOnce: true
      });
    }
  };

  setModalVisible = isVisible => {
    this.setState({
      isVisible,
      activeCard: this.state.userRole
    });
  };

  isCardActive = cardId => {
    return this.state.activeCard === cardId;
  };

  cardClick = (event, cardId) => {
    event.preventDefault();
    this.setState({ activeCard: cardId });
  };

  closeCard = async event => {
    const { activeCard } = this.state;
    event.preventDefault();
    if (activeCard > 0) {
      try {
        await Api.updateUserRole(activeCard);
        const metadata = await Api.getUserMetadata();
        const userRole = metadata.role;
        this.setState({
          activeCard: userRole,
          userRole: userRole
        });
        this.setModalVisible(false);
      } catch (error) {
        throw error;
      }
    } else {
      this.setState({ errorMessageVisible: true });
    }
  };

  render() {
    return (
      <Modal
        centered
        maskClosable={
          this.state.userRole && this.state.userRole !== "undefined"
        }
        visible={this.state.isVisible}
        onCancel={() => this.setModalVisible(false)}
        maskStyle={{ backgroundColor: "rgba(2, 0, 71, 0.8)" }}
        footer={null}
        closable={false}
        bodyStyle={{
          padding: 0,
          margin: 0
        }}
        width="auto"
        keyboard={this.state.userRole && this.state.userRole !== "undefined"}
      >
        <Row style={styles.rowStyles}>
          <span style={styles.titleText}>Welcome!</span>
        </Row>
        <Row style={styles.rowStyles}>
          <span style={styles.descriptionText}>
            For easier use, SEO Inspector can be tailored to your role. Just
            select the job type closest to your own. (You can change your role
            any time.)
          </span>
        </Row>
        <Row style={styles.rowStyles}>
          <RoleFilterCard
            key="editorial"
            roleTitle="Editorial"
            selected={this.isCardActive(EDITORIAL)}
            cardClick={e => this.cardClick(e, EDITORIAL)}
            textList={["Editor", "Writer", "Journalist", "Producer"]}
          />
          <RoleFilterCard
            key="engineering"
            roleTitle="Engineering"
            selected={this.isCardActive(DEVELOPER)}
            cardClick={e => this.cardClick(e, DEVELOPER)}
            textList={["Developer", "Data engineer", "Operations"]}
          />
          <RoleFilterCard
            key="marketing"
            roleTitle="Marketing"
            selected={this.isCardActive(MARKETING)}
            cardClick={e => this.cardClick(e, MARKETING)}
            textList={[
              "Marketing",
              "Ad sales",
              "Distribution strategy",
              "Social media"
            ]}
          />
        </Row>
        <Row style={styles.rowStyles}>
          <RoleFilterCard
            key="seo-analyst"
            roleTitle="SEO Analyst"
            selected={this.isCardActive(SEO)}
            cardClick={e => this.cardClick(e, SEO)}
            textList={[
              "Technical SEO",
              "SEO analyst",
              "SEO producer",
              "Data scientist"
            ]}
          />
          <RoleFilterCard
            key="powerful"
            roleTitle="Show me everything!"
            selected={this.isCardActive(ALL)}
            cardClick={e => this.cardClick(e, ALL)}
            textList={[
              'Choose "Show me everything" if you don\'t want the tool tailored to your job.'
            ]}
          />
        </Row>
        {this.state.errorMessageVisible ? (
          <Row
            style={{
              ...styles.rowStyles,
              height: "auto",
              margin: "4px"
            }}
          >
            <span
              style={{
                ...styles.descriptionText,
                height: "auto",
                margin: "4px"
              }}
            >
              * Please select a job type
            </span>
          </Row>
        ) : null}
        <Row style={styles.rowStyles}>
          <div style={styles.startButton}>
            <CommonButton
              secondary
              onClick={e => this.closeCard(e)}
              additionalStyle={{
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)"
              }}
            >
              SUBMIT
            </CommonButton>
          </div>
        </Row>
      </Modal>
    );
  }
}

const styles = {
  rowStyles: {
    width: "1000px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "8px"
  },
  titleText: {
    height: "33px",
    width: "656px",
    color: "#FFFFFF",
    fontFamily: "Avenir",
    fontSize: "24px",
    fontWeight: "900",
    lineHeight: "33px",
    textAlign: "center"
  },
  descriptionText: {
    height: "54px",
    width: "656px",
    color: "#FFFFFF",
    fontFamily: "Avenir",
    fontSize: "20px",
    lineHeight: "27px",
    textAlign: "center",
    marginBottom: "16px"
  },
  startButton: {
    height: "48px",
    width: "232px"
  }
};

RoleFilterModal.propTypes = {
  user: PropTypes.object
};

RoleFilterModal.defaultProps = {
  user: {
    nickname: "",
    name: "",
    picture: "",
    updated_at: "",
    email: "",
    email_verified: true,
    sub: ""
  }
};

export default RoleFilterModal;
