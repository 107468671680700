import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Spin, Icon } from "antd";

const Login = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then(info => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const login = async () => {
    oktaAuth.signInWithRedirect();
  };

  if (authState.isPending) {
    const antIcon = <Icon type="loading" style={{ fontSize: 140 }} spin />;
    return (
      <div style={styles.loading}>
        <Spin tip="Loading..." size="large" indicator={antIcon} />
      </div>
    );
  }

  if (authState.isAuthenticated && !userInfo) {
    const antIcon = <Icon type="loading" style={{ fontSize: 140 }} spin />;
    return (
      <div style={styles.loading}>
        <Spin
          tip="Loading user information..."
          size="large"
          indicator={antIcon}
        />
      </div>
    );
  }

  if (authState.isAuthenticated && userInfo) {
    window.location.href = "/start";
  }

  if (!authState.isAuthenticated) {
    login();
  }
};

const styles = {
  loading: {
    display: "flex",
    justifyContent: "center"
  }
};

export default Login;
