import React, { Component } from "react";
import RuleResult from "../RuleResult";
import PropTypes from "prop-types";
import CustomAlert from "../../../common/CustomAlert";

class ResultList extends Component {
  getFailedRules = () => this.props.rules.filter(rule => !rule.passed);
  getSuccessRules = () => this.props.rules.filter(rule => rule.passed);

  render() {
    const failedRules = this.getFailedRules();
    const passedRules = this.getSuccessRules();
    /* TODO: Alert notifications should be work when the results of the report load dynamically */
    let failedAlert = failedRules.length ? (
      <div style={styles.alert}>
        <CustomAlert style={styles.alert} info="failed" />
      </div>
    ) : (
      <div />
    );

    let passedAlert = passedRules.length ? (
      <div style={styles.alert}>
        <CustomAlert style={styles.alert} info="passed" />
      </div>
    ) : (
      <div />
    );
    return (
      <React.Fragment>
        {failedAlert}
        {failedRules.map((rule, index) => {
          return (
            <RuleResult
              key={rule.id || `failed-${index}`}
              passed={false}
              name={rule.name}
              description={rule.description}
              details={rule.details}
              howToFix={rule.howToFix}
            />
          );
        })}
        {passedAlert}
        {passedRules.map((rule, index) => {
          return (
            <RuleResult
              key={rule.id || `passed-${index}`}
              passed={true}
              name={rule.name}
              description={rule.description}
              details={rule.details}
              howToFix={rule.howToFix}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

ResultList.propTypes = {
  rules: PropTypes.array
};

const styles = {
  issuesHeader: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 12
  },
  passHeader: {
    fontSize: 18,
    marginTop: 16
  },
  alert: {
    width: "100%"
  }
};

export default ResultList;
