import React, { Component } from "react";
import GoogleGraphWidget from "./components/GoogleGraphWidget";
import {
  trendsFilterType,
  trendsGraphTypeType,
  trendsSpecificationType
} from "./types";

class GoogleTrendsGraph extends Component {
  render() {
    const { comparisonItem } = this.props.trendsSpecification;
    if (comparisonItem.length <= 0) {
      return <div>Please provide at least one keyword</div>;
    }

    if (comparisonItem.length > 5) {
      return (
        <div>
          Unfortunately, google trends only allows to search 5 keywords maximum
        </div>
      );
    }

    return (
      <GoogleGraphWidget
        trendsFilter={this.props.trendsFilter}
        trendsGraphType={this.props.trendsGraphType}
        trendsSpecification={this.props.trendsSpecification}
      />
    );
  }
}

GoogleTrendsGraph.propTypes = {
  trendsGraphType: trendsGraphTypeType.isRequired,
  trendsSpecification: trendsSpecificationType.isRequired,
  trendsFilter: trendsFilterType.isRequired
};

export default GoogleTrendsGraph;
