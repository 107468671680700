import React from "react";
import PropTypes from "prop-types";
import cc from "classnames";
import "./DetailForm.css";

const DetailForm = props => {
  const { children, className, ...otherProps } = props;
  return (
    <div className={cc("detail__form", className)} {...otherProps}>
      {children}
    </div>
  );
};

DetailForm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default DetailForm;
