import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Products, Channels, Teams } from "../utils/CampaignOptions";
import CommonSelect from "./common/CommonSelect";

const fieldNames = [
  "Product",
  "Campaign",
  "Channel",
  "Team",
  "Miscellaneous",
  "URL"
];

class CampaignCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Product: "",
      Campaign: "",
      Channel: "",
      Team: "",
      Miscellaneous: "",
      URL: "",
      code: "",
      visibleCode: false
    };

    this.handleChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.copyCode = this.copyCode.bind(this);
  }

  handleInputChange(e) {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  hasEmptyFields() {
    if (
      this.state.Product === "" ||
      this.state.Campaign === "" ||
      this.state.Channel === "" ||
      this.state.Team === "" ||
      this.state.URL === ""
    ) {
      return true;
    }
    return false;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.hasEmptyFields()) {
      const code = `https://${this.state.URL}?mod=${
        this.state.Product
      }_${this.parseCampaign(this.state.Campaign)}_${this.state.Channel}_${
        this.state.Team
      }_${this.state.Miscellaneous !== "" ? this.state.Miscellaneous : "NA"}`;
      this.setState({
        code: code,
        visibleCode: true
      });
    } else {
      alert("Please fill all fields.");
    }
  }

  resetForm(e) {
    e.preventDefault();
    for (let i = 0; i < fieldNames.length; i++) {
      document.getElementById(fieldNames[i]).value = "";
    }
    this.setState({
      Product: "",
      Campaign: "",
      Channel: "",
      Team: "",
      Miscellaneous: "",
      URL: "",
      code: "",
      visibleCode: false
    });
  }

  copyCode(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.code);
  }

  parseCampaign(campaignText) {
    let code = "";
    campaignText.split(" ").forEach(word => {
      if (word.length > 0) {
        code += word[0];
      }
      if (word.length > 1) {
        code += word[1];
      }
    });
    return code;
  }

  render() {
    return (
      <div style={styles.container}>
        <h1>Campaign URL Builder</h1>
        <form style={styles.form}>
          <div style={styles.formItem}>
            <label style={styles.labels}>{fieldNames[5]}: </label>
            <input
              style={styles.inputs}
              type="text"
              id={fieldNames[5]}
              name={fieldNames[5]}
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.formItem}>
            <label style={styles.labels}>{fieldNames[0]}: </label>
            <CommonSelect
              options={Products}
              style={styles.inputs}
              id={fieldNames[0]}
              name={fieldNames[0]}
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.formItem}>
            <label style={styles.labels}>{fieldNames[1]}: </label>
            <input
              type="text"
              style={styles.inputs}
              id={fieldNames[1]}
              name={fieldNames[1]}
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.formItem}>
            <label style={styles.labels}>{fieldNames[2]}: </label>
            <CommonSelect
              options={Channels}
              style={styles.inputs}
              id={fieldNames[2]}
              name={fieldNames[2]}
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.formItem}>
            <label style={styles.labels}>{fieldNames[3]}: </label>
            <CommonSelect
              options={Teams}
              style={styles.inputs}
              id={fieldNames[3]}
              name={fieldNames[3]}
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.formItem}>
            <label style={styles.labels}>{fieldNames[4]}: </label>
            <input
              style={styles.inputs}
              type="text"
              id={fieldNames[4]}
              name={fieldNames[4]}
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.buttons}>
            <button
              type="submit"
              style={{ padding: "5px", margin: "10px", float: "left" }}
              onClick={this.handleSubmit}
            >
              Create URL
            </button>
            <button
              type="submit"
              style={{ padding: "5px", marginTop: "10px", marginLeft: "30px" }}
              onClick={this.copyCode}
            >
              Copy
            </button>
            <button
              type="reset"
              style={{ padding: "5px", margin: "10px", float: "right" }}
              onClick={this.resetForm}
            >
              Reset
            </button>
          </div>
        </form>
        <div style={styles.result} hidden={!this.state.visibleCode}>
          {this.state.code}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    margin: "auto",
    padding: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderColor: "black",
    borderStyle: "solid",
    borderRadius: "50px",
    width: "50%",
    marginTop: "100px"
  },
  formItem: {
    padding: "5px",
    height: "30px"
  },
  labels: {
    display: "inline-block",
    width: "130px",
    textAlign: "right"
  },
  inputs: {
    display: "inline-block",
    width: "200px"
  },
  result: {
    background: "#f4f4f4",
    border: "1px solid #ddd",
    borderLeft: "3px solid #f36d33",
    color: "#666",
    pageBreakInside: "avoid",
    fontFamily: "monospace",
    fontSize: "15px",
    lineHeight: "1.6",
    marginBottom: "1.6em",
    maxWidth: "100%",
    overflow: "auto",
    padding: "1em 1.5em",
    display: "block",
    wordWrap: "break-word"
  },
  buttons: {
    padding: "20px",
    height: "50px"
  }
};

export default withRouter(CampaignCode);
