import React from "react";
import AbstractReport from "./AbstractReport";
import Report from "../../../audit/Report";
import GoogleScoreGuide from "../../../performanceReportComponents/GoogleScoreGuide";
import PerformanceMetricsDetail from "../ruleResult/components/PerformanceMetricsDetail/PerformanceMetricsDetail";

class Performance extends AbstractReport {
  state = {
    name: "Performance Report",
    reportName: "Performance Report"
  };

  getGoogleGuide = () => {
    const { metrics, infoTexts } = this.state.report.extras;
    return (
      <div style={styles.performanceMetrics}>
        <GoogleScoreGuide />
        <PerformanceMetricsDetail metrics={metrics} infoTexts={infoTexts} />
      </div>
    );
  };

  getTimeOutText = () => {
    return (
      <span>
        Hmm... it seems like there is something wrong with this report. You can
        look up results manually at
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://developers.google.com/speed/pagespeed/insights"
        >
          {" "}
          HERE.
        </a>
      </span>
    );
  };

  renderReport = () => {
    const { report: reportData } = this.state;
    return (
      <React.Fragment>
        <Report
          name="Performance"
          score={reportData.extras.score * 100}
          rulesTotal={reportData.rules.total}
          rulesPassedCount={reportData.rules.passed}
          rulesFailedCount={reportData.rules.failed}
          rulesData={reportData.rules.nodes}
          isProcessed={reportData.isProcessed}
          resultListType="performance"
          metrics={reportData.extras.metrics}
          infoTexts={reportData.extras.infoTexts}
          preRuleInserts={this.getGoogleGuide()}
        />
      </React.Fragment>
    );
  };
}

const styles = {
  performanceMetrics: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20,
    width: "100%"
  }
};

export default Performance;
