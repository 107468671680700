import React, { Component } from "react";
import { Progress } from "antd";
import PropTypes from "prop-types";

class CommonProgress extends Component {
  render() {
    const {
      score,
      children,
      loading,
      styleType,
      type,
      strokeColor,
      width,
      strokeWidth,
      style,
      format
    } = this.props;
    return (
      <Progress
        className={styleType}
        strokeLinecap="square"
        percent={loading ? 0 : Math.round(score)}
        type={type}
        strokeColor={strokeColor}
        width={width}
        strokeWidth={strokeWidth}
        style={style}
        format={format}
      >
        {children}
      </Progress>
    );
  }
}

CommonProgress.propTypes = {
  score: PropTypes.number.isRequired,
  loading: PropTypes.bool
};

CommonProgress.defaultProps = {
  loading: false
};

export default CommonProgress;
