import React from "react";
import { useOktaAuth } from "@okta/okta-react";

import { Api } from "../../utils/Api";
import isDev from "../../utils/isDev";

const UseAuthWrapper = props => {
  const {
    authState: { isAuthenticated = false, isPending = false } = {}
  } = useOktaAuth();
  const user = Api.getUser();
  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      ...child.props,
      user,
      isAuthenticated: isDev() ? true : isAuthenticated,
      isLoading: isDev() ? false : isPending
    })
  );

  return <React.Fragment>{childrenWithProps}</React.Fragment>;
};

export default UseAuthWrapper;
