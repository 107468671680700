/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import PropTypes from "prop-types";

class ImageDetail extends Component {
  render() {
    const { src } = this.props;

    return (
      <div style={styles.imageContainer}>
        <img src={src} style={styles.imageStyle} alt="Image Detail" />
      </div>
    );
  }
}

ImageDetail.propTypes = {
  src: PropTypes.string.isRequired
};

const styles = {
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5F6F7",
    padding: "20px 0",
    margin: "5px 0 0 0"
  },
  imageStyle: {
    boxShadow: "4px 4px 21px -2px rgba(0,0,0,0.47)"
  }
};

export default ImageDetail;
