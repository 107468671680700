import React from "react";
import PropTypes from "prop-types";
import cc from "classnames";
import "./DetailFormLabel.css";

const DetailFormLabel = props => {
  const { children, className, ...otherProps } = props;
  return (
    <label className={cc(className, "detail-form-label")} {...otherProps}>
      {children}
    </label>
  );
};

DetailFormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default DetailFormLabel;
