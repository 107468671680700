import React, { Component } from "react";
import { Button } from "antd";

class CommonButton extends Component {
  state = {
    hovered: false
  };

  getStyle = () => {
    const { hovered } = this.state;
    const { disabled, secondary } = this.props;

    if (disabled) {
      return styles.buttonDisabled;
    }

    if (secondary) {
      return styles.buttonSecondary;
    }

    if (hovered) {
      return styles.buttonHovered;
    }

    return styles.button;
  };

  toggleHover = isHovered => {
    this.setState({ hovered: isHovered });
  };

  render() {
    const { children, onClick } = this.props;
    return (
      <Button
        style={{
          ...this.props.additionalStyle,
          ...this.getStyle()
        }}
        onMouseEnter={e => {
          e.preventDefault();
          this.toggleHover(true);
        }}
        onMouseLeave={e => {
          e.preventDefault();
          this.toggleHover(false);
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
}

const styles = {
  button: {
    height: "auto",
    width: "100%",
    color: "#FFFFFF",
    fontFamily: "Space Mono",
    fontSize: "16px",
    letterSpacing: "0.06px",
    lineHeight: "32px",
    textAlign: "center",
    borderStyle: "solid",
    borderColor: "#030075",
    borderRadius: 0,
    backgroundColor: "#030075",
    padding: "8px"
  },
  buttonHovered: {
    height: "auto",
    width: "100%",
    color: "#000000",
    fontFamily: "Space Mono",
    fontSize: "16px",
    letterSpacing: "0.06px",
    lineHeight: "32px",
    textAlign: "center",
    borderStyle: "solid",
    borderColor: "#030075",
    borderRadius: 0,
    backgroundColor: "#FFFFFF",
    padding: "8px"
  },
  buttonSecondary: {
    height: "auto",
    width: "100%",
    color: "#030075",
    fontFamily: "Space Mono",
    fontSize: "16px",
    letterSpacing: "0.06px",
    lineHeight: "32px",
    textAlign: "center",
    borderStyle: "solid",
    borderColor: "#030075",
    borderRadius: 0,
    backgroundColor: "#FFFFFF",
    padding: "8px"
  },
  buttonDisabled: {
    height: "auto",
    width: "100%",
    color: "#FFFFFF",
    fontFamily: "Space Mono",
    fontSize: "16px",
    letterSpacing: "0.06px",
    lineHeight: "32px",
    textAlign: "center",
    borderStyle: "solid",
    borderColor: "#8A8E9A",
    borderRadius: 0,
    backgroundColor: "#8A8E9A",
    padding: "8px"
  }
};

export default CommonButton;
