import React from "react";
import { Tree, Icon } from "antd";
const { TreeNode } = Tree;

const treeParent = parent => {
  const { url } = parent;
  const recievedTime = Math.ceil((parent.endTime - parent.startTime) * 1000);
  const transferSize = Math.ceil(parent.transferSize / 1000);
  const parentTitle = `${url} - ${recievedTime}ms, ${transferSize}KB`;

  return parentTitle;
};

const treeChildren = children => {
  const arrayOfChildren = [];
  const elements = [];
  for (const property in children) {
    const { request } = children[property];
    const { url, transferSize, endTime, startTime } = request;
    arrayOfChildren.push({ url, transferSize, endTime, startTime });
  }

  arrayOfChildren.forEach(child => {
    const { url } = child;
    const recievedTime = Math.ceil((child.endTime - child.startTime) * 1000);
    const transferSize = Math.ceil(child.transferSize / 1000);
    const childTitle = `${url} - ${recievedTime} ms, ${transferSize} KB`;
    const keyValue = `0-0-${arrayOfChildren.indexOf(child)}`;

    elements.push(<TreeNode title={childTitle} key={keyValue} />);
  });

  return elements;
};

const TreeDetail = props => {
  const { parent, children } = props;
  // Tree parent data
  const parentTitle = treeParent(parent);
  // Tree children data
  const childrenElements = treeChildren(children);

  return (
    <div
      style={{
        "overflow-x": "scroll",
        "overflow-y": "scroll",
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
        padding: "10px"
      }}
    >
      <Tree
        showLine
        switcherIcon={<Icon type="down" />}
        defaultExpandedKeys={["0-0"]}
      >
        <TreeNode title={parentTitle} key="0-0">
          {childrenElements}
        </TreeNode>
      </Tree>
    </div>
  );
};

export default TreeDetail;
