import React, { Component } from "react";
import { List } from "antd";
import PropTypes from "prop-types";
import CommonMarkdown from "../../../../../common/CommonMarkdown";

class ListDetail extends Component {
  render() {
    const { listHeader, list, listFooter } = this.props;

    let inputProps = {};
    if (listHeader !== "") {
      inputProps.header = <span style={styles.headerStyle}>{listHeader}</span>;
    }
    if (listFooter !== "") {
      inputProps.footer = <span>{listFooter}</span>;
    }

    return (
      <div style={styles.listContainer}>
        <List
          split={false}
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <div style={styles.listItemBox}>
                <span style={styles.listItemText}>
                  <CommonMarkdown linkStyle={styles.links}>
                    {item}
                  </CommonMarkdown>
                </span>
              </div>
            </List.Item>
          )}
          {...inputProps}
        />
      </div>
    );
  }
}

ListDetail.propTypes = {
  listHeader: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  listFooter: PropTypes.string
};

ListDetail.defaultProps = {
  listHeader: "",
  listFooter: ""
};

const styles = {
  listContainer: {
    backgroundColor: "#F5F6F7",
    padding: "0 20px"
  },
  headerStyle: {
    height: "72px",
    width: "770px",
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: "900",
    lineHeight: "24px"
  },
  listItemBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: "15px 16px",
    marginBottom: "17px"
  },
  listItemText: {
    height: "24px",
    color: "#000000",
    fontFamily: "Space Mono",
    fontSize: "13px",
    lineHeight: "24px",
    whiteSpace: "pre-wrap"
  },
  links: {
    color: "#030075",
    textDecoration: "underline"
  }
};

export default ListDetail;
