const devicesDictionary = {
  amp: {
    id: "amp",
    displayName: "Top Stories"
  },
  desktop: {
    id: "desktop",
    displayName: "Desktop"
  },
  iPhone: {
    id: "iPhone",
    displayName: "Mobile"
  },
  tablet: {
    id: "tablet",
    displayName: "Tablet"
  }
};

export default {
  devicesDictionary,
  find(deviceId) {
    return devicesDictionary[deviceId];
  },
  getAll() {
    return Object.keys(devicesDictionary).map(
      deviceId => devicesDictionary[deviceId]
    );
  },
  getAllIds() {
    return this.getAll().map(d => d.id);
  }
};
