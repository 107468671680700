import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import UrlInput from "./UrlInput";
import SideMenu from "./SideMenu";
import HeaderAudit from "./HeaderAudit";
import CustomFooter from "./CustomFooter";
import UseAuthWrapper from "./UseAuthWrapper";
import RoleMenuButton from "./RoleMenuButton";
import Logo from "../../assets/images/seo-logo.svg";
import SideMenuCollapseButton from "./SideMenuCollapseButton";

const { Header, Content, Sider, Footer } = Layout;
const HEADER_HEIGHT = "80px";

class MainContent extends Component {
  state = {
    collapsed: false,
    sideMenuCollapseButtonHover: false
  };

  toogleHover = hoverState => {
    this.setState(hoverState);
  };

  toggleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  getCollapseButton = () => {
    return (
      <UseAuthWrapper>
        <SideMenuCollapseButton
          style={{ marginTop: "auto" }}
          isCollapsed={this.state.collapsed}
          isHovered={this.state.sideMenuCollapseButtonHover}
          onClickAction={() => {
            this.toggleCollapse();
          }}
          onMouseEnterAction={e => {
            this.toogleHover({ sideMenuCollapseButtonHover: true });
          }}
          onMouseLeaveAction={e => {
            this.toogleHover({ sideMenuCollapseButtonHover: false });
          }}
        />
      </UseAuthWrapper>
    );
  };

  onCollapse = collapsed => {
    this.setState({ collapsed, logoVisible: !this.state.logoVisible });
  };

  render() {
    const {
      auditData: {
        audit: { auditUrl, title }
      },
      auditId,
      routes
    } = this.props;
    return (
      <Layout style={styles.fullContainer}>
        <Header style={styles.urlInputWraper}>
          <div className="logo" onClick={this.props.goBackToStart}>
            <img src={Logo} alt={"logo"} style={styles.logo} />
          </div>
          <div
            style={{
              ...styles.urlInputOuterContainer,
              marginLeft: this.state.collapsed ? 0 : "91px"
            }}
          >
            <div style={styles.urlInputInnerContainer}>
              <UseAuthWrapper>
                <UrlInput
                  defaultUrl={auditUrl}
                  reports={this.props.reports}
                  selectedReports={this.props.selectedReports}
                  onReportSelectionChange={this.props.onReportSelectionChange}
                  handleID={id => {
                    this.props.loadAudit(id);
                  }}
                  additionalActionOnSubmit={[]}
                />
              </UseAuthWrapper>
            </div>
          </div>
          <RoleMenuButton
            userRoleModalComponent={this.props.userRoleModalComponent}
          />
        </Header>
        <Layout style={styles.pageLayoutUnderHeader}>
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            style={styles.leftSideMenuWrapper}
            trigger={null}
          >
            <SideMenu
              style={styles.sideMenuContainer}
              onClick={this.selectComponent}
              auditId={auditId}
              auditedURL={auditUrl}
              collapseButton={this.getCollapseButton()}
            />
          </Sider>
          <Layout style={{ marginLeft: this.state.collapsed ? 80 : 200 }}>
            <Content>
              <div style={styles.auditResultWrapper}>
                <HeaderAudit title={title} url={auditUrl} />
                <hr style={styles.titleURLLine} />
                {routes}
              </div>
            </Content>
            <Footer style={{ backgroundColor: "#FFF" }}>
              <CustomFooter
                modalComponent={this.props.feedbackModalComponent}
              />
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

MainContent.propTypes = {
  auditData: PropTypes.objectOf(
    PropTypes.shape({
      auditUrl: PropTypes.string,
      title: PropTypes.string
    })
  ).isRequired,
  auditId: PropTypes.string.isRequired,
  routes: PropTypes.array,
  goBackToStart: PropTypes.func.isRequired,
  reports: PropTypes.array,
  selectedReports: PropTypes.array,
  onReportSelectionChange: PropTypes.func,
  loadAudit: PropTypes.func.isRequired,
  userRoleModalComponent: PropTypes.object.isRequired,
  feedbackModalComponent: PropTypes.object.isRequired
};

MainContent.defaultPropTypes = {
  routes: [],
  reports: [],
  selectedReports: [],
  onReportSelectionChange: () => {}
};

const styles = {
  fullContainer: {
    minHeight: "100vh"
  },
  urlInputWraper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    height: HEADER_HEIGHT,
    minWidth: 721,
    backgroundImage:
      "linear-gradient(to bottom, transparent 50%, #f2f2f2 50%), linear-gradient(to right, #f2f2f2 50%, #bcbcbc 50%)",
    backgroundSize: "2px 2px, 2px 2px",
    position: "fixed",
    zIndex: 1
  },
  logo: {
    height: "56px",
    width: "84px"
  },
  urlInputOuterContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginRight: "-23px"
  },
  urlInputInnerContainer: {
    width: "75%",
    maxWidth: "816px"
  },
  pageLayoutUnderHeader: {
    marginTop: HEADER_HEIGHT,
    padding: 0
  },
  leftSideMenuWrapper: {
    backgroundColor: "#30c6d6",
    height: `calc(100vh - ${HEADER_HEIGHT})`,
    justifyContent: "center",
    position: "fixed",
    overflow: "auto",
    left: 0
  },
  sideMenuContainer: {
    marginTop: 34,
    width: "100%"
  },
  auditResultWrapper: {
    padding: "24px 64px",
    background: "#fff",
    minHeight: 360
  },
  titleURLLine: {
    borderTop: "1px solid #E0E0E0",
    height: "1px",
    marginBottom: 35,
    marginTop: 10
  }
};

export default MainContent;
