import { Api } from "./Api";

const getSatisfactionEmoji = satisfaction => {
  switch (satisfaction) {
    case "GOOD":
      return ":seo-happy-face:";
    case "REGULAR":
      return ":seo-normal-face:";
    case "BAD":
      return ":seo-sad-face:";
    default:
      return "";
  }
};

const getFeedbackMessage = fields => {
  const { name, email, message, satisfaction, images } = fields;

  const blocks = [
    {
      type: "section",
      text: {
        type: "plain_text",
        text: "The SEO Tool received new feedback:"
      },
      fields: [
        {
          type: "mrkdwn",
          text: `*User:* ${name.value}`
        },
        {
          type: "mrkdwn",
          text: `*Email:* ${email.value}`
        },
        {
          type: "mrkdwn",
          text: `*Message:*\n>${message.value}`
        },
        {
          type: "mrkdwn",
          text: `*Satisfaction:* ${getSatisfactionEmoji(satisfaction.value)}`
        }
      ]
    }
  ];

  const imageBlocks = [];

  if (images.value.fileList && images.value.fileList.length > 0) {
    images.value.fileList.forEach(image => {
      imageBlocks.push({
        type: "image",
        title: {
          type: "plain_text",
          text: image.name,
          emoji: true
        },
        image_url: image.response.url,
        alt_text: image.name
      });
    });

    blocks.push(...imageBlocks);
  }

  return {
    blocks
  };
};

const fieldsToJSON = fields => {
  const JSONFeedback = {
    name: fields.name.value,
    email: fields.email.value,
    message: fields.message.value,
    satisfaction: fields.satisfaction.value
  };

  if (fields.images.value.fileList && fields.images.value.fileList.length > 0) {
    const imagesURLs = [];
    fields.images.value.fileList.forEach(image => {
      imagesURLs.push(image.response.url);
    });

    JSONFeedback.images = imagesURLs;
  }

  return JSONFeedback;
};

const Slack = {
  sendMessage: fields => {
    const data = getFeedbackMessage(fields);
    const JSONfeedback = fieldsToJSON(fields);
    return Api.postToSlack({ data, JSONfeedback });
  }
};

export default Slack;
