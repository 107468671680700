import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { greenCheck } from "../../../assets/svgInJs/user-role";

const RoleFilterCard = props => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = event => {
    event.preventDefault();
    props.cardClick(event);
  };

  const toggleHovered = (event, hover) => {
    event.preventDefault();
    setIsHovered(hover);
  };

  const getInnerContainerStyle = () => {
    return isHovered
      ? {
          border: "1px solid #0D007F",
          ...styles.cardInnerContainer
        }
      : styles.cardInnerContainer;
  };

  return (
    <div
      style={styles.cardOuterContainer}
      onClick={e => handleClick(e)}
      onMouseEnter={e => toggleHovered(e, true)}
      onMouseLeave={e => toggleHovered(e, false)}
    >
      <div style={getInnerContainerStyle()}>
        {/* HEADER */}
        <div style={styles.cardHeader}>
          <span style={styles.cardTitleText}>{props.roleTitle}</span>
          <div style={styles.cardTitleCircleIconOuter}>
            {props.selected ? (
              <div style={styles.cardTitleCircleIconInner} />
            ) : null}
          </div>
        </div>
        {/* BODY */}
        <div style={styles.cardBody}>
          {/* BODY TEXT */}
          <div style={styles.cardBodyTextContainer}>
            {props.textList.map((text, index) => (
              <span
                key={`${props.roleTitle}-${index}`}
                style={styles.cardBodyText}
              >
                {text}
              </span>
            ))}
          </div>
          {/* BODY CHECK */}
          <div style={styles.checkOuterContainer}>
            {props.selected ? (
              <div style={styles.checkInnerContainer}>
                <Icon style={styles.iconStyle} component={greenCheck} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

RoleFilterCard.propTypes = {
  roleTitle: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  textList: PropTypes.arrayOf(PropTypes.string),
  cardClick: PropTypes.func
};

RoleFilterCard.defaultProps = {
  textList: [],
  cardClick: () => {}
};

const styles = {
  cardOuterContainer: {
    width: "312px",
    height: "192px",
    padding: "8px",
    backgroundColor: "#EDF0F2",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)"
  },
  cardInnerContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardHeader: {
    width: "100%",
    height: "48px",
    backgroundImage:
      "linear-gradient(to bottom, transparent 50%, #f2f2f2 50%), linear-gradient(to right, #f2f2f2 50%, #bcbcbc 50%)",
    backgroundSize: "2px 2px, 2px 2px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardTitleText: {
    height: "30px",
    width: "224px",
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: "18px",
    fontWeight: "900",
    lineHeight: "30px"
  },
  cardTitleCircleIconOuter: {
    height: "18px",
    width: "18px",
    border: "1px solid #030075",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  cardTitleCircleIconInner: {
    height: "12px",
    width: "12px",
    backgroundColor: "#030075",
    borderRadius: "50%"
  },
  cardBody: {
    height: "100%",
    width: "100%",
    display: "flex"
  },
  cardBodyTextContainer: {
    height: "100%",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "8px"
  },
  cardBodyText: {
    color: "#5F6065",
    fontFamily: "Space Mono",
    fontSize: "14px",
    lineHeight: "22px"
  },
  checkOuterContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF"
  },
  checkInnerContainer: {
    height: "96px",
    width: "64px",
    backgroundColor: "#F5F6F7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default RoleFilterCard;
