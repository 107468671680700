import React from "react";

const dashboard = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M11 13v9H2v-9zm11.148 0v9h-9v-9zM11 2v9H2V2zm11.243 0v9h-9V2z"
    />
  </svg>
);

const settingsGear = (divColor, iconColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={divColor} d="M0 0h32v32H0z" transform="translate(-.059)" />
      <path
        fill={iconColor}
        d="M15.141 8a.374.374 0 00-.096.017l-2.103.562a.355.355 0 00-.125.063.359.359 0 00-.096.102.354.354 0 00-.034.278l.477 1.784c-.5.279-.966.634-1.375 1.057l-1.602-.926a.347.347 0 00-.398.028.38.38 0 00-.097.108l-1.085 1.88a.374.374 0 00-.045.137.366.366 0 00.005.142.354.354 0 00.17.216l1.603.926a5.92 5.92 0 00-.227 1.716l-1.784.482a.38.38 0 00-.131.063.34.34 0 00-.136.239.354.354 0 00.01.142l.563 2.101a.38.38 0 00.17.222.347.347 0 00.274.034l1.784-.477c.278.5.634.96 1.056 1.37l-.926 1.601a.354.354 0 00-.034.278.355.355 0 00.165.216l1.886 1.091c.042.024.09.04.137.046a.39.39 0 00.142-.012.38.38 0 00.222-.17l.92-1.596a5.91 5.91 0 001.716.227l.477 1.784a.38.38 0 00.17.222c.042.023.09.038.137.045a.39.39 0 00.142-.012l2.102-.562a.353.353 0 00.216-.17.366.366 0 00.04-.273l-.483-1.784a5.88 5.88 0 001.375-1.057l1.602.926c.042.024.09.04.137.046a.39.39 0 00.142-.012.38.38 0 00.222-.17l1.085-1.88a.354.354 0 00.034-.278.355.355 0 00-.063-.126.359.359 0 00-.102-.096l-1.602-.926c.16-.562.23-1.135.221-1.704l1.79-.49a.382.382 0 00.222-.17.35.35 0 00.034-.272l-.563-2.102a.377.377 0 00-.17-.222.354.354 0 00-.278-.034l-1.785.477a5.889 5.889 0 00-1.05-1.374l.92-1.602a.366.366 0 00-.023-.403.361.361 0 00-.108-.092l-1.88-1.085a.375.375 0 00-.137-.045.366.366 0 00-.142.005.38.38 0 00-.222.17l-.92 1.603a5.958 5.958 0 00-1.722-.227l-.477-1.784A.36.36 0 0015.14 8zm.93 4c.677 0 1.366.17 1.992.532a4.014 4.014 0 011.46 5.472 4.003 4.003 0 01-5.463 1.46A4.006 4.006 0 0112.594 14c.74-1.282 2.091-2 3.477-1.999z"
        transform="translate(-.059)"
      />
    </g>
  </svg>
);

const analytics = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill={color} fillRule="evenodd">
      <path d="M10 13V3.003C4.756 3 2 7.26 2 12.516 2 17.774 6.256 22 11.5 22c5.237 0 9.484-2.4 9.5-9z" />
      <path d="M22 10.99s-.017.016-.017.008C21.983 6.027 17.74 2.028 12 2v8.99h10z" />
    </g>
  </svg>
);

const googleG = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.254 4.78l-.245-.22c-1.805-1.58-3.954-2.435-6.599-2.435C6.794 2.125 2.125 6.586 2.125 12s4.67 9.875 10.285 9.875c2.95 0 5.247-.917 7.074-2.765 1.615-1.573 2.391-3.876 2.391-6.299 0-.603-.042-1.14-.126-1.586h-9.214v2.483h6.58l.124.142c-.204 1.527-.727 2.69-1.565 3.517-1.34 1.306-2.947 2.036-5.264 2.036-4.176 0-7.48-3.28-7.48-7.392 0-4.11 3.304-7.392 7.48-7.392 2.012 0 3.676.683 5.049 1.91z"
    />
  </svg>
);

const mobileComp = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.56 2h8.88c.882 0 1.56.694 1.56 1.451V20.55c0 .82-.678 1.451-1.56 1.451H7.56C6.745 22 6 21.37 6 20.549V3.45C6 2.694 6.746 2 7.56 2zm.875 2h7.13c.272 0 .435.258.435.516v12.968c0 .258-.163.516-.435.516h-7.13C8.218 18 8 17.742 8 17.484V4.516C8 4.258 8.218 4 8.435 4zm3.597 15c.516 0 .968.452.968.968 0 .58-.452 1.032-.968 1.032A1.02 1.02 0 0111 19.968c0-.516.452-.968 1.032-.968z"
    />
  </svg>
);

const schema = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="m15.8333333 0h-11.66666663c-2.30083334 0-4.16666667 1.86583333-4.16666667 4.16666667v11.66666663c0 2.3008334 1.86583333 4.1666667 4.16666667 4.1666667h11.66666663c2.3016667 0 4.1666667-1.8658333 4.1666667-4.1666667v-11.66666663c0-2.30083334-1.865-4.16666667-4.1666667-4.16666667zm-7.66666663 8.49916667-3.03333334 1.50083333 3.03333334 1.4966667v1.8366666l-5-2.4458333v-1.77583333l5-2.445zm8.66666663 2.38833333-5 2.4458333v-1.8366666l3.0333334-1.4966667-3.0333334-1.50083333v-1.8325l5 2.44583333z"
    />
  </svg>
);

const pageHealth = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.155 4a5.009 5.009 0 00-3.65 1.586c-2.008 2.117-2.006 5.52 0 7.639l8.175 8.641a.433.433 0 00.625 0c2.73-2.876 5.46-5.75 8.188-8.627 2.01-2.118 2.01-5.522 0-7.64a4.985 4.985 0 00-7.298 0l-1.189 1.256-1.202-1.27A5.009 5.009 0 007.154 4z"
    />
  </svg>
);

const performance = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 3c6.667 0 10.333 4 11 12h-8c.004 1.234.438 6-3.149 6C8.646 21 9 16.918 9 15c0-.002-2.667-.002-8 0C1.667 7 5.333 3 12 3zm1.635 5c-.11 0-.22.074-.287.237-.826 2.043-1.647 4.089-2.49 6.118-.3.723-.74 1.604-.836 2.441C9.87 18.117 10.12 20 11.992 20c.551 0 1.089-.33 1.469-.907.47-.712.532-1.537.538-2.425v-.49l-.027-7.714c0-.29-.168-.464-.337-.464zM20 12a1.001 1.001 0 000 2 1.001 1.001 0 000-2zM4 12a1.001 1.001 0 000 2 1.001 1.001 0 000-2zm15-4a1.001 1.001 0 000 2 1.001 1.001 0 000-2zM5 8a1.001 1.001 0 000 2 1.001 1.001 0 000-2zm11-3a1.001 1.001 0 000 2 1.001 1.001 0 000-2zM8 5a1.001 1.001 0 000 2 1.001 1.001 0 000-2zm4-1a1.001 1.001 0 000 2 1.001 1.001 0 000-2z"
    />
  </svg>
);

const siteMap = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M9 16v6h6v-6h-2v-3h5v3h-2v6h6v-6h-2v-5h-7V8h2V2H9v6h2v3H4v5H2v6h6v-6H6v-3h5v3z"
    />
  </svg>
);

const social = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M18.667 15.333c-1 0-1.889.452-2.5 1.152L8.59 12.697a3.268 3.268 0 000-1.394l7.577-3.788c.611.7 1.5 1.152 2.5 1.152A3.337 3.337 0 0022 5.333 3.337 3.337 0 0018.667 2a3.337 3.337 0 00-3.334 3.333c0 .24.028.472.076.697L7.833 9.818c-.611-.7-1.5-1.151-2.5-1.151C3.495 8.667 2 10.162 2 12s1.495 3.333 3.333 3.333c1 0 1.889-.451 2.5-1.151l7.577 3.788c-.05.225-.077.458-.077.697A3.337 3.337 0 0018.667 22 3.337 3.337 0 0022 18.667a3.337 3.337 0 00-3.333-3.334z"
    />
  </svg>
);

const trends = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M3 2c.51 0 1 .413 1 .922v11.551l3.246-3.436a.959.959 0 011.407 0l2.156 2.282 8.75-9.262a.958.958 0 011.313-.087l.094.087.08.097c.297.402.27.98-.08 1.35l-9.454 10.007a.959.959 0 01-1.407 0L7.95 13.23 4.44 16.943a.976.976 0 01-.44.27L4 20h17.078c.51 0 .922.49.922 1s-.413 1-.922 1H2.922A.922.922 0 012 21.078V2.922C2 2.412 2.49 2 3 2z"
    />
  </svg>
);

const webSpeed = color => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill={color} fillRule="evenodd">
      <rect width="8" height="2" x="4" y="5" rx="1" />
      <rect width="8" height="2" x="2" y="10" rx="1" />
      <rect width="8" height="2" x="4" y="15" rx="1" />
      <path d="M16.015 22v-9.565H14V2h7.134l-2.78 6.34H22z" />
    </g>
  </svg>
);

const menuCollapse = (divcolor, iconColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={divcolor} d="M0 0h24v24H0z" />
      <g fill={iconColor}>
        <path d="M7.333 12.667H6v-1.334h1.333zm1.334-1.334H7.333V10h1.334zm0 2.667H7.333v-1.333h1.334zM10 10H8.667V8.667H10zm0 5.333H8.667V14H10zm1.333-6.666H10V7.333h1.333zm0 8H10v-1.334h1.333zm1.334-9.334h-1.334V6h1.334zm0 10.667h-1.334v-1.333h1.334zM12.55 12.667h-1.333v-1.334h1.333zm1.333-1.334H12.55V10h1.333zm0 2.667H12.55v-1.333h1.333zm1.334-4h-1.334V8.667h1.334zm0 5.333h-1.334V14h1.334zm1.333-6.666h-1.333V7.333h1.333zm0 8h-1.333v-1.334h1.333zm1.333-9.334H16.55V6h1.333zm0 10.667H16.55v-1.333h1.333z" />
      </g>
    </g>
  </svg>
);

const menuExpand = (divcolor, iconColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={divcolor} d="M0 0h24v24H0z" />
      <g fill={iconColor}>
        <path
          d="M7.333 12.667H6v-1.334h1.333zm1.334-1.334H7.333V10h1.334zm0 2.667H7.333v-1.333h1.334zM10 10H8.667V8.667H10zm0 5.333H8.667V14H10zm1.333-6.666H10V7.333h1.333zm0 8H10v-1.334h1.333zm1.334-9.334h-1.334V6h1.334zm0 10.667h-1.334v-1.333h1.334zM12.55 12.667h-1.333v-1.334h1.333zm1.333-1.334H12.55V10h1.333zm0 2.667H12.55v-1.333h1.333zm1.334-4h-1.334V8.667h1.334zm0 5.333h-1.334V14h1.334zm1.333-6.666h-1.333V7.333h1.333zm0 8h-1.333v-1.334h1.333zm1.333-9.334H16.55V6h1.333zm0 10.667H16.55v-1.333h1.333z"
          transform="rotate(180 12 12)"
        />
      </g>
    </g>
  </svg>
);

const closeSquare = (divcolor, iconColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      transform="translate(-936 -408) translate(496 392) translate(440 16)"
    >
      <path fill={divcolor} d="M0 0H24V24H0z" />
      <path
        fill={iconColor}
        d="M17.25 15.75v1.5h-1.5v-1.5h1.5zm-9 0v1.5h-1.5v-1.5h1.5zm7.5-1.5v1.5h-1.5v-1.5h1.5zm-6 0v1.5h-1.5v-1.5h1.5zm4.5-1.5v1.5h-1.5v-1.5h1.5zm-3 0v1.5h-1.5v-1.5h1.5zm1.5-1.5v1.5h-1.5v-1.5h1.5zm-1.5-1.5v1.5h-1.5v-1.5h1.5zm3 0v1.5h-1.5v-1.5h1.5zm-4.5-1.5v1.5h-1.5v-1.5h1.5zm6 0v1.5h-1.5v-1.5h1.5zm-7.5-1.5v1.5h-1.5v-1.5h1.5zm9 0v1.5h-1.5v-1.5h1.5z"
      />
    </g>
  </svg>
);

export {
  dashboard,
  settingsGear,
  analytics,
  googleG,
  mobileComp,
  pageHealth,
  performance,
  siteMap,
  social,
  trends,
  webSpeed,
  menuCollapse,
  menuExpand,
  closeSquare,
  schema
};
