import React, { Component } from "react";
import PropTypes from "prop-types";

class PerformanceMetricsDetail extends Component {
  state = {
    LCPiconHover: false,
    FIDiconHover: false
  };

  toogleHover = isHovered => {
    this.setState(isHovered);
  };

  getColor(data, key) {
    if (data[key] === 0) {
      return { color: "rgb(0, 0, 0" };
    }
    if (key === "LCP") {
      if (data[key] < 2.5) {
        return { color: "rgb(10, 206, 106" };
      }
      if (data[key] < 4) {
        return { color: "rgb(255,164,0)" };
      }
      return { color: "rgb(255,78,65)" };
    }
    if (key === "CLS") {
      if (data[key] < 0.1) {
        return { color: "rgb(10,206,106)" };
      }
      if (data[key] < 0.25) {
        return { color: "rgb(255,164,0)" };
      }
      return { color: "rgb(255,78,65)" };
    }
    if (key === "FID") {
      if (data[key] < 100) {
        return { color: "rgb(10,206,106)" };
      }
      if (data[key] < 300) {
        return { color: "rgb(255,164,0)" };
      }
      return { color: "rgb(255,78,65)" };
    }
  }

  render() {
    const metrics = this.props.metrics;
    let metricsShow = {
      FID: metrics.FID.toString(),
      LCP: metrics.LCP.toString(),
      CLS: metrics.CLS.toString()
    };
    if (metrics.CLS === 0) {
      metricsShow.CLS = "There is not enough traffic on this URL to have data.";
    }
    if (metrics.LCP === 0) {
      metricsShow.LCP = "There is not enough traffic on this URL to have data.";
    }
    if (metrics.FID === 0) {
      metricsShow.FID = "There is not enough traffic on this URL to have data.";
    }
    return (
      <div style={{ width: "100%" }}>
        <div style={styles.metrics}>
          <div style={styles.secundaryHeaderDiv}>
            <h2 style={styles.secundaryHeader}>Metrics</h2>
          </div>
          <h3>Core Web Vitals Results</h3>
          <div style={styles.metricsContent}>
            <div style={styles.dataColumns}>
              <div>
                <span>Largest Contentful Paint - </span>
                <span style={this.getColor(metrics, "LCP")}>
                  <b>{metricsShow.LCP}</b>
                </span>
              </div>
            </div>
          </div>
          <div style={styles.metricsContent}>
            <div style={styles.dataColumns}>
              <div>
                <span>First Input Delay - </span>
                <span style={this.getColor(metrics, "FID")}>
                  <b>{metricsShow.FID}</b>
                </span>
              </div>
            </div>
          </div>
          <div style={styles.metricsContent}>
            <div style={styles.dataColumns}>
              <div>
                <span>Cumulative Layout Shift - </span>
                <span style={this.getColor(metrics, "CLS")}>
                  <b>{metricsShow.CLS}</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PerformanceMetricsDetail.propTypes = {
  metrics: PropTypes.object.isRequired,
  infoTexts: PropTypes.object.isRequired
};

const styles = {
  metrics: {
    padding: 40,
    marginBottom: 40,
    background: "#EDF0F2",
    fontFamily: "Avenir",
    width: "100%"
  },
  metricsContent: {
    background: "#ffffff",
    padding: "24px",
    margin: "10px 0 10px 0"
  },
  labData: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    marginBottom: 40,
    background: "#EDF0F2",
    fontFamily: "Avenir",
    width: "100%"
  },
  dataColumns: {
    display: "flex",
    justifyContent: "space-between"
  },
  labDataSeparator: {
    borderColor: "#000000",
    borderStyle: "solid",
    width: "100%"
  },
  secundaryHeaderDiv: {
    display: "flex",
    justifyContent: "center"
  },
  secundaryHeader: {
    color: "#030075",
    fontFamily: "Avenir",
    fontSize: 20
  },
  reportTestsHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBotttom: 50,
    fontFamily: "Avenir"
  },
  reportTestsHeaderText: {
    margin: "16px 0 0 0"
  },
  collapseButton: {
    cursor: "pointer",
    width: "32px",
    height: "32px"
  }
};

export default PerformanceMetricsDetail;
