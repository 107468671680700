import React from "react";
import { Row } from "antd";
import Report from "../../../audit/Report";
import AbstractReport from "./AbstractReport";
import GscAnalyticsStats from "../ruleResult/components/GscAnalyticsStats/GscAnalyticsStats";

class Gsc extends AbstractReport {
  state = {
    visible: true,
    name: "Search Console",
    reportName: "GSC Report"
  };

  renderReport = () => {
    const { report: reportData, auditId } = this.state;
    let content = (
      <div>
        <p style={styles.auditLoaded}>
          <strong>This URL does not have data yet</strong>
        </p>
      </div>
    );
    if (!reportData.extras.message) {
      content = (
        <Report
          name={this.state.name}
          score={reportData.rules.score}
          rulesTotal={reportData.rules.total}
          rulesPassedCount={reportData.rules.passed}
          rulesFailedCount={reportData.rules.failed}
          rulesData={reportData.rules.nodes}
          isProcessed={reportData.isProcessed}
          resultListType="serp"
          preRuleInserts={
            <GscAnalyticsStats
              auditId={auditId}
              data={{
                nodes: reportData.rules.nodes,
                extras: reportData.extras
              }}
            />
          }
        />
      );
    }
    return (
      <React.Fragment>
        <Row>{content}</Row>
      </React.Fragment>
    );
  };
}

export default Gsc;

const styles = {
  auditLoaded: {
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: 20,
    fontWeight: 800
  }
};
