import React, { Component } from "react";
import CommonProgress from "../common/CommonProgress";

class ReportDashboard extends Component {
  getStrokeColor = (reportTitle, score, performanceData) => {
    const progressScoreColor = {
      passed: "#51C41B",
      warning: "#FFAC00",
      failed: "#FD093F"
    };

    if (reportTitle === "Performance") {
      if (score >= 0 && score <= 4900) return progressScoreColor.failed;
      else if (score > 4900 && score <= 8000) return progressScoreColor.warning;
      else return progressScoreColor.passed;
    }

    if (score >= 0 && score <= 49) return progressScoreColor.failed;
    else if (score > 49 && score <= 80) return progressScoreColor.warning;
    else return progressScoreColor.passed;
  };

  getReportOverallInfo = (reportTitle, score, category) => {
    if (reportTitle === "Performance") {
      return (
        <div>
          <p style={styles.auditDescription}>
            Each of the Core Web Vitals represents a distinct facet of the user
            experience.
          </p>
        </div>
      );
    }
    return (
      <div>
        <p style={styles.auditDescription}>
          You took <strong>{this.props.numberOftests}</strong> tests
        </p>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={styles.testInfoDivLeft}>
            <p style={styles.testNumber}>{this.props.excellent}</p>
            <p style={styles.testStatusDesc}> Pass</p>
          </div>
          <div style={styles.testInfoDivRight}>
            <p style={styles.testNumber}>{this.props.poor}</p>
            <p style={styles.testStatusDesc}> Fail</p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { reportTitle, reportScore, category } = this.props;
    const reportScoreStrokeColor = this.getStrokeColor(
      reportTitle,
      reportScore,
      category
    );
    return (
      <div style={styles.reportDashboard}>
        <div style={styles.scoreDash}>
          <CommonProgress
            type="dashboard"
            score={
              reportTitle === "Performance" ? reportScore / 100 : reportScore
            }
            strokeColor={reportScoreStrokeColor}
            width={135}
            strokeWidth={10}
            style={styles.progressScore}
          />
        </div>
        <div style={styles.scoreList}>
          <p style={styles.auditHeader}>
            <strong>{reportTitle}</strong>
          </p>
          {this.getReportOverallInfo(reportTitle, reportScore, category)}
        </div>
      </div>
    );
  }
}

const styles = {
  reportDashboard: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  scoreDash: {
    minWidth: 150,
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
  },
  progressScore: {
    fontSize: 32
  },
  scoreList: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
    paddingLeft: 10,
    minWidth: 300
  },
  auditHeader: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: 24,
    fontWeight: 800,
    margin: "16px 0 0 0"
  },
  auditDescription: {
    color: "#8A8E9A",
    fontFamily: "Avenir",
    fontSize: 20,
    margin: 0
  },
  testInfoDivLeft: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    width: 100,
    borderWidth: "0 1px",
    borderStyle: "solid"
  },
  testInfoDivRight: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    width: 100,
    borderWidth: "0 1px 0 0",
    borderStyle: "solid"
  },
  testInfo1: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
    width: 100
  },
  testNumber: {
    display: "flex",
    justifyContent: "center"
  },
  testStatusDesc: {
    marginTop: "-25%",
    display: "flex",
    justifyContent: "center"
  }
};

export default ReportDashboard;
