import React from "react";
import { Table } from "antd/lib/index";
import { v4 as uuidv4 } from "uuid";

import "./TableDetail.css";
import CheckIcon from "../CheckIcon/CheckIcon";
import WarningIcon from "../WarningIcon/WarningIcon";
import FailedIcon from "../FailIcon/FailIcon";
import CommonMarkdown from "../../../../../common/CommonMarkdown";

const WARNING_TEXT = /__warning__/;
const BASE64_TEXT = "data:image/jpeg;base64";

const ColumnComponentFactory = {
  getByType: type => {
    switch (type) {
      case "boolean": {
        return boolean => {
          return boolean ? <CheckIcon /> : <FailedIcon />;
        };
      }
      case "image": {
        return imageSrc => {
          return <img alt="" className="table-details__image" src={imageSrc} />;
        };
      }
      case "performance": {
        // Custom table cells for perfomance repport
        return text => {
          if (typeof text === "boolean") {
            return text ? <CheckIcon /> : <FailedIcon />;
          }
          if (typeof text === "object" && text !== null) {
            if (text.type && text.type === "code") {
              return (
                <div
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderStyle: "solid",
                    borderColor: "#DADCE0",
                    borderWidth: 0.2,
                    padding: 5
                  }}
                >
                  <code>{text.value.toString()}</code>
                </div>
              );
            } else if (text.type && text.type === "link") {
              return <a href={text.url}>{text.text}</a>;
            }
            return JSON.stringify(text);
          }
          if (typeof text === "string" && text.includes(BASE64_TEXT)) {
            return <img alt="" className="table-details__image" src={text} />;
          }
          return text;
        };
      }
      default: {
        return text => {
          if (WARNING_TEXT.exec(text)) {
            return (
              <span>
                <WarningIcon /> {text.replace(WARNING_TEXT, "")}
              </span>
            );
          }
          return text;
        };
      }
    }
  }
};

const TableTitle = props => (
  <span
    style={{
      fontWeight: "bold",
      fontSize: "16px"
    }}
  >
    <CommonMarkdown>{props.title}</CommonMarkdown>
  </span>
);

const TableDetail = props => {
  const tableColumns = props.columns.map(column => {
    const { title, id: dataIndex, type, style } = column;
    const ColumnComponent =
      style === "bold"
        ? text => <b>{text}</b>
        : ColumnComponentFactory.getByType(type);

    return {
      title,
      dataIndex,
      render: ColumnComponent
    };
  });
  const data = props.data.map((item, index) => ({ ...item, uniqueKey: index }));

  Object.keys(data).forEach(key => {
    try {
      data[key].value = JSON.parse(data[key].value);
    } catch (e) {
      return null;
    }
  });
  return (
    <div
      style={{
        overflowX: "scroll",
        overflowY: "scroll",
        width: "100%",
        height: "100%",
        whiteSpace: "pre-line"
      }}
    >
      <Table
        title={() => <TableTitle title={props.title} />}
        dataSource={data}
        columns={tableColumns}
        bordered
        rowKey={() => uuidv4()}
      />
    </div>
  );
};

export default TableDetail;
