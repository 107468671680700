import React, { Component } from "react";
import PropTypes from "prop-types";
import GoogleSERPTool from "../GoogleSERPTool/GoogleSERPTool";
import { Input, Button, DatePicker } from "antd";
import cc from "classnames";
import createSERPValidator from "./services/SERPValidation/createSERPValidator";
import DetailForm from "../DetailForm/DetailForm";
import HowToFix from "../audit/report/ruleResult/components/HowToFix/HowToFix";
import DetailFormItem from "../DetailForm/components/DetailFormItem/DetailFormItem";
import DetailFormLabel from "../DetailForm/components/DetailFormLabel/DetailFormLabel";
import moment from "moment";
import "./GoogleSERPSimulator.css";
const { TextArea } = Input;

const HOW_TO_FIX = {
  titleLength: "Validates the title tag content is less than 70 characters.",
  descriptionLength:
    "Validates the description tag content is less than 160 characters.",
  titleDescriptionSame: "The title and description should be different."
};

const NOT_KEYWORDS = [
  "and",
  "yet",
  "the",
  "this",
  "these",
  "than",
  "those",
  "are",
  "you",
  "they",
  "then",
  "when",
  "what",
  "who",
  "where",
  "whose",
  "put",
  "ahead"
];

const SERPRemainingText = props => {
  const { remainingCharacter, maxCharacterLength, hasError } = props;
  return (
    <span
      style={styles.infoLength}
      className={cc(
        {
          "serp_simulator__remaining_text--red-text": hasError
        },
        "serp_simulator__remaining_text"
      )}
    >
      {`${remainingCharacter}/${maxCharacterLength}`}
    </span>
  );
};

class GoogleSERPSimulator extends Component {
  state = {
    selectedDevice: "desktop",
    url: this.props.defaultSiteUrl,
    description: this.props.defaultDescription,
    title: this.props.defaultTitle,
    date: this.props.date || new Date().toISOString().slice(0, 10),
    publisher: this.props.schemaPublisher,
    logo: this.props.schemaPublisher.logo.url,
    image: this.props.schemaImage
  };

  getKeywords = title => {
    let keywordsString = (title || this.state.title).trim();
    keywordsString = keywordsString
      .replace(/\s-\sWSJ/g, "")
      .replace(";", " ")
      .replace(/[^\w\d\s]/g, "")
      .replace(/\s\d+\s/g, "");
    let keywords = keywordsString.split(" ") || [];
    keywords = keywords
      .filter(
        keyword =>
          keyword.length >= 3 && !NOT_KEYWORDS.includes(keyword.toLowerCase())
      )
      .filter((keyword, index) => index < 5);
    return keywords;
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleCopy = field => {
    let value = this.state[field];
    let hiddenInput = document.createElement("textarea");
    hiddenInput.innerText = value;
    document.body.appendChild(hiddenInput);
    hiddenInput.select();
    document.execCommand("copy");
    hiddenInput.remove();
  };

  handleDatePublishedChange = value => {
    this.setState({
      date: value.format()
    });
  };

  handleDeviceSelection = selectionValue => {
    this.setState({ selectedDevice: selectionValue });
  };

  handleUpdateClick = () => {
    this.setState({ keywords: this.getKeywords() });
  };

  handleResetClick = e => {
    this.setState({
      title: this.props.defaultTitle,
      description: this.props.defaultDescription,
      logo: this.props.schemaPublisher.logo.url,
      image: this.props.schemaImage,
      date: this.props.date,
      keywords: this.getKeywords(this.props.defaultTitle)
    });
  };

  validateTitleAndDescriptionArticle = () => {
    const { maxTitleLength, maxDescriptionLength } = this.props;
    const { title, description } = this.state;
    const serpValidator = createSERPValidator({
      maxDescriptionLength,
      maxTitleLength
    });
    const titleResult = serpValidator.validateTitle(title);
    const descriptionResult = serpValidator.validateDescription(description);

    let howToFix = [];
    if (!titleResult.isValid) {
      howToFix.push(HOW_TO_FIX.titleLength);
    }

    if (!descriptionResult.isValid) {
      howToFix.push(HOW_TO_FIX.descriptionLength);
    }

    if (title === description) {
      howToFix.push(HOW_TO_FIX.titleDescriptionSame);
    }

    howToFix = howToFix.filter(item => item);
    return {
      titleResult,
      descriptionResult,
      howToFix
    };
  };

  render() {
    const { maxTitleLength, maxDescriptionLength, hasAMP } = this.props;
    const {
      url,
      title,
      description,
      date,
      publisher,
      logo,
      image,
      keywords
    } = this.state;
    const {
      titleResult,
      descriptionResult,
      howToFix
    } = this.validateTitleAndDescriptionArticle();
    const datePublishedWithMoment = date ? moment(date) : null;
    return (
      <div style={styles.wrapper}>
        <div style={styles.preview}>
          <span style={styles.titlesnipet}>Snippet Preview</span>
          <p>
            Use the simulator below to see how your article will appear in
            Google SERPS, and the Google Trends result for your article's first
            5 keywords over the past 30 days. You can experiment with changing
            title text to see updated Trends data scoring.
          </p>
        </div>
        <div style={styles.serpWrapper}>
          <GoogleSERPTool
            titleResult={titleResult}
            descriptionResult={descriptionResult}
            imageResult={image}
            publisher={publisher}
            logo={logo}
            siteUrl={url}
            date={date}
            onSelectDevice={this.handleDeviceSelection}
            hasAMP={hasAMP}
            keywords={keywords || this.getKeywords(this.props.defaultTitle)}
            onCopy={this.handleCopy}
          />
        </div>
        <div style={styles.simulatorContainer}>
          <span style={styles.titlesnipet}>Snippet Editor</span>
          <p>
            Use this tool to test various titles and descriptions. Click
            "update" to preview the results above.
          </p>
          <div style={styles.separator} />
          <DetailForm className="google-serp__form">
            <DetailFormItem>
              <div style={styles.formItem}>
                <DetailFormLabel htmlFor="title">Title</DetailFormLabel>
                <SERPRemainingText
                  remainingCharacter={titleResult.charactersRemaining}
                  maxCharacterLength={maxTitleLength}
                  hasError={!titleResult.isValid}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextArea
                  id="title"
                  onChange={this.handleChange}
                  rows={4}
                  value={title}
                  style={styles.titleInput}
                />
                <div
                  style={
                    titleResult.isValid
                      ? styles.validationCorrect
                      : styles.validationError
                  }
                />
              </div>
            </DetailFormItem>
            <DetailFormItem>
              <div style={styles.formItem}>
                <DetailFormLabel htmlFor="description">
                  Description
                </DetailFormLabel>
                <SERPRemainingText
                  remainingCharacter={descriptionResult.charactersRemaining}
                  maxCharacterLength={maxDescriptionLength}
                  hasError={!descriptionResult.isValid}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextArea
                  id="description"
                  rows={4}
                  onChange={this.handleChange}
                  value={description}
                  style={styles.descriptionInput}
                />
                <div
                  style={
                    descriptionResult.isValid
                      ? styles.validationCorrect
                      : styles.validationError
                  }
                />
              </div>
            </DetailFormItem>
            {this.state.selectedDevice === "amp" ? (
              <div>
                <DetailFormItem>
                  <div style={styles.formItem}>
                    <DetailFormLabel htmlFor="image">Image</DetailFormLabel>
                  </div>
                  <TextArea
                    id="image"
                    rows={2}
                    onChange={this.handleChange}
                    value={image}
                    style={styles.imagesURLInput}
                  />
                </DetailFormItem>
                <DetailFormItem>
                  <div style={styles.formItem}>
                    <DetailFormLabel htmlFor="logo">Logo</DetailFormLabel>
                  </div>
                  <TextArea
                    id="logo"
                    rows={2}
                    onChange={this.handleChange}
                    value={logo}
                    style={styles.imagesURLInput}
                  />
                </DetailFormItem>
                <DetailFormItem>
                  <DetailFormLabel htmlFor="datePublished">
                    Publish Date:{" "}
                  </DetailFormLabel>
                  <DatePicker
                    id="datePublished"
                    allowClear={false}
                    showTime
                    defaultValue={datePublishedWithMoment}
                    onOk={this.handleDatePublishedChange}
                    style={styles.datePublishedInput}
                  />
                </DetailFormItem>
              </div>
            ) : null}
            {howToFix.length > 0 && (
              <div>
                <HowToFix howToFixArray={howToFix} />
                <div style={styles.separator} />
              </div>
            )}
            <div style={styles.editorButtonsContainer}>
              <Button
                style={styles.resetButton}
                onClick={this.handleResetClick}
              >
                RESTORE
              </Button>
              <Button
                style={styles.updateButton}
                onClick={this.handleUpdateClick}
              >
                UPDATE
              </Button>
            </div>
          </DetailForm>
        </div>
      </div>
    );
  }
}

GoogleSERPSimulator.propTypes = {
  maxTitleLength: PropTypes.number.isRequired,
  maxDescriptionLength: PropTypes.number.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  defaultDescription: PropTypes.string.isRequired,
  defaultSiteUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  date: PropTypes.any
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column"
  },
  titlesnipet: {
    fontFamily: "Avenir",
    fontSize: 18,
    color: "#000000"
  },
  serpWrapper: {
    marginBottom: "24px"
  },
  preview: {
    marginTop: "24px",
    fontFamily: "Avenir",
    fontSize: 15,
    lineHeight: "20px"
  },
  validationCorrect: {
    display: "absolute",
    bottom: "1px",
    width: "100%",
    height: "4px",
    backgroundColor: "#73CF00"
  },
  validationError: {
    display: "absolute",
    bottom: "1px",
    width: "100%",
    height: "4px",
    backgroundColor: "#FD0A40"
  },
  inputContainer: {
    border: "1px solid #030075",
    borderRadius: "0px",
    marginBottom: 25
  },
  descriptionInput: {
    fontFamily: "Space Mono",
    fontSize: 13,
    borderRadius: "0px",
    color: "#000000"
  },
  titleInput: {
    fontFamily: "Space Mono",
    fontSize: 13,
    borderRadius: "0px",
    color: "#000000",
    height: 48
  },
  imagesURLInput: {
    fontFamily: "Space Mono",
    fontSize: 13,
    borderColor: "#030075",
    borderRadius: "0px",
    color: "#000000",
    marginBottom: 25,
    height: 36
  },
  datePublishedInput: {
    fontFamily: "Space Mono",
    fontSize: 13,
    borderColor: "#030075",
    borderRadius: "0px",
    color: "#000000",
    marginBottom: 25,
    height: 36,
    maxWidth: 150
  },
  infoLength: {
    fontFamily: "Avenir",
    fontSize: 13,
    fontWeight: 900
  },
  simulatorContainer: {
    paddingTop: 30,
    marginBottom: "5%",
    fontFamily: "Avenir",
    fontSize: 15,
    lineHeight: "20px"
  },
  separator: {
    margin: "20px 0"
  },
  formItem: {
    display: "flex",
    justifyContent: "space-between"
  },
  editorButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingBottom: "5%"
  },
  resetButton: {
    height: 48,
    width: 224,
    backgroundColor: "#FFFFFF",
    color: "#030075",
    fontFamily: "Space Mono",
    fontSize: 16,
    letterSpacing: "0.06px",
    borderStyle: "solid",
    borderColor: "#030075",
    borderRadius: 0,
    marginRight: "5%"
  },
  updateButton: {
    height: 48,
    width: 224,
    backgroundColor: "#030075",
    color: "#FFFFFF",
    borderRadius: 0,
    fontFamily: "Space Mono",
    fontSize: 16,
    letterSpacing: "0.06px"
  }
};
export default GoogleSERPSimulator;
