import React, { Component } from "react";
import { Icon, Collapse } from "antd";
import {
  collapseBtn,
  expandBtn,
  helpBtn
} from "../../../assets/svgInJs/panel-btns";
import CommonTooltip from "../CommonTooltip";
import PropTypes from "prop-types";

class CommonCollapsePanel extends Component {
  state = {
    active: this.props.active || false,
    helpIconHovered: false,
    expandIconHovered: false,
    collapseIconHovered: false
  };

  getHelpIconStyle = () => {
    const { helpIconHovered } = this.state;

    if (helpIconHovered) {
      return helpBtn("#FFF", "#030075");
    }
    return helpBtn("#030075", "#FFF");
  };

  getExpandIconStyle = () => {
    const { expandIconHovered } = this.state;

    if (expandIconHovered) {
      return expandBtn("#FFF", "#030075");
    }

    return expandBtn("#030075", "#FFF");
  };

  getCollapseIconStyle = () => {
    const { collapseIconHovered } = this.state;

    if (collapseIconHovered) {
      return collapseBtn("#FFF", "#030075");
    }

    return collapseBtn("#030075", "#FFF");
  };

  toggleHover = isHovered => {
    this.setState(isHovered);
  };

  toggleCollapse = newActive => {
    this.setState({ active: newActive });
  };

  getCollapseButton = isActive => {
    return isActive ? (
      <Icon
        style={styles.collapseButton}
        component={() => this.getCollapseIconStyle()}
        onClick={e => {
          e.preventDefault();
          this.toggleCollapse(false);
        }}
        onMouseEnter={e => {
          e.preventDefault();
          this.toggleHover({ collapseIconHovered: true });
        }}
        onMouseLeave={e => {
          e.preventDefault();
          this.toggleHover({ collapseIconHovered: false });
        }}
      />
    ) : (
      <Icon
        style={styles.collapseButton}
        component={() => this.getExpandIconStyle()}
        onClick={e => {
          e.preventDefault();
          this.toggleCollapse(true);
        }}
        onMouseEnter={e => {
          e.preventDefault();
          this.toggleHover({ expandIconHovered: true });
        }}
        onMouseLeave={e => {
          e.preventDefault();
          this.toggleHover({ expandIconHovered: false });
        }}
      />
    );
  };

  getHelpIcon = () => {
    const { tooltipAbout, tooltipBestPractice } = this.props;
    const columns = [];

    if (tooltipAbout) {
      columns.push({
        header: "About",
        content: tooltipAbout
      });
    }

    if (tooltipBestPractice) {
      columns.push({
        header: "Best Practice",
        content: tooltipBestPractice
      });
    }

    return (
      <CommonTooltip columns={columns}>
        <Icon
          style={styles.collapseButton}
          component={() => this.getHelpIconStyle()}
          onClick={event => {
            event.stopPropagation();
          }}
          onMouseEnter={e => {
            e.preventDefault();
            this.toggleHover({ helpIconHovered: true });
          }}
          onMouseLeave={e => {
            e.preventDefault();
            this.toggleHover({ helpIconHovered: false });
          }}
        />
      </CommonTooltip>
    );
  };

  getHeader = () => {
    const { title, isRule, passed, detailType } = this.props;
    let color = passed ? "#73CF00" : "#FD0A40";
    if (detailType) {
      switch (detailType) {
        case "opportunity":
          color = "#FFAC00";
          break;
        case "diagnostic":
          color = "#FD0A40";
          break;
        case "passedAudit":
          color = "#73CF00";
          break;
        default:
          break;
      }
    }
    const { active } = this.state;
    return (
      <div
        style={styles.headerContainer}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          this.toggleCollapse(!active);
        }}
      >
        <div style={styles.headerItemContainer}>
          {isRule ? (
            <div style={{ ...styles.passedDot, backgroundColor: color }} />
          ) : null}
          <span style={styles.header}>{title}</span>
        </div>
        <div style={styles.headerItemContainer}>
          {this.getHelpIcon()}
          {this.getCollapseButton(active)}
        </div>
      </div>
    );
  };

  render() {
    const { panelKey, children } = this.props;
    const { active } = this.state;
    return (
      <Collapse.Panel
        header={this.getHeader()}
        style={styles.panel}
        isActive={active}
        showArrow={false}
        key={`collapse_panel_${panelKey}`}
      >
        <div style={styles.panelContent}>{children}</div>
      </Collapse.Panel>
    );
  }
}

CommonCollapsePanel.propTypes = {
  title: PropTypes.string,
  isRule: PropTypes.bool,
  passed: PropTypes.bool,
  tooltipAbout: PropTypes.string,
  tooltipBestPractice: PropTypes.string,
  panelKey: PropTypes.string.isRequired
};

CommonCollapsePanel.defaultProps = {
  title: "",
  isRule: false,
  passed: false,
  tooltipAbout: "",
  tooltipBestPractice: ""
};

const styles = {
  panel: {
    background: "#EDF0F2",
    borderRadius: 0,
    margin: "8px 0",
    border: 0,
    overflow: "hidden"
  },
  panelContent: {
    borderColor: "#B4B6BD",
    borderTopStyle: "solid",
    borderWidth: "1px",
    margin: "0 24px"
  },
  collapseButton: {
    cursor: "pointer",
    width: "32px",
    height: "32px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#EDF0F2"
  },
  headerItemContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  passedDot: {
    height: "8px",
    width: "8px",
    borderRadius: 100,
    marginRight: "16px",
    marginLeft: "4px"
  },
  header: {
    color: "#030075",
    fontFamily: "Avenir",
    fontSize: "18px",
    lineHeight: "18px"
  }
};

export default CommonCollapsePanel;
