import React, { Component } from "react";
import moment from "moment";
import newscorpLogo from "../../assets/images/newscorp_logo.png";
import wsjLongLogo from "../../assets/images/wsj_long_logo.png";
import djsecondaryLogo from "../../assets/images/footer_logos/DJ_Secondary@1x.png";
import harpercollinsLogo from "../../assets/images/footer_logos/HarperCollins_Publishers@1x.png";
import newscorpaustraliaLogo from "../../assets/images/footer_logos/News_Corp_Australia@1x.png";
import newsukLogo from "../../assets/images/footer_logos/News_UK@1x.png";
import nypostLogo from "../../assets/images/footer_logos/NYPost@1x.png";
import realtorLogo from "../../assets/images/footer_logos/realtor-3@1x.png";
import storyfulLogo from "../../assets/images/footer_logos/Storyful@1x.png";

class CustomFooter extends Component {
  showModal = () => {
    this.props.modalComponent.current.setModalVisible(true);
  };

  render() {
    return (
      <React.Fragment>
        <div style={styles.footer}>
          <div style={styles.newscorpLogo}>
            <img src={newscorpLogo} alt={"NewsCorp"} />
          </div>
          <div style={styles.groupLogos}>
            <img style={styles.logo} src={newsukLogo} alt={"NewsUK"} />
            <img
              style={styles.logo}
              src={newscorpaustraliaLogo}
              alt={"AustraliaD"}
            />
            <img style={styles.logo} src={djsecondaryLogo} alt={"Dow Jones"} />
            <img
              src={wsjLongLogo}
              alt={"The Wall Street Journal"}
              style={styles.logo}
            />
            <img style={styles.logo} src={nypostLogo} alt={"NewYorkPost"} />
            <img style={styles.logo} src={realtorLogo} alt={"realtor"} />
            <img style={styles.logo} src={storyfulLogo} alt={"storyful"} />
            <img
              style={styles.logo}
              src={harpercollinsLogo}
              alt={"HarperCollinsPublishers"}
            />
          </div>
          <div style={styles.textInfo}>
            <p style={styles.credits}>
              Developed by News Corp Ⓒ{moment().format("YYYY")}
            </p>
            <p> | </p>
            <p
              style={styles.feedback}
              onClick={e => {
                e.preventDefault();
                this.showModal();
              }}
            >
              {" "}
              Feedback or Questions
            </p>
          </div>
          <div style={styles.textInfo}>
            <p style={styles.credits}>
              "Credits: Richard Nazarewicz, Arun Arunachalam, Jennie Baird,
              Rachel Turner, Grethel Bello, Wizeline, Martina Fugazzotto, Adam
              Bedell, Thowfeeq Mustafa, Lee Cookson"
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  newscorpLogo: {},
  groupLogos: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "row"
  },
  textInfo: {
    display: "flex",
    alignItems: "center"
  },
  credits: {
    color: "#8A8E9A",
    fontFamily: "Avenir",
    fontSize: 10,
    marginRight: 5
  },
  feedback: {
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: 10,
    fontWeight: 800,
    textDecoration: "underline",
    marginLeft: 5,
    cursor: "pointer"
  },
  logo: {
    marginRight: 12,
    maxWidth: "150px"
  }
};

export default CustomFooter;
