import React, { Component } from "react";
import CommonCollapse from "../../../../common/collapse/CommonCollapse";
import CommonCollapsePanel from "../../../../common/collapse/CommonCollapsePanel";
import CustomAlert from "../../../../common/CustomAlert";
import RuleResult from "../../RuleResult";
import PropTypes from "prop-types";
import { Api } from "../../../../../utils/Api";

class SeoCollapse extends Component {
  state = {
    ruleRefs: [],
    userRole: 0
  };

  getAlert = () => {
    const { passed, detailType } = this.props;

    let info = passed ? "passed" : "failed";
    if (detailType) {
      info = detailType;
    }
    return (
      <div style={styles.alert}>
        <CustomAlert style={styles.alert} info={info} />
      </div>
    );
  };

  toggleAllChildren = newActive => {
    const { ruleRefs } = this.state;
    ruleRefs.forEach(ref => {
      ref.current.toggleCollapse(newActive);
    });
  };

  getSeoHeader = (showCollapseHeader = true) => {
    return (
      <div style={styles.headingContainer}>
        {this.getAlert()}
        {showCollapseHeader && (
          <div style={styles.controlsContainer}>
            <span
              style={styles.controlActive}
              onClick={e => {
                e.preventDefault();
                this.toggleAllChildren(false);
              }}
            >
              CLOSE ALL
            </span>
            |
            <span
              style={styles.controlActive}
              onClick={e => {
                e.preventDefault();
                this.toggleAllChildren(true);
              }}
            >
              OPEN ALL
            </span>
          </div>
        )}
      </div>
    );
  };

  async componentDidMount() {
    const { rules } = this.props;
    const metadata = await Api.getUserMetadata();
    const currentRole = parseInt(metadata.role, 10);
    const refsArray = [];
    rules.forEach(() => {
      const newRef = React.createRef();
      refsArray.push(newRef);
    });
    this.setState({
      ruleRefs: refsArray,
      userRole: currentRole
    });
  }

  render() {
    const { ruleRefs } = this.state;
    const { rules, passed, detailType, selectedRule } = this.props;
    const panels = rules
      .map((rule, key) => {
        const unit = Math.floor(
          (rule.RoleTypeSecurity / this.state.userRole) % 10
        );
        const isVisibleByRole = unit >= 1 || this.state.userRole === 1111;
        return isVisibleByRole ? (
          <CommonCollapsePanel
            title={rule.name}
            isRule
            passed={passed}
            key={`panel_${rule.name.replaceAll(" ", "-").toLowerCase()}`}
            panelKey={rule.name.replaceAll(" ", "-").toLowerCase()}
            tooltipAbout={rule.description}
            tooltipBestPractice={rule.bestPractice}
            ref={ruleRefs[key]}
            detailType={detailType}
            active={selectedRule === rule.name}
          >
            <RuleResult
              key={`rule_result_${
                rule.id ? rule.id : rule.name.replace(" ", "-").toLowerCase()
              }`}
              passed={passed}
              name={rule.name}
              description={rule.description}
              details={rule.details}
              howToFix={rule.howToFix}
            />
          </CommonCollapsePanel>
        ) : null;
      })
      .filter(panel => panel);

    return (
      <React.Fragment>
        {this.getSeoHeader(panels.length > 0)}
        <CommonCollapse>{panels}</CommonCollapse>
      </React.Fragment>
    );
  }
}

SeoCollapse.propTypes = {
  passed: PropTypes.bool,
  rules: PropTypes.array.isRequired
};

const styles = {
  headingContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    height: "24px",
    justifyContent: "space-between"
  },
  alert: {
    width: "80%"
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "15px",
    color: "#8A8E9A",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15px",
    textAlign: "right",
    width: "173px",
    whiteSpace: "nowrap"
  },
  controlActive: {
    color: "#030075",
    cursor: "pointer"
  },
  controlInactive: {
    pointerEvents: "none'",
    color: "#8A8E9A",
    cursor: "not-allowed"
  }
};

export default SeoCollapse;
