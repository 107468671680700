import React from "react";
import PropTypes from "prop-types";
import cc from "classnames";
import DevicesRepository from "../../../services/DevicesRepository";
import getRelativeTimeFromNow from "../../../../common/services/getRelativeTimeFromNow";
import "./GoogleResultsBody.css";
const deviceIdsList = DevicesRepository.getAllIds();

const GoogleResultBody = props => {
  const getBodyDeviceClass = deviceId =>
    ({
      desktop: "preview_body_desktop",
      tablet: "preview_body_tablet",
      iPad: "preview_body_ipad",
      iPhone: "preview_body_iphone",
      amp: "amp-simulator"
    }[deviceId]);

  return (
    <div
      id="preview_body"
      className={cc("a_tag_google", getBodyDeviceClass(props.deviceId))}
    >
      <div className="wrapper-preview-meta">
        <div id="preview_description" className="descr_google">
          <span className="preview_body_date">{`${getRelativeTimeFromNow(
            props.date
          )} - `}</span>
          {props.description}
        </div>
      </div>
    </div>
  );
};

GoogleResultBody.propTypes = {
  deviceId: PropTypes.oneOf(deviceIdsList).isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.any
};

export default GoogleResultBody;
