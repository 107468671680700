import envVars from "./envVars";
import seoLogo from "./assets/images/seo-logo.svg";

const {
  REACT_APP_OKTA_CLIENT_ID: OKTA_CLIENT_ID,
  REACT_APP_OKTA_DOMAIN: OKTA_DOMAIN
} = envVars();

export const widgetConfig = {
  baseUrl: `https://${OKTA_DOMAIN}`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  authParams: {
    issuer: `https://${OKTA_DOMAIN}/oauth2/default`,
    authorizeURL: `https://${OKTA_DOMAIN}/oauth2/default/v1/authorize?prompt=none`
  },
  logo: seoLogo,
  i18n: {
    en: {
      // Labels
      "primaryauth.title": "Welcome to SEO Inspector!",
      "primaryauth.username.placeholder": "Okta ID",
      "primaryauth.password.placeholder": "Password",
      // Errors
      "error.username.required": "Please enter your Okta ID",
      "error.password.required": "Please enter your Okta password"
    }
  },
  features: {
    webauthn: true
  }
};

export const clientConfig = {
  issuer: `https://${OKTA_DOMAIN}/oauth2/default`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email"],
  pkce: true
};
