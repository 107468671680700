import React from "react";
import AbstractReport from "./AbstractReport";
import PropTypes from "prop-types";
import { Row } from "antd";
import Report from "../../Report";

class Seo extends AbstractReport {
  state = {
    name: "Page Health Report",
    reportName: "SEO Report"
  };

  renderReport = () => {
    const { report } = this.state;
    return (
      <Row>
        <Report
          name="Page Health"
          isProcessed={report.isProcessed}
          score={report.rules.score}
          rulesData={report.rules.nodes}
          rulesTotal={report.rules.total}
          rulesPassedCount={report.rules.passed}
          rulesFailedCount={report.rules.failed}
          resultListType="seo"
          selectedRule={this.props.selectedRule}
        />
      </Row>
    );
  };
}

Seo.propTypes = {
  auditId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default Seo;
