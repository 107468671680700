import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { menuExpand, menuCollapse } from "../../assets/svgInJs/side-menu";

const SideMenuCollapseButton = props => {
  return (
    <div
      style={styles.container}
      onClick={e => {
        e.preventDefault();
        props.onClickAction();
      }}
      onMouseEnter={e => {
        e.preventDefault();
        props.onMouseEnterAction();
      }}
      onMouseLeave={e => {
        e.preventDefault();
        props.onMouseLeaveAction();
      }}
    >
      {props.isCollapsed ? (
        <Icon
          style={styles.iconStyle}
          component={() => {
            if (props.isHovered) {
              return menuExpand("#fff", "#030075");
            }
            return menuExpand("#030075", "#fff");
          }}
        />
      ) : (
        <React.Fragment>
          <span style={styles.textStyle}>Publication</span>
          <Icon
            style={styles.iconStyle}
            component={() => {
              if (props.isHovered) {
                return menuCollapse("#fff", "#030075");
              }
              return menuCollapse("#030075", "#fff");
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

SideMenuCollapseButton.propTypes = {
  onClickAction: PropTypes.func,
  isCollapsed: PropTypes.bool,
  colors: PropTypes.object
};

SideMenuCollapseButton.defaultProps = {
  onClickAction: () => {},
  isCollapsed: false,
  colors: { divColor: "#fff", iconColor: "#ff0000" }
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    marginLeft: "8px"
  },
  textStyle: {
    height: "18px",
    width: "143px",
    color: "#FFFFFF",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: 800,
    lineHeight: "18px"
  }
};

export default SideMenuCollapseButton;
