import React from "react";
import cc from "classnames";
import "./DeviceOption.css";

const DeviceOption = ({
  deviceName,
  isSelected,
  wrapperWidth,
  isValid = false,
  onClick
} = {}) => {
  const deviceId = deviceName.toLowerCase();
  return (
    // `${wrapperWidth}%`
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={onClick}
      id={`a${deviceId}`}
      style={{ width: "15%" }}
      className={cc("icon-wrapper", {
        borderyes: isValid,
        borderno: !isValid,
        imlinkchosen: isSelected
      })}
    >
      <p className="device-name">{deviceName}</p>
    </a>
  );
};

export default DeviceOption;
