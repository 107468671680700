import React, { Component } from "react";
import { Icon } from "antd";
import PropTypes from "prop-types";

import CommonCheck from "../common/CommonCheck";
import CommonTooltip from "../common/CommonTooltip";
import { helpBtn } from "../../assets/svgInJs/panel-btns";
import "./HeaderStat.css";

class HeaderStat extends Component {
  state = {
    isHelpButtonHover: false
  };

  render() {
    const {
      title,
      value,
      background,
      onCheck,
      isChecked,
      name,
      description
    } = this.props;

    return (
      <div className="tile">
        <div className="titleContainer">
          <span className="title">{title}</span>
          <CommonCheck
            isChecked={isChecked}
            onCheck={onCheck}
            name={name}
            checkboxStyle="checkbox"
            iconStyle="checkboxIcon"
          />
        </div>
        <div
          className="valueContainer"
          style={{
            backgroundColor: background
          }}
        >
          <span className="value">{value}</span>
          <CommonTooltip
            columns={[
              {
                header: "About",
                content: description
              }
            ]}
          >
            <Icon
              component={() => helpBtn("#030075", "#FFF", "32")}
              onClick={event => {
                event.stopPropagation();
              }}
              onMouseEnter={e => {
                e.preventDefault();
                this.setState({ isHelpButtonHover: true });
              }}
              onMouseLeave={e => {
                e.preventDefault();
                this.setState({ isHelpButtonHover: false });
              }}
            />
          </CommonTooltip>
        </div>
      </div>
    );
  }
}

HeaderStat.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default HeaderStat;
