import ReportsLayoutInformation from "./ReportsLayoutInformation";

const reportsLayoutOrder = ReportsLayoutInformation.reduce(
  (dictionary, report, position) => {
    return {
      ...dictionary,
      [report.type]: position
    };
  },
  {}
);

const reportsTypeOpenByDefault = new Set(
  ReportsLayoutInformation.filter(r => r.isOpenByDefault).map(r => r.type)
);

const getDefaultOpenReportsType = reports => {
  return reports
    .map(r => r.type)
    .filter(type => reportsTypeOpenByDefault.has(type));
};

const getReportOrder = report => {
  const { type } = report;
  const reportLayout = reportsLayoutOrder[type];
  if (reportLayout !== undefined) {
    return reportLayout;
  }

  return ReportsLayoutInformation.length + 1;
};

const compareSortOfReportsType = (firstReport, secondReport) => {
  const firstReportOrder = getReportOrder(firstReport);
  const secondReportOrder = getReportOrder(secondReport);
  if (firstReportOrder < secondReportOrder) {
    return -1;
  }
  if (firstReportOrder > secondReportOrder) {
    return 1;
  }
  return 0;
};

const sortReportsByType = (reports = []) => {
  return reports.sort(compareSortOfReportsType);
};

export default {
  getDefaultOpenReportsType,
  sortReportsByType
};
