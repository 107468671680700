import React from "react";
import PropTypes from "prop-types";
import DeviceOption from "./components/DeviceOption/DeviceOption";
import PageResultPreviewer from "./components/PageResultPreviewer/PageResultPreviewer";
import DevicesRepository from "./services/DevicesRepository";
import "./GoogleSERPTool.css";

const devices = DevicesRepository.getAll();
const wrapperWidth = 100 / devices.length;

class GoogleSERPTool extends React.Component {
  state = {
    selectedDevice: "desktop"
  };

  handleDeviceClick = device => {
    this.setState({ selectedDevice: device });
    this.props.onSelectDevice(device);
  };

  render() {
    const {
      siteUrl,
      titleResult,
      descriptionResult,
      date,
      imageResult,
      publisher,
      logo,
      hasAMP,
      keywords
    } = this.props;
    const device = DevicesRepository.find(this.state.selectedDevice);
    const isValid = titleResult.isValid && descriptionResult.isValid;
    return (
      <section className="google-serp-tool">
        <div>
          <div id="linker">
            {devices.map(device => {
              return (
                <DeviceOption
                  key={device.id}
                  onClick={this.handleDeviceClick.bind(null, device.id)}
                  wrapperWidth={wrapperWidth}
                  deviceName={device.displayName}
                  isSelected={this.state.selectedDevice === device.id}
                  isValid={isValid}
                />
              );
            })}
            <div className="clear-both" />
          </div>

          <div id="prevline" />
          <PageResultPreviewer
            titleResult={titleResult}
            descriptionResult={descriptionResult}
            siteUrl={siteUrl}
            deviceId={device.id}
            date={date}
            publisher={publisher}
            logo={logo}
            image={imageResult}
            hasAMP={hasAMP}
            keywords={keywords}
            onCopy={this.props.onCopy}
          />
        </div>
      </section>
    );
  }
}

GoogleSERPTool.propTypes = {
  titleResult: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  descriptionResult: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  siteUrl: PropTypes.string.isRequired,
  date: PropTypes.any
};

export default GoogleSERPTool;
