import React, { Component } from "react";
import CommonMarkdown from "../../../../../common/CommonMarkdown";
import PropTypes from "prop-types";

class HowToFix extends Component {
  renderContentBox = content => {
    const { howToFixArray, headerText } = this.props;
    return (
      <div style={styles.howToFixContainer}>
        <div style={styles.headerContainer}>
          <span style={styles.headerText}>{headerText}</span>
          <div style={styles.headerLineContainer}>
            <div style={styles.headerLine} />
            <div style={styles.headerLine} />
          </div>
        </div>
        <div style={styles.tipsContainer}>
          {howToFixArray.map((howTo, index) => {
            return (
              <React.Fragment key={index}>
                <div style={styles.tipText}>
                  <CommonMarkdown>{howTo}</CommonMarkdown>
                </div>
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    return this.props.howToFixArray.length ? (
      <div style={styles.shadow}>{this.renderContentBox()}</div>
    ) : null;
  }
}

HowToFix.propTypes = {
  headerText: PropTypes.string,
  howToFixArray: PropTypes.arrayOf(PropTypes.string).isRequired
};

HowToFix.defaultProps = {
  headerText: "How to solve"
};

const styles = {
  shadow: {
    display: "inline-block",
    padding: "16px",
    backgroundImage:
      "linear-gradient(to bottom, transparent 50%, #f2f2f2 50%), linear-gradient(to right, #f2f2f2 50%, #cccccc 50%)",
    backgroundSize: "2px 2px, 2px 2px",
    backgroundRepeat: "repeat",
    width: "auto",
    height: "auto",
    marginTop: "10px"
  },
  howToFixContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 21px -2px rgba(0,0,0,0.47)",
    width: "auto"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "32px",
    width: "100%",
    backgroundColor: "#30C6D6",
    padding: "4px 16px"
  },
  headerText: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "auto",
    color: "#FFFFFF",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: 800,
    lineJeight: "24px",
    whiteSpace: "nowrap"
  },
  headerLine: {
    boxSizing: "border-box",
    height: "1px",
    backgroundColor: "#FFFFFF",
    width: "100%"
  },
  headerLineContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "8px 0 8px 10px"
  },
  tipsContainer: {
    width: "100%",
    padding: "16px"
  },
  tipText: {
    color: "#000000",
    fontFamily: "Space Mono",
    fontSize: "13px",
    lineHeight: "16px"
  }
};

export default HowToFix;
