import React from "react";
import PropTypes from "prop-types";
import AMPCarouselItemSimulator from "../../../../../AMPCarouselItemSimulator/AMPCarouselItemSimulator";
import SchemaPropTypes from "../../../../../AMPCarouselItemSimulator/services/SchemaPropTypes";

const AmpCarouselItemDetail = props => {
  const {
    data: { schema, url }
  } = props;

  return <AMPCarouselItemSimulator schema={schema} key={url} />;
};

AmpCarouselItemDetail.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string.isRequired,
    schema: SchemaPropTypes
  })
};

export default AmpCarouselItemDetail;
