import { Icon } from "antd";
import React from "react";
import colors from "../../../../../../constants/colors";

const styles = {
  warningRuleIcon: {
    color: colors.warning
  }
};

const WarningIcon = () => (
  <Icon type="warning" style={styles.warningRuleIcon} />
);

export default WarningIcon;
