import React from "react";
import PropTypes from "prop-types";

const SubtitleDetail = props => {
  return <p style={styles.collapseDesc}>{props.text}</p>;
};

SubtitleDetail.propTypes = {
  text: PropTypes.string.isRequired
};

const styles = {
  collapseDesc: {
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: 13,
    fontWeight: 800
  }
};

export default SubtitleDetail;
