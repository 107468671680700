import React from "react";

const greenCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="44"
    viewBox="0 0 48 44"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#73CF00" transform="translate(-467 -299)">
        <g transform="translate(235 201)">
          <path d="M248.457 141.356L232 124.958l5.066-5.428 12.944 12.109L273.58 98l6.42 4.159-28.518 38.962a2 2 0 01-3.025.235z" />
        </g>
      </g>
    </g>
  </svg>
);

export { greenCheck };
