import React, { Component } from "react";
import UrlInput from "./common/UrlInput";
import Loading from "./common/Loading";
import LoadError from "./common/LoadError";
import SubTitle from "./common/SubTitle";
import Logo from "../assets/images/seo-logo.svg";
import UseAuthWrapper from "./common/UseAuthWrapper";
import { Api } from "../utils/Api";
import RoleFilterModal from "./common/RoleFilter/RoleFilterModal";
import RoleMenuButton from "./common/RoleMenuButton";

class Start extends Component {
  constructor(props) {
    super(props);
    this.userRoleModalComponent = React.createRef();
  }

  handleRoleMenuVisibleChange = visible => {
    this.setState({ roleMenuVisible: visible });
  };

  hideRoleMenu = () => {
    this.setState({ roleMenuVisible: false });
  };

  toogleHover = hoverState => {
    this.setState(hoverState);
  };

  render() {
    if (!this.state.availableReportsLoaded) return <Loading />;

    if (this.state.availableReportsLoadError) return <LoadError />;

    return (
      <div style={styles.container}>
        <UseAuthWrapper>
          <RoleFilterModal ref={this.userRoleModalComponent} />
        </UseAuthWrapper>
        <div style={styles.wrapper}>
          <RoleMenuButton
            userRoleModalComponent={this.userRoleModalComponent}
          />
          <div style={styles.logoBarContainer}>
            <div style={styles.logo}>
              <img src={Logo} alt={"logo"} />
            </div>
            <div style={{ width: "824px" }}>
              <UseAuthWrapper>
                <UrlInput
                  reports={this.state.reports}
                  selectedReports={this.state.selectedReports}
                  onReportSelectionChange={this.onReportSelectionChange}
                  disabled={
                    !this.props.userRole || this.props.userRole === "undefined"
                  }
                />
              </UseAuthWrapper>
            </div>
          </div>
        </div>
        <SubTitle style={styles.subTitle} />
      </div>
    );
  }

  state = {
    availableReportsLoaded: false,
    availableReportsLoadError: false,
    selectedReports: null,
    reports: null,
    roleMenuVisible: false,
    settingsButtonHover: false
  };

  componentDidMount() {
    Api.getAvailableReports().then(
      availableReports => {
        this.setState({
          availableReportsLoaded: true,
          selectedReports: availableReports.selectedReports,
          reports: availableReports.reports
        });
      },
      error => {
        this.setState({
          availableReportsLoaded: true,
          availableReportsLoadError: true
        });
      }
    );
  }

  onReportSelectionChange = selectedReports => {
    this.setState({ selectedReports });
  };
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    justifyContent: "space-between"
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flex: 8,
    borderWidth: 0,
    backgroundImage:
      "linear-gradient(to bottom, transparent 50%, #f2f2f2 50%), linear-gradient(to right, #f2f2f2 50%, #cccccc 50%)",
    backgroundSize: "2px 2px, 2px 2px",
    position: "relative"
  },
  subTitle: {
    display: "flex",
    flex: 1,
    alignSelf: "flex-end",
    height: "80px"
  },
  greetingWrapper: {
    marginTop: 50,
    textAlign: "center"
  },
  greetingHeader: {
    fontSize: 24,
    width: "100%"
  },
  greetingTip: {
    fontSize: 16,
    fontStyle: "italic",
    width: "100%"
  },
  logo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "25px"
  },
  logoBarContainer: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  roleButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    width: "32px",
    backgroundColor: "#030075",
    position: "fixed",
    top: 0,
    right: 0
  },
  iconStyles: {
    color: "#fff"
  }
};

export default Start;
