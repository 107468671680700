import React from "react";
import { Form } from "antd";
import CommonInput from "../CommonInput";
import PropTypes from "prop-types";
import FeedbackSatisfactionButtons from "./FeedbackSatisfactionButtons";
import CommonSubmit from "../CommonSubmit";

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

const FeedbackForm = Form.create({
  name: "global_state",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.name,
        value: props.name.value
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      }),
      message: Form.createFormField({
        ...props.message,
        value: props.message.value
      }),
      images: Form.createFormField({
        ...props.images,
        value: props.images.value
      }),
      satisfaction: Form.createFormField({
        ...props.satisfaction,
        value: props.satisfaction.value
      })
    };
  }
})(props => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    validateFields
  } = props.form;
  const nameError = isFieldTouched("name") && getFieldError("name");
  const emailError = isFieldTouched("email") && getFieldError("email");
  const satisfactionError =
    isFieldTouched("satisfaction") && getFieldError("satisfaction");
  const messageError = isFieldTouched("message") && getFieldError("message");
  return (
    <Form
      style={{ height: "auto" }}
      onSubmit={e => {
        e.preventDefault();
        validateFields(err => {
          if (!err) props.submitFunc();
        });
      }}
    >
      <Form.Item
        validateStatus={nameError ? "error" : ""}
        help={nameError || ""}
      >
        {getFieldDecorator("name", {
          rules: [{ required: true, message: "A name is required!" }]
        })(<CommonInput inputTitle="Name" type="text" />)}
      </Form.Item>
      <Form.Item
        validateStatus={emailError ? "error" : ""}
        help={emailError || ""}
      >
        {getFieldDecorator("email", {
          rules: [
            { type: "email", message: "The input is not valid E-mail!" },
            { required: true, message: "A contact email is required!" }
          ]
        })(<CommonInput inputTitle="Email" type="email" />)}
      </Form.Item>
      <Form.Item
        validateStatus={messageError ? "error" : ""}
        help={messageError || ""}
      >
        {getFieldDecorator("message", {
          rules: [{ required: true, message: "A message is required!" }]
        })(
          <CommonInput
            inputTitle="Message"
            type="textarea"
            rows={8}
            style={{ resize: "none" }}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("images", {
          rules: [{ required: false }]
        })(
          <CommonInput
            inputTitle="Screenshots (optional)"
            type="file"
            innerText="Click or drag file to this area to upload images"
            accept="image/*"
          />
        )}
      </Form.Item>
      <Form.Item
        validateStatus={satisfactionError ? "error" : ""}
        help={satisfactionError || ""}
      >
        {getFieldDecorator("satisfaction", {
          rules: [
            { required: true, message: "A satisfaction level is required!" }
          ]
        })(
          <FeedbackSatisfactionButtons inputTitle="What is your overall satisfaction?" />
        )}
      </Form.Item>
      <Form.Item>
        <CommonSubmit value="SEND" disabled={hasErrors(getFieldsError())} />
      </Form.Item>
    </Form>
  );
});

FeedbackForm.propTypes = {
  submitFunc: PropTypes.func
};

FeedbackForm.defaultProps = {
  submitFunc: () => {}
};

export default FeedbackForm;
