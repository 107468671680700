import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div style={styles.loading}>
      <Spin size="large" style={styles.spinner} />
      <span>Loading...</span>
    </div>
  );
};

const styles = {
  loading: {
    marginTop: 200,
    textAlign: "center",
    fontSize: 26
  },
  spinner: {
    marginRight: 24
  }
};

export default Loading;
