const getAuditRulesData = reports => {
  let auditRules = { total: 0, failed: 0, loading: false };
  const reportsLoading = [];
  reports.forEach(report => {
    auditRules.total = auditRules.total + report.rules.total;
    auditRules.failed = auditRules.failed + report.rules.failed;
    if (report.name !== "Performance Report" && report.name !== "GSC Report") {
      reportsLoading.push(report.rules.total === 0);
    }
  });

  if (
    reportsLoading.filter(isLoading => isLoading).length >=
    reports.length / 2
  ) {
    auditRules.loading = true;
  }

  return auditRules;
};

const getReportData = (reports, reportType, performanceScore) => {
  const report = reports.find(report => report.type === reportType);
  return {
    score:
      report.type === "performance" ? performanceScore : report.rules.score,
    failed: report.rules.failed,
    isProcessed: report.isProcessed,
    totalRules: report.rules.total
  };
};

const getReportByName = (reports, reportName) => {
  const reportByName = reports.find(report => report.name === reportName);
  return reportByName;
};

module.exports = { getAuditRulesData, getReportData, getReportByName };
