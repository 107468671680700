import React, { Component } from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import { isDevURL } from "../../utils/reportUtils";
import {
  dashboard,
  googleG,
  mobileComp,
  pageHealth,
  performance,
  trends,
  siteMap,
  social,
  schema
} from "../../assets/svgInJs/side-menu";
import PropTypes from "prop-types";

class SideMenu extends Component {
  state = {
    selectedKey: window.location.href.split("/").pop()
  };

  getMenuItemsInfo = (props, getSelectedIconColor) => {
    const { auditedURL } = props;
    const isDev = isDevURL(auditedURL);
    return [
      {
        key: "dashboard",
        icon: () => dashboard(getSelectedIconColor("dashboard")),
        text: "Dashboard",
        link: `/audit/${props.auditId}/dashboard`
      },
      {
        key: "google",
        icon: () => googleG(getSelectedIconColor("google")),
        text: "Google SERPS",
        link: `/audit/${props.auditId}/google`
      },
      {
        key: "analytics",
        icon: () => trends(getSelectedIconColor("gsc")),
        text: "Search Console",
        link: `/audit/${props.auditId}/analytics`,
        disabledForDEV: isDev
      },
      {
        key: "seo",
        icon: () => pageHealth(getSelectedIconColor("seo")),
        text: "Page Health",
        link: `/audit/${props.auditId}/seo`
      },
      {
        key: "schema",
        icon: () => schema(getSelectedIconColor("schema")),
        text: "Schema Markup",
        link: `/audit/${props.auditId}/schema`
      },
      {
        key: "sitemaps",
        icon: () => siteMap(getSelectedIconColor("sitemaps")),
        text: "Sitemap",
        link: `/audit/${() => props.auditId}/sitemaps`,
        disabledForDEV: isDev
      },
      {
        key: "performance",
        icon: () => performance(getSelectedIconColor("performance")),
        text: "Core Web Vitals",
        link: `/audit/${props.auditId}/performance`,
        disabledForDEV: isDev
      },
      {
        key: "social",
        icon: () => social(getSelectedIconColor("social")),
        text: "Social Tags",
        link: `/audit/${props.auditId}/social`
      },
      {
        key: "mobile",
        icon: () => mobileComp("#2494A0"),
        text: (
          <span>
            Mobile
            <br />
            Compatibility
          </span>
        )
      }
    ];
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.selectedKey !== this.state.selectedKey;
  }

  getSelectedIconColor = iconName => {
    const color = this.state.selectedKey === iconName ? "#000000" : "#030075";
    return color;
  };

  render() {
    const menuItems = this.getMenuItemsInfo(
      this.props,
      this.getSelectedIconColor
    )
      .filter(item => !item.disabledForDEV)
      .map(item => (
        <Menu.Item
          key={item.key}
          style={styles.menuItem}
          onClick={() => {
            this.setState({
              selectedKey: window.location.href.split("/").pop()
            });
          }}
        >
          <Icon style={styles.iconStyle} component={item.icon} />
          <span style={styles.menuText}>{item.text}</span>
          {item.link && <Link key={`${item.key}-report`} to={item.link} />}
        </Menu.Item>
      ));
    return (
      <div style={styles.container}>
        <Menu
          style={styles.sideMenu}
          mode="inline"
          selectedKeys={this.state.selectedKey}
        >
          {menuItems}
        </Menu>
        <div style={styles.collapseButton}>{this.props.collapseButton}</div>
      </div>
    );
  }
}

SideMenu.propTypes = {
  collapseButton: PropTypes.node
};

SideMenu.defaultProps = {
  collapseButton: <div />
};

const COLLAPSE_BUTTON_HEIGHT = "56px";
const COLLAPSE_BUTTON_MARGIN_BOTTOM = "16px";

const styles = {
  container: {
    height: "100%"
  },
  sideMenu: {
    backgroundColor: "#30c6d6",
    fontFamily: "Avenir",
    color: "#020065",
    fontSize: 12,
    fontWeight: 800,
    borderStyle: "none",
    height: `calc(100% - ${COLLAPSE_BUTTON_HEIGHT} - ${COLLAPSE_BUTTON_MARGIN_BOTTOM})`
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    borderStyle: "solid",
    borderColor: "transparent",
    borderWidth: "0 24px 0 0",
    margin: 0,
    height: "56px",
    width: "100%"
  },
  iconStyle: {
    height: "24px",
    width: "24px"
  },
  menuText: {
    width: "100%",
    lineHeight: "15px"
  },
  collapseButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: COLLAPSE_BUTTON_HEIGHT,
    marginTop: "auto",
    marginBottom: COLLAPSE_BUTTON_MARGIN_BOTTOM
  }
};

export default SideMenu;
