import React from "react";
import PropTypes from "prop-types";
import SeoCollapse from "./SeoCollapse/SeoCollapse";
import ResultList from "./ResultList";

class ResultListPerformance extends ResultList {
  getOpportunities = () =>
    this.props.rules.filter(
      rule => !rule.passed && rule.detailType === "opportunity"
    );

  getDiagnostics = () =>
    this.props.rules.filter(
      rule => !rule.passed && rule.detailType !== "opportunity"
    );

  render() {
    const opportunities = this.getOpportunities();
    const diagnostics = this.getDiagnostics();
    const passedAudits = this.getSuccessRules();

    /* TODO: Alert notifications should be work when the results of the report load dynamically */
    return (
      <React.Fragment>
        {opportunities && opportunities.length > 0 ? (
          <SeoCollapse detailType={"opportunity"} rules={opportunities} />
        ) : null}
        <br />
        {diagnostics && diagnostics.length > 0 ? (
          <SeoCollapse detailType={"diagnostic"} rules={diagnostics} />
        ) : null}
        <br />
        {passedAudits && passedAudits.length > 0 ? (
          <SeoCollapse detailType={"passedAudit"} rules={passedAudits} />
        ) : null}
      </React.Fragment>
    );
  }
}

ResultList.propTypes = {
  rules: PropTypes.array,
  name: PropTypes.string
};

export default ResultListPerformance;
