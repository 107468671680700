import { Icon } from "antd";
import React from "react";
import colors from "../../../../../../constants/colors";

const styles = {
  passedRuleIcon: {
    color: colors.passed
  }
};

const CheckIcon = () => <Icon type="check" style={styles.passedRuleIcon} />;

export default CheckIcon;
