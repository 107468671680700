import { combineReducers } from "redux";

const userRoleReducer = (state = 0, action) => {
  switch (action.type) {
    case "UPDATE_USER_ROLE":
      return action.payload;
    default:
      return state;
  }
};

const userRoleCheckedReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_USER_ROLE_CHEKED":
      return action.payload;
    default:
      return state;
  }
};

const allReducers = combineReducers({
  userRole: userRoleReducer,
  userRoleChecked: userRoleCheckedReducer
});

export default allReducers;
