import createTitleValidation from "./createTitleValidation";
import createDescriptionValidation from "./createDescriptionValidation";

const createSERPValidator = ({ maxTitleLength, maxDescriptionLength } = {}) => {
  const validateTitle = createTitleValidation({ maxTitleLength });
  const validateDescription = createDescriptionValidation({
    maxDescriptionLength
  });

  return {
    validateTitle,
    validateDescription
  };
};

export default createSERPValidator;
