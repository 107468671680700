class CustomError extends Error {
  constructor(name, message, code = 0, ...args) {
    super(message, ...args);
    this.name = name;
    if (code !== 0) {
      this.code = code;
    }
  }
}

export default CustomError;
