import React, { Component } from "react";

class CommonSelect extends Component {
  getOptions(options) {
    let selectOptions = [<option value={""}>Select...</option>];
    options.forEach(option => {
      selectOptions.push(
        <option value={Object.keys(option)[0]}>
          {Object.values(option)[0]}
        </option>
      );
    });
    return selectOptions;
  }

  render() {
    const { options, style, id, name, onChange } = this.props;
    return (
      <select style={style} id={id} name={name} onChange={onChange}>
        {this.getOptions(options)}
      </select>
    );
  }
}

export default CommonSelect;
