import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Spin } from "antd";
import ResultList from "./report/resultList/ResultList";
import ResultListSEO from "./report/resultList/ResultListSEO";
import ResultListPerformance from "./report/resultList/ResultListPerformance";
import ReportDashboard from "../common/ReportDashboard";
import _ from "lodash";

class Report extends Component {
  compareRuleTestGroups = (ruleA, ruleB) => {
    if (ruleA.testGroup < ruleB.testGroup) {
      return -1;
    } else if (ruleA.testGroup > ruleB.testGroup) {
      return 1;
    } else {
      return 0;
    }
  };

  getTestGroups = () => {
    const orderedRules = this.props.rulesData.sort(this.compareRuleTestGroups);

    let testGroups = [];

    orderedRules.forEach(rule => {
      const testGroupName = rule.testGroup;
      const currentGroup = _.find(testGroups, ["groupName", testGroupName]);
      if (currentGroup) {
        testGroups[_.indexOf(testGroups, currentGroup)].rules.push(rule);
      } else {
        testGroups.push({
          groupName: testGroupName,
          rules: [rule]
        });
      }
    });

    return testGroups;
  };

  render() {
    if (this.props.rulesTotal === 0 || !this.props.isProcessed) {
      const antIcon = <Icon type="loading" spin />;
      return (
        <div style={styles.loadingReport}>
          <Spin indicator={antIcon} size="large" style={styles.spin} />
        </div>
      );
    }
    const {
      name,
      score,
      rulesPassedCount,
      rulesFailedCount,
      rulesTotal,
      resultListType,
      rulesData,
      metrics,
      selectedRule
    } = this.props;
    let resultList;

    if (resultListType === "seo") {
      resultList = (
        <ResultListSEO rules={rulesData} selectedRule={selectedRule} />
      );
    } else if (resultListType === "performance") {
      resultList = <ResultListPerformance rules={rulesData} />;
    } else if (resultListType !== "serp") {
      resultList = <ResultList rules={rulesData} />;
    }

    return (
      <div>
        <div style={styles.scoreContanier}>
          <div style={styles.scoreDashboardDiv}>
            <ReportDashboard
              reportTitle={name}
              reportScore={score}
              numberOftests={rulesTotal}
              excellent={rulesPassedCount}
              poor={rulesFailedCount}
              performanceData={metrics}
            />
          </div>
          <div style={{ width: "100%" }}>{this.props.preRuleInserts}</div>
        </div>
        {resultList}
      </div>
    );
  }
}

Report.propTypes = {
  name: PropTypes.string.isRequired,
  isProcessed: PropTypes.bool.isRequired,
  rulesData: PropTypes.array,
  rulesTotal: PropTypes.number,
  rulesPassedCount: PropTypes.number,
  rulesFailedCount: PropTypes.number,
  resultListType: PropTypes.string,
  preRuleInserts: PropTypes.element
};

Report.defaultProps = {
  rulesData: [],
  rulesTotal: 0,
  rulesPassedCount: 0,
  rulesFailedCount: 0,
  resultListType: "",
  preRuleInserts: <div />
};

const styles = {
  header: {
    fontWeight: "bold",
    color: "#2c8bbb",
    fontSize: 16,
    marginTop: 16
  },
  spin: {
    marginLeft: 6
  },
  scoreDashboardDiv: {
    marginBottom: 20
  },
  scoreContanier: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  score: {
    fontSize: 28
  },
  loadingReport: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    minHeight: "50px"
  }
};

export default Report;
