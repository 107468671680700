import React, { Component } from "react";
import CommonProgress from "./CommonProgress";
import { Card, Spin } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class DashboardCard extends Component {
  getStrokeColor = () => {
    const { reportScore, reportName } = this.props;
    const score = reportScore.score;
    const progressScoreColor = {
      passed: "#51C41B",
      warning: "#FFAC00",
      failed: "#FD093F"
    };

    if (reportName === "Core Web Vitals") {
      if (score <= 49) return progressScoreColor.failed;
      else if (score > 49 && score <= 89) return progressScoreColor.warning;
      else if (score > 90) return progressScoreColor.passed;
    }

    if (score >= 0 && score <= 30) return progressScoreColor.failed;
    else if (score > 30 && score <= 80) return progressScoreColor.warning;
    else return progressScoreColor.passed;
  };

  render() {
    const {
      descriptionText,
      reportName,
      reportLink,
      auditId,
      reportScore,
      needsReload,
      enable
    } = this.props;

    return (
      <Link to={`/audit/${auditId}/${reportLink}`}>
        <Card.Grid id={`${reportLink}-card`} style={styles.gridStyle}>
          <div id={`${reportLink}-card`} style={styles.cardTitle}>
            <p style={styles.title1}>{reportName}</p>
          </div>
          {(reportScore.isProcessed === false ||
            reportScore.totalRules === 0) &&
          reportLink !== "analytics" ? (
            needsReload ? (
              <div id={`${reportLink}-card`} style={styles.cardGridContent}>
                <div
                  id={`${reportLink}-failed-div`}
                  style={styles.reloadButton}
                >
                  <span>{`Report Timed out :( `}</span>
                  <span>Click this card to continue loading</span>
                </div>
              </div>
            ) : (
              <Spin size="large" style={styles.spin} />
            )
          ) : enable ? (
            <div style={styles.cardData}>
              <div style={styles.cardGridContent}>
                <CommonProgress
                  score={reportScore.score}
                  strokeColor={this.getStrokeColor()}
                  style={styles.pBar}
                  size="small"
                  styleType="custom-progress-bar"
                  showInfo={false}
                />
              </div>
              <div id={`${reportLink}-card`} style={styles.cardGridContent}>
                <p style={styles.textP}>{descriptionText}</p>
              </div>
              <span id={`${reportLink}-card`} style={styles.failedTests}>
                <span>
                  <b style={styles.failedTestsBold}>{reportScore.failed}</b>{" "}
                  Issue(s) to fix
                </span>
              </span>
            </div>
          ) : (
            <div style={styles.cardData}>
              <div id={`${reportLink}-card`} style={styles.cardGridContent}>
                <p style={styles.textP}>
                  This report is not available at this moment. Coming soon...
                </p>
              </div>
            </div>
          )}
        </Card.Grid>
      </Link>
    );
  }
}

DashboardCard.propTypes = {
  descriptionText: PropTypes.string,
  reportName: PropTypes.string,
  reportLink: PropTypes.string,
  auditId: PropTypes.string.isRequired,
  reportScore: PropTypes.object.isRequired,
  needsReload: PropTypes.bool,
  enable: PropTypes.bool
};

DashboardCard.defaultProps = {
  descriptionText: "",
  reportName: "Report",
  reportLink: "",
  needsReload: false,
  enable: true
};

const styles = {
  title1: {
    color: "#201E84",
    fontFamily: "Avenir",
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 0,
    lineHeight: "16px"
  },
  cardTitle: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    height: "24px"
  },
  cardGridContent: {
    display: "flex",
    justifyContent: "left"
  },
  gridStyle: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    height: "168px",
    background: "#FFFFFF",
    borderColor: "#EDF0F2",
    borderWidth: 3,
    borderStyle: "solid",
    padding: "16px 32px"
  },
  cardData: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  pBar: {
    maxWidth: "100%",
    minWidth: 100,
    marginBottom: "2px"
  },
  textP: {
    color: "#5F6065",
    fontFamily: "Space Mono",
    fontSize: "15px",
    lineHeight: "19px"
  },
  failedTests: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "14px",
    marginTop: "auto"
  },
  failedTestsBold: {
    color: "#000",
    fontFamily: "Avenir",
    marginTop: "auto"
  },
  testDescr: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: 13
  },
  reloadButton: {
    height: "24px",
    width: "180px",
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "22px"
  }
};

export default DashboardCard;
