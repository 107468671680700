import React from "react";
import CommonButton from "./common/CommonButton";

const PageNotFound = props => {
  return (
    <div style={styles.backdrop}>
      <div style={styles.middleSection}>
        <div style={styles.header}>404 Page not Found!</div>
        <div style={styles.sub}>
          Sorry, the page you're looking for doesn't exist.
        </div>
        <div style={styles.button}>
          <CommonButton
            type="primary"
            onClick={e => {
              e.preventDefault();
              props.history.goBack();
            }}
          >{`< Go Back`}</CommonButton>
        </div>
      </div>
    </div>
  );
};

const styles = {
  backdrop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundImage:
      "linear-gradient(to bottom, transparent 50%, #f2f2f2 50%), linear-gradient(to right, #f2f2f2 50%, #cccccc 50%)",
    backgroundSize: "2px 2px, 2px 2px",
    backgroundRepeat: "repeat"
  },
  middleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "55%",
    width: "100%",
    backgroundColor: "#ffffff"
  },
  header: {
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: "40px",
    fontWeight: 800
  },
  sub: {
    header: {
      color: "#000000",
      fontFamily: "Avenir",
      fontSize: "40px",
      fontWeight: 800,
      padding: "16px"
    }
  },
  button: {
    width: "200px",
    margin: "16px"
  }
};

export default PageNotFound;
