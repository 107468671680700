import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input, Upload, Icon } from "antd";
import { Api } from "../../utils/Api";

class CommonInput extends Component {
  renderInput = type => {
    const { onChange } = this.props;
    switch (type) {
      case "file":
        const { accept, innerText } = this.props;
        return (
          <Upload.Dragger
            action={Api.postToFirebase()}
            accept={accept}
            style={styles.dragger.draggerStyle}
            {...this.props}
          >
            <p>
              <Icon type="inbox" style={styles.dragger.iconStyle} />
            </p>
            <div style={styles.dragger.innerTextStyle}>{innerText}</div>
          </Upload.Dragger>
        );
      case "textArea":
      case "textarea":
        const { TextArea } = Input;
        return (
          <TextArea
            rows={5}
            autoFocus
            onChange={onChange}
            type={type}
            style={{ ...styles.inputBox, height: "auto" }}
            autoComplete="off"
          />
        );
      case "children":
        return this.props.children;
      case "text":
      default:
        return (
          <Input
            autoFocus
            onChange={onChange}
            type={type}
            style={styles.inputBox}
            autoComplete="off"
            value={this.props.value}
          />
        );
    }
  };

  render() {
    const { inputTitle, type } = this.props;
    return (
      <React.Fragment>
        <span style={styles.title}>{inputTitle}</span>
        {this.renderInput(type)}
      </React.Fragment>
    );
  }
}

const styles = {
  title: {
    height: "24px",
    width: "121px",
    color: "#5B5858",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontWeight: 800,
    lineHeight: "24px"
  },
  inputBox: {
    boxSizing: "border-box",
    height: "48px",
    width: "320px",
    border: "1px solid #030075",
    borderRadius: 0,
    fontFamily: "Space Mono",
    fontSize: "13px",
    lineHeight: "24px"
  },
  dragger: {
    draggerStyle: {
      background: "#F5F6F7"
    },
    iconStyle: {
      fontSize: "30px",
      color: "#5F6065"
    },
    innerTextStyle: {
      color: "#5F6065",
      fontFamily: "Space Mono",
      fontSize: "13px",
      lineHeight: "22px",
      padding: "0px 13px"
    }
  }
};

CommonInput.propTypes = {
  inputTitle: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func
};

CommonInput.defaultProps = {
  type: "text",
  onChange: () => {}
};

export default CommonInput;
