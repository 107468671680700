import React, { Component } from "react";
import {
  trendsFilterType,
  trendsGraphTypeType,
  trendsSpecificationType
} from "../types";

class GoogleGraphWidget extends Component {
  state = {
    graphRendererIsReady: false
  };

  constructor(props) {
    super(props);
    this.graphContainerRef = React.createRef();
    this.trendsGrapher = null;
  }

  renderGraphWidget = (trendsGraphType, trendsSpecification, trendsFilter) => {
    this.graphContainerRef.current.innerHTML = "";
    this.trendsGrapher.embed.renderExploreWidgetTo(
      this.graphContainerRef.current,
      trendsGraphType,
      trendsSpecification,
      trendsFilter
    );
  };

  componentDidMount() {
    // Maybe we could download this code and add it as a global
    // For doing that we need to eject the project to
    // we can tweak webpack config
    // Or find another way to do it.
    const script = document.createElement("script");
    script.src =
      "https://ssl.gstatic.com/trends_nrtr/1709_RC01/embed_loader.js";
    script.async = true;

    this.graphContainerRef.current.appendChild(script);

    script.onload = () => {
      this.setState(() => {
        /* eslint-disable-next-line no-undef */
        this.trendsGrapher = trends;
        return { graphRendererIsReady: true };
      });
    };
  }

  render() {
    return <div ref={this.graphContainerRef} />;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { graphRendererIsReady } = this.state;
    if (graphRendererIsReady) {
      this.renderGraphWidget(
        this.props.trendsGraphType,
        this.props.trendsSpecification,
        this.props.trendsFilter
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.trendsSpecification !== nextProps.trendsSpecification) {
      return true;
    }

    if (this.props.trendsGraphType !== nextProps.trendsGraphType) {
      return true;
    }

    if (this.props.trendsFilter !== nextProps.trendsFilter) {
      return true;
    }

    const { graphRendererIsReady } = this.state;
    const { graphRendererIsReady: graphRenderIsGoingTobeReady } = nextState;
    const trendsGrapherChangeToReady =
      !graphRendererIsReady && graphRenderIsGoingTobeReady;

    if (trendsGrapherChangeToReady) {
      return true;
    }

    return false;
  }
}

GoogleGraphWidget.propTypes = {
  trendsGraphType: trendsGraphTypeType.isRequired,
  trendsSpecification: trendsSpecificationType.isRequired,
  trendsFilter: trendsFilterType.isRequired
};

export default GoogleGraphWidget;
