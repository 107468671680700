import React, { Component } from "react";
import HappyFace from "../../assets/images/happy_face.svg";
import SadFace from "../../assets/images/sad_face.svg";

const getTestInfo = infoTest => {
  if (infoTest === "passed") {
    return {
      textInfoTest: "Well done!",
      iconInfoTest: HappyFace,
      textColor: "#70D000"
    };
  } else if (infoTest === "failed") {
    return {
      textInfoTest: "Issues that need immediate attention",
      iconInfoTest: SadFace,
      textColor: "#FD0A40"
    };
  } else if (infoTest === "opportunity") {
    return {
      textInfoTest: "Opportunities",
      textColor: "#8A8E9A"
    };
  } else if (infoTest === "diagnostic") {
    return {
      textInfoTest: "Diagnostics",
      textColor: "#8A8E9A"
    };
  } else if (infoTest === "passedAudit") {
    return {
      textInfoTest: "Passed Audits",
      textColor: "#8A8E9A"
    };
  }
};

class CustomAlert extends Component {
  render() {
    const { textInfoTest, iconInfoTest, textColor } = getTestInfo(
      this.props.info
    );
    return (
      <div style={styles.content}>
        {iconInfoTest ? (
          <img src={iconInfoTest} alt="alertIcon" style={styles.icon} />
        ) : null}
        <p
          style={{
            color: textColor,
            fontFamily: "Avenir",
            fontSize: 18,
            fontWeight: 800
          }}
        >
          {textInfoTest}
        </p>
      </div>
    );
  }
}

const styles = {
  content: {
    display: "flex",
    justifyContent: "flex-start"
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 20
  }
};

export default CustomAlert;
