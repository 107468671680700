export default () => {
  return {
    API_ENDPOINT:
      process.env.REACT_APP_API_ENDPOINT || window.REACT_APP_API_ENDPOINT,
    SLACK_WEBHOOK_URL:
      process.env.REACT_APP_SLACK_WEBHOOK_URL ||
      window.REACT_APP_SLACK_WEBHOOK_URL,
    AUTH0_DOMAIN:
      process.env.REACT_APP_AUTH0_DOMAIN || window.REACT_APP_AUTH0_DOMAIN,
    AUTH0_KEY: process.env.REACT_APP_AUTH0_KEY || window.REACT_APP_AUTH0_KEY,
    MAX_API_CALLS:
      process.env.REACT_APP_MAX_API_CALLS || window.REACT_APP_MAX_API_CALLS,
    REACT_APP_OKTA_CLIENT_ID:
      process.env.REACT_APP_OKTA_CLIENT_ID || window.REACT_APP_OKTA_CLIENT_ID,
    REACT_APP_OKTA_DOMAIN:
      process.env.REACT_APP_OKTA_DOMAIN || window.REACT_APP_OKTA_DOMAIN,
    REACT_APP_GOOGLE_ANALYTCS_ID:
      process.env.REACT_APP_GOOGLE_ANALYTCS_ID ||
      window.REACT_APP_GOOGLE_ANALYTCS_ID,
    REACT_APP_GOOGLE_TAG_MANAGER_ID:
      process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID ||
      window.REACT_APP_GOOGLE_TAG_MANAGER_ID
  };
};
