import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Popover } from "antd";
import RoleMenu from "./RoleMenu";
import UseAuthWrapper from "./UseAuthWrapper";
import { settingsGear } from "../../assets/svgInJs/side-menu";

class RoleMenuButton extends Component {
  state = {
    isHover: false,
    isVisible: false
  };

  toogleHover = hoverState => {
    this.setState({
      isHover: hoverState
    });
  };

  handleRoleMenuVisibleChange = visible => {
    this.setState({ isVisible: visible });
  };

  render() {
    return (
      <div>
        <div
          style={styles.roleButton}
          onMouseEnter={e => {
            e.preventDefault();
            this.toogleHover(true);
          }}
          onMouseLeave={e => {
            e.preventDefault();
            this.toogleHover(false);
          }}
        >
          <Popover
            content={
              <UseAuthWrapper>
                <RoleMenu
                  className="role-menu"
                  modalComponent={this.props.userRoleModalComponent}
                  closeAction={() => this.handleRoleMenuVisibleChange(false)}
                />
              </UseAuthWrapper>
            }
            trigger="click"
            placement="bottomRight"
            visible={this.state.isVisible}
            onVisibleChange={this.handleRoleMenuVisibleChange}
          >
            <Icon
              style={styles.iconStyle}
              component={() => {
                if (this.state.isHover) {
                  return settingsGear("#fff", "#030075");
                }
                return settingsGear("#030075", "#fff");
              }}
            />
          </Popover>
        </div>
      </div>
    );
  }
}

const styles = {
  roleButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    width: "32px",
    backgroundColor: "#030075",
    position: "fixed",
    top: 0,
    right: 0
  },
  iconStyle: {
    color: "#fff"
  }
};

RoleMenuButton.propTypes = {
  userRoleModalComponent: PropTypes.object.isRequired
};

export default RoleMenuButton;
