import React from "react";
import Markdown from "markdown-to-jsx";
import PropTypes from "prop-types";

const CommonMarkdown = props => {
  const markdownOptions = {
    overrides: {
      a: {
        props: {
          target: "_blank",
          onClick: event => {
            event.stopPropagation();
          },
          style: props.linkStyle
        }
      }
    }
  };

  return <Markdown options={markdownOptions}>{props.children}</Markdown>;
};

CommonMarkdown.propTypes = {
  children: PropTypes.node.isRequired,
  linkStyle: PropTypes.object
};

CommonMarkdown.defaultProps = {
  linkStyle: {}
};

export default CommonMarkdown;
