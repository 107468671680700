const getPublicationNameThroughEmail = mail => {
  const mailRegex = /@.*\./;
  if (!mail || !mail.match(mailRegex)) {
    return "";
  }

  const company = mail
    .match(mailRegex)[0]
    .replace("@", "")
    .replace(".", "");

  switch (company) {
    case "barrons":
      return "Barrons";
    case "realtor":
      return "Realtor";
    case "harpercollins":
      return "HarperCollins";
    case "nypost":
      return "New York Post";
    case "marketwatch":
      return "Market Watch";
    case "fnlondon":
      return "Financial News London";
    case "mansionglobal":
      return "Mansion Global";
    case "storyful":
      return "Storyful";
    case "wsj":
    case "wsj-asia":
      return "Wall Street Journal";
    case "dowjones":
      return "Dow Jones";
    case "thetimes":
    case "sunday-times":
      return "The Times";
    case "newscorp":
    default:
      return "Newscorp";
  }
};

const convertISOStringToDate = isoDate => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  let month = date.getMonth();
  let dt = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  const strimDate = `${year}-${month}-${dt} ${hours}:${minutes}:${seconds}`;
  return strimDate;
};

export default {
  getPublicationNameThroughEmail,
  convertISOStringToDate
};
