import React from "react";

const getCalendarIcon = () => (
  <svg
    height="16"
    viewBox="0 0 15 16"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m6.37579987 6.28238938v3.74141052h-3.74141049v-3.74141052zm4.22486313-6.28238938h1.8707053v1.24713683h.9353526c.4157144 0 .779459.15589054 1.0912447.46767631.3117858.31178576.4676763.67553037.4676763 1.09124472v10.60066304c0 .4157144-.1558905.779459-.4676763 1.0912447-.3117857.3117858-.6755303.4676763-1.0912447.4676763h-11.84779987c-.41571435 0-.77945895-.1558905-1.09124472-.4676763-.31178576-.3117857-.46767631-.6755303-.46767631-1.0912447v-10.60066304c0-.41571435.15589055-.77945896.46767631-1.09124472.31178577-.31178577.67553037-.46767631 1.09124472-.46767631h.93535263v-1.24713683h1.87070524v1.24713683h6.2356841zm2.8060579 13.4067209v-8.26228148h-11.84779987v8.26228148z"
      fill="#07007a"
      fillRule="evenodd"
      transform="translate(0 .5)"
    />
  </svg>
);

export { getCalendarIcon };
