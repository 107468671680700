import React from "react";
import PropTypes from "prop-types";
import cc from "classnames";
import "./DetailFormItem.css";
const DetailFormItem = props => {
  const { className, children, ...otherProps } = props;
  return (
    <div className={cc("detail-form__item")} {...otherProps}>
      {children}
    </div>
  );
};

DetailFormItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default DetailFormItem;
