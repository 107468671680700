import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Alert, notification } from "antd";
import { Api } from "../../utils/Api";
import { isDevURL } from "../../utils/reportUtils";
import userAuthInfoConversions from "../../utils/userAuthInfoConversions";

const ONLY_DEV_URL_REPORTS = ["seo", "serp", "social"];
class UrlInput extends Component {
  state = {
    auditResponsePending: false,
    disabled: false,
    showAuditResponseError: false
  };

  openServerErrorNotification = () => {
    notification.error({
      message: "Sever not responding",
      description:
        "There is either problem with Internet connection or with our server. Please, check you connection or try again"
    });
  };

  getUserInfo = (userAuthInfo, auditUrl) => {
    if (!userAuthInfo) {
      return {};
    }

    const { nickname, email, updated_at } = userAuthInfo;
    const userInfo = {
      name: nickname,
      email,
      lastLogin: userAuthInfoConversions.convertISOStringToDate(updated_at),
      company: userAuthInfoConversions.getPublicationNameThroughEmail(email),
      testedUrl: auditUrl
    };
    return userInfo;
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      const protocolRegex = /^(https:\/\/|http:\/\/)/;
      const ampPageRegex = /\/amp\//;
      if (values.url) {
        this.setState({
          auditResponsePending: true,
          showAuditResponseError: false
        });

        let auditUrl = values.url.replace(ampPageRegex, "/");
        const userInfo = this.getUserInfo(this.props.user, auditUrl);
        if (!protocolRegex.test(auditUrl)) {
          auditUrl = "https://" + auditUrl;
        }

        const selectedReports = isDevURL(auditUrl)
          ? ONLY_DEV_URL_REPORTS
          : this.props.selectedReports;

        Api.createAudit({
          auditUrl,
          userInfo,
          reports: selectedReports,
          reportParams: {}
        }).then(
          auditId => {
            this.setState({ auditResponsePending: false });
            this.props.history.push({
              pathname: `/audit/${auditId}/dashboard`,
              state: {
                pageURL: values.url
              }
            });
            this.props.handleID(auditId);
          },
          error => {
            this.setState({
              auditResponsePending: false,
              showAuditResponseError: true
            });
            this.openServerErrorNotification();
          }
        );
      }
    });

    this.props.additionalActionOnSubmit.forEach(async func => {
      await func();
    });
  };

  areNoReportsSelected = () => {
    return this.props.selectedReports.length === 0;
  };

  urlProtocolCorrect = (rule, value, callback) => {
    if (
      value &&
      value.indexOf("http://") !== 0 &&
      value.indexOf("https://") !== 0
    ) {
      callback(
        `Url should start either with "http://" or "https://", will default to "https://" when missing.`
      );
    } else {
      callback();
    }
  };

  handleOnChange = event => {
    event.preventDefault();
    event.target.value = event.target.value.replace(/\s/g, "");
  };

  render() {
    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const urlError = isFieldTouched("url") && getFieldError("url");
    const userInfo = this.getUserInfo(this.props.user || {});
    return (
      <div style={styles.urlInputDiv} className="url-input">
        <div style={styles.formWrapper}>
          <Form
            layout="inline"
            id="form-audit"
            onSubmit={this.handleSubmit}
            style={styles.form}
          >
            <div style={styles.formInnerContainer}>
              <Form.Item htmlFor="client-data-form" style={{ display: "none" }}>
                <p>{JSON.stringify(userInfo)}</p>
              </Form.Item>
              <Form.Item
                validateStatus={urlError ? "error" : ""}
                htmlFor="input-form"
                style={{ ...styles.formItem, width: "100%" }}
              >
                {getFieldDecorator("url", {
                  rules: [{ validator: this.urlProtocolCorrect }],
                  initialValue: this.props.defaultUrl
                })(
                  <Input
                    className="InputSEO"
                    style={styles.urlInput}
                    placeholder="Enter a new URL to inspect"
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    type="url"
                  />
                )}
              </Form.Item>
              <Form.Item style={styles.formItem}>
                <Button
                  style={styles.btn}
                  id="btn-submit-form"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.auditResponsePending}
                  disabled={this.areNoReportsSelected() || this.state.disabled}
                >
                  GO
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        {this.areNoReportsSelected() ? (
          <Alert
            style={styles.noReportsSelectedAlert}
            message="No reports selected"
            description="At least one report should be selected to execute the Audit"
            type="warning"
            showIcon
          />
        ) : null}
      </div>
    );
  }
}

UrlInput.propTypes = {
  onReportSelectionChange: PropTypes.func,
  reports: PropTypes.array,
  selectedReports: PropTypes.array,
  handleID: PropTypes.func,
  additionalActionOnSubmit: PropTypes.arrayOf(PropTypes.func),
  user: PropTypes.object,
  disabled: PropTypes.bool
};

UrlInput.defaultProps = {
  handleID: id => {},
  additionalActionOnSubmit: [() => {}],
  user: {
    nickname: "",
    name: "",
    picture: "",
    updated_at: "",
    email: "",
    email_verified: true,
    sub: ""
  },
  disabled: false
};

const styles = {
  urlInputDiv: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  formWrapper: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    width: "100%",
    height: "auto"
  },
  form: {
    boxShadow: "4px 6px 0px 0px #010064",
    height: 40,
    marginBottom: "2px",
    width: "100%"
  },
  formInnerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    whiteSpace: "nowrap"
  },
  urlIcon: {
    color: "rgba(0,0,0,.25)"
  },
  urlInput: {
    height: "40px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    flex: 9,
    fontFamily: "Space Mono",
    fontSize: "13px",
    lineHeight: "24px",
    borderRadius: 0
  },
  btn: {
    height: "40px",
    width: "96px",
    backgroundColor: "#010064",
    fontFamily: "Space Mono",
    fontSize: 16,
    letterSpacing: 0.06,
    textAlign: "center",
    borderRadius: 0,
    borderStyle: "none"
  },
  noReportsSelectedAlert: {
    marginTop: "24px"
  },
  formItem: {
    margin: 0,
    padding: 0
  }
};

export { UrlInput };
export default withRouter(Form.create({ name: "url_from" })(UrlInput));
