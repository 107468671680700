import React from "react";
import PropTypes from "prop-types";
import CommonMarkdown from "../../../../../common/CommonMarkdown";

const MarkdownDetail = props => (
  <CommonMarkdown>{props.children}</CommonMarkdown>
);

MarkdownDetail.propTypes = {
  children: PropTypes.node.isRequired
};

export default MarkdownDetail;
