import React, { Component } from "react";
import { Radio } from "antd";
import PropTypes from "prop-types";
import HappyFace from "../../../assets/images/happy_face.svg";
import HappyFaceGray from "../../../assets/images/happy_face_gray.svg";
import NormalFace from "../../../assets/images/normal_face.svg";
import NormalFaceGray from "../../../assets/images/normal_face_gray.svg";
import SadFace from "../../../assets/images/sad_face.svg";
import SadFaceGray from "../../../assets/images/sad_face_gray.svg";

class FeedbackSatisfactionButtons extends Component {
  state = {
    value: ""
  };

  constructor(props) {
    super(props);
    this.setValue(props.value);
  }

  getIconColor = (srcColor, srcGray, expectedValue) => {
    const { value } = this.state;

    if (value === expectedValue) {
      return srcColor;
    }

    return srcGray;
  };

  setValue = value => {
    this.setState({ value: value });
  };

  renderFaces = () => {
    const { onChange } = this.props;
    return (
      <Radio.Group style={styles.faceContainer} onChange={onChange}>
        <Radio
          value="GOOD"
          onClick={e => {
            e.preventDefault();
            this.setValue("GOOD");
          }}
        >
          <img
            src={this.getIconColor(HappyFace, HappyFaceGray, "GOOD")}
            alt="Satisfied"
            height="32px"
            width="32px"
          />
        </Radio>
        <Radio
          value="REGULAR"
          onClick={e => {
            e.preventDefault();
            this.setValue("REGULAR");
          }}
        >
          <img
            src={this.getIconColor(NormalFace, NormalFaceGray, "REGULAR")}
            alt="Not Satisfied nor Insatisfied"
            height="32px"
            width="32px"
          />
        </Radio>
        <Radio
          value="BAD"
          onClick={e => {
            e.preventDefault();
            this.setValue("BAD");
          }}
        >
          <img
            src={this.getIconColor(SadFace, SadFaceGray, "BAD")}
            alt="Insatisfied"
            height="32px"
            width="32px"
          />
        </Radio>
      </Radio.Group>
    );
  };

  render() {
    const { inputTitle } = this.props;
    return (
      <React.Fragment>
        <span style={styles.title}>{inputTitle}</span>
        {this.renderFaces()}
      </React.Fragment>
    );
  }
}

const styles = {
  title: {
    height: "24px",
    width: "121px",
    color: "#5B5858",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontWeight: 800,
    lineHeight: "24px"
  },
  faceContainer: {
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    width: "100%",
    padding: "0 10%"
  }
};

FeedbackSatisfactionButtons.propTypes = {
  inputTitle: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

FeedbackSatisfactionButtons.defaultProps = {
  onChange: () => {}
};

export default FeedbackSatisfactionButtons;
