import React from "react";
import PropTypes from "prop-types";
import SeoCollapse from "./SeoCollapse/SeoCollapse";
import ResultList from "./ResultList";

class ResultListSEO extends ResultList {
  render() {
    const failedRules = this.getFailedRules();
    const passedRules = this.getSuccessRules();
    const { selectedRule } = this.props;
    /* TODO: Alert notifications should be work when the results of the report load dynamically */
    return (
      <React.Fragment>
        {failedRules.length > 0 ? (
          <SeoCollapse
            passed={false}
            rules={failedRules}
            selectedRule={selectedRule}
          />
        ) : null}
        <br />
        {passedRules.length > 0 ? (
          <SeoCollapse passed rules={passedRules} selectedRule={selectedRule} />
        ) : null}
      </React.Fragment>
    );
  }
}

ResultList.propTypes = {
  rules: PropTypes.array,
  name: PropTypes.string
};

export default ResultListSEO;
