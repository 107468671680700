import React, { Component } from "react";
import PropTypes from "prop-types";
import Detail from "./ruleResult/Detail";
import HowToFix from "./ruleResult/components/HowToFix/HowToFix";

class RuleResult extends Component {
  render() {
    return (
      <div>
        <div style={styles.details}>
          {this.props.details.map((detail, index) => {
            return (
              <Detail
                key={detail.id || index}
                type={detail.type}
                data={detail.data}
                name={this.props.name}
              />
            );
          })}
        </div>
        <HowToFix howToFixArray={this.props.howToFix} />
      </div>
    );
  }
}

RuleResult.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  passed: PropTypes.bool,
  details: PropTypes.array.isRequired,
  howToFix: PropTypes.arrayOf(PropTypes.string)
};

RuleResult.defaultProps = {
  howToFix: [],
  exportButton: { enable: false }
};

const styles = {
  header: {
    marginTop: 8,
    fontWeight: "bold"
  },
  name: {
    marginLeft: 8
  },
  details: {
    marginTop: "25px"
  }
};

export default RuleResult;
