import React from "react";
import moment from "moment";
import { Collapse } from "antd";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

import Detail from "../../Detail";

const { Panel } = Collapse;

const getCsvName = (elementTitle, url) => {
  let article = url.replaceAll("http(s)?://", "");
  article = url.split("/");
  article = url[url.length - 1];

  const date = moment().format("YYYY-MM-DD");

  const elementName = elementTitle.replaceAll(" ", "-");
  return `${elementName}-${date}-${article}.csv`;
};

const transformData = tableData =>
  tableData.reduce((result, currentData) => {
    const { field, value, children } = currentData;
    result.push({ key: field, value });
    if (children) {
      const transformedChildren = transformData(children);
      result = result.concat(transformedChildren);
    }
    return result;
  }, []);

const renderPanelInsides = (panels, parentId, keyHelper, exportButton) => {
  let counter = 0 + keyHelper + 10;
  let getHeader = panel => <h3>{panel.header}</h3>;
  if (exportButton && exportButton.enable) {
    getHeader = panel => (
      <div style={{ flexFlow: "row" }}>
        <h3 style={{ display: "inline-flex", width: "50%" }}>{panel.header}</h3>
        <CSVLink
          style={{
            display: "inline-flex",
            width: "50%",
            flexDirection: "row-reverse"
          }}
          filename={getCsvName(panel.header, exportButton.url)}
          data={transformData(panel.data.data.data)}
          className="btn btn-primary"
        >
          Export as CSV
        </CSVLink>
      </div>
    );
  }
  return (
    <React.Fragment>
      {panels.map(panel => {
        const newKey = `${parentId}-${counter}`;
        counter += 11;
        return (
          <div key={newKey}>
            {getHeader(panel)}
            <Detail type={panel.data.type} data={panel.data.data} />
          </div>
        );
      })}
    </React.Fragment>
  );
};

const renderPanels = (data, exportButton) => {
  let counter = 0;
  return (
    <React.Fragment>
      {data.map(panel => {
        counter += 100;
        return (
          <Panel
            key={`${panel.title}-${counter}`}
            header={panel.title}
            keyHelper={counter}
          >
            {renderPanelInsides(panel.panel, panel.title, null, exportButton)}
          </Panel>
        );
      })}
    </React.Fragment>
  );
};

const CollapseDetail = props => {
  const { data, exportButton } = props;
  return <Collapse>{renderPanels(data, exportButton)}</Collapse>;
};

CollapseDetail.propTypes = {
  data: PropTypes.array.isRequired
};

export default CollapseDetail;
