import ls from "local-storage";
import isDev from "./isDev";
import envVars from "../envVars";
import CustomError from "../CustomError";
const { API_ENDPOINT } = envVars();
const Api = {};

const endpoint = isDev() ? API_ENDPOINT : "/api";

Api.getAvailableReports = async () => {
  try {
    const response = await fetch(`${endpoint}/reports`);
    const reportsTemplateList = await response.json();
    return {
      reports: reportsTemplateList.map(reportTemplate => reportTemplate.code),
      selectedReports: reportsTemplateList
        .filter(reportTemplate => reportTemplate.preSelected)
        .map(reportTemplate => reportTemplate.code)
    };
  } catch (e) {
    throw new CustomError(
      "API-ERROR",
      "Error while getting the Reports. Could't get available reports due to a problem with the server.",
      e.code
    );
  }
};

Api.createAudit = async auditFormRequest => {
  try {
    const response = await fetch(`${endpoint}/audits/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(auditFormRequest)
    });
    const auditData = await response.json();
    return auditData.id;
  } catch (e) {
    throw new CustomError(
      "API-ERROR",
      "Error while creating Audit. Could't create Audit due to a problem with the server.",
      e.code
    );
  }
};

Api.getAuditData = async auditId => {
  const response = await fetch(`${endpoint}/audits/${auditId}`);
  if (response.status >= 400 && response.status < 600) {
    const notFoundErr = new CustomError(
      "API-ERROR",
      `Error while getting audit ${auditId}. Couldn't get Audit due to a problem with the server.`,
      response.status
    );
    throw notFoundErr;
  }
  const auditData = await response.json();
  return auditData;
};

Api.createSitemaps = async sitemapsRequest => {
  try {
    const response = await fetch(`${endpoint}/sitemaps`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(sitemapsRequest)
    });

    const sitemapData = await response.json();
    return sitemapData.id;
  } catch (e) {
    throw new CustomError(
      "API-ERROR",
      "Error while creating Sitemaps Report. Could't create Sitemaps Report due to a problem with the server",
      e.code
    );
  }
};

Api.getSitemapsData = async sitemapsId => {
  try {
    const response = await fetch(`${API_ENDPOINT}/sitemaps/${sitemapsId}`);
    const sitemapsData = await response.json();
    return sitemapsData;
  } catch (e) {
    throw new CustomError(
      "API-ERROR",
      `Error getting Sitemaps Report ${sitemapsId}. Could't get Sitemaps report due to a problem with the server`,
      e.code
    );
  }
};

Api.postToSlack = data => {
  try {
    return new Promise(async resolve => {
      await fetch(`${endpoint}/slack`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      resolve("MESSAGE SENT");
    });
  } catch (e) {
    throw new CustomError(
      "API-ERROR",
      "Error while posting to Slack. Could't post to slack due to a problem with the server",
      e.code
    );
  }
};

Api.postToFirebase = () => `${endpoint}/firebase/storage`;

Api.getUser = () => {
  if (isDev()) return { email: "dev@dowjones.com" };

  try {
    return ls.get("okta-token-storage");
  } catch (e) {
    throw new CustomError(
      "API-ERROR-AUTH",
      `Error while getting auth User`,
      e.code
    );
  }
};

Api.setUser = user => {
  try {
    ls.set("seo-inspector-user", user);
  } catch (e) {
    throw new CustomError(
      "API-ERROR-AUTH",
      `Error while getting auth User`,
      e.code
    );
  }
};

Api.getUserMetadata = async email => {
  if (isDev()) return { role: "1111" };

  try {
    const userRole = ls.get("seo-inspector-user-role");
    return userRole ? userRole : { role: "0" };
  } catch (e) {
    throw new CustomError(
      "API-ERROR-AUTH",
      `Error while getting auth User ${email}`,
      e.code
    );
  }
};

Api.updateUserRole = role => {
  try {
    ls.set("seo-inspector-user-role", { role });
  } catch (e) {
    throw new CustomError(
      "API-ERROR-AUTH",
      `Error while getting auth User`,
      e.code
    );
  }
};

Api.getGSCHistory = async (auditId, dateRange) => {
  try {
    const response = await fetch(`${endpoint}/gsc/${auditId}/${dateRange}`);
    if (response.status >= 400 && response.status < 600) {
      const notFoundErr = new CustomError(
        "API-ERROR",
        `Error while getting GSC Report for audit ${auditId} and date range ${dateRange}.`,
        response.status
      );
      throw notFoundErr;
    }
    const gscData = await response.json();
    return gscData;
  } catch (e) {
    throw new CustomError(
      "API-ERROR",
      `Error while getting GSC Data for ${auditId} audit and date range ${dateRange}`,
      e.code
    );
  }
};

export { Api };
