import React from "react";
import PropTypes from "prop-types";
import { Icon, Button, Popover } from "antd";
import GoogleResultTitle from "./components/GoogleResultTitle";
import GoogleResultBody from "./components/GoogleResultBody";
import KeywordsOverTimeGraph from "../../../KeywordsOverTimeGraph/KeywordsOverTimeGraph";
import AMPCarouselItem from "../../../AMPCarouselItemSimulator/components/AMPCarouselItem/AMPCarouselItem";
import "./PageResultPreviewer.css";
import { copyButton } from "../../../../assets/svgInJs/google-serp";

const PreviewElementWrapper = props => (
  <div className="preview-wrapper">
    <Icon
      type={props.isValid ? "check" : "close"}
      style={props.isValid ? styles.iconCheck : styles.iconClose}
    />
    {props.children}
  </div>
);

const ToolTipCopyButton = () => (
  <div style={styles.tooltipCopyButton}>
    <span>Copied!</span>
  </div>
);

PreviewElementWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isValid: PropTypes.bool.isRequired
};

const PageResultPreviewer = props => {
  const schema = {
    headline: props.titleResult.text,
    datePublished: props.date,
    image: props.image,
    publisher: {
      ...props.publisher,
      logo: {
        url: props.logo
      }
    }
  };
  return (
    <div id="preview" className="snippet-preview">
      <span className="title-length-test" />
      <div className="preview-inner-wrapper">
        {props.deviceId !== "amp" ? (
          <div>
            <KeywordsOverTimeGraph
              keywords={props.keywords}
              timeRangeOption="one-month"
            />
            <div className="preview-render">
              <PreviewElementWrapper
                label="Title"
                isValid={props.titleResult.isValid}
              >
                <GoogleResultTitle
                  title={props.titleResult.text}
                  siteUrl={props.siteUrl}
                  deviceId={props.deviceId}
                />
              </PreviewElementWrapper>
              <PreviewElementWrapper
                label="Description"
                isValid={props.descriptionResult.isValid}
              >
                <GoogleResultBody
                  description={props.descriptionResult.text}
                  deviceId={props.deviceId}
                  date={props.date}
                />
              </PreviewElementWrapper>
              <div style={styles.copyButtonsContainer}>
                {props.titleResult.isValid ? (
                  <Popover
                    placement="bottom"
                    content={<ToolTipCopyButton />}
                    trigger="click"
                  >
                    <Button
                      style={styles.copyButton}
                      onClick={() => props.onCopy("title")}
                    >
                      <Icon
                        style={styles.copyButtonIcon}
                        component={copyButton}
                      />
                      COPY NEW SEO TITLE
                    </Button>
                  </Popover>
                ) : (
                  <Button
                    style={styles.copyButton}
                    onClick={() => props.onCopy("title")}
                    disabled
                  >
                    <Icon
                      style={styles.copyButtonIcon}
                      component={copyButton}
                    />
                    COPY NEW SEO TITLE
                  </Button>
                )}
                {props.descriptionResult.isValid ? (
                  <Popover
                    placement="bottom"
                    content={<ToolTipCopyButton />}
                    trigger="click"
                    style={{ backgroundColor: "#73CF00" }}
                  >
                    <Button
                      style={styles.copyButton}
                      onClick={() => props.onCopy("description")}
                    >
                      <Icon
                        style={styles.copyButtonIcon}
                        component={copyButton}
                      />
                      COPY NEW SEO DESCRIPTION
                    </Button>
                  </Popover>
                ) : (
                  <Button
                    style={styles.copyButton}
                    onClick={() => props.onCopy("description")}
                    disabled
                  >
                    <Icon
                      style={styles.copyButtonIcon}
                      component={copyButton}
                    />
                    COPY NEW SEO DESCRIPTION
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="preview-render">
            <AMPCarouselItem
              className={"amp-carousel-simulator__previewer"}
              schema={schema}
              hasAMP={props.hasAMP}
            />
          </div>
        )}
      </div>
    </div>
  );
};

PageResultPreviewer.propTypes = {
  titleResult: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  descriptionResult: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  siteUrl: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  date: PropTypes.any
};

const styles = {
  iconCheck: {
    color: "#52c41a",
    fontSize: 24,
    fontWeigth: 900,
    marginRight: "5px"
  },
  iconClose: {
    color: "#FD093F",
    fontSize: 24,
    fontWeigth: 900,
    marginRight: "5px"
  },
  copyButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginLeft: "25px"
  },
  copyButton: {
    backgroundColor: "#FFFFFF",
    color: "#030075",
    fontFamily: "Space Mono",
    letterSpacing: "0.06px",
    borderStyle: "solid",
    borderColor: "#030075",
    borderRadius: 0,
    marginRight: "25px"
  },
  tooltipCopyButton: {
    height: "47px",
    width: "150px",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: 900,
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#73CF00"
  }
};

export default PageResultPreviewer;
