import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, notification } from "antd";
import utils from "../utils";
import { Api } from "../../../../utils/Api";
import Loading from "../../../common/Loading";
import LoadError from "../../../common/LoadError";

class AbstractReport extends Component {
  state = {
    auditId: this.props.auditId,
    auditLoaded: false,
    auditLoadError: false,
    name: "Abstract Report",
    report: null,
    reportName: null,
    showUpdateFailedAlert: false
  };

  renderReport = () => <h3> Abstract Report </h3>;

  openNotification = () => {
    notification.open({
      message: `Problem with ${this.state.name}`,
      description: (
        <div style={styles.alternativeText}>{this.getTimeOutText()}</div>
      ),
      duration: 5
    });
  };

  getTimeOutText = () => (
    <span>
      Hmm... it seems like there is something wrong with this report. Please try
      again later.
    </span>
  );

  renderReloadButton = () => (
    <div
      style={styles.reloadButton}
      onClick={e => {
        e.preventDefault();
        this.setState(
          {
            auditLoaded: false,
            auditLoadError: false
          },
          async () => {
            const getAuditDataResult = await this.getAuditData(
              this.state.auditId
            );
            this.setState(getAuditDataResult);
          }
        );
      }}
    >
      <Icon type="reload" style={styles.iconStyles} />
      <span>
        {`This could take a while... continue loading ${this.state.name}?`}
      </span>
      {this.renderMessageState()}
    </div>
  );

  getAuditData = auditId => {
    return Api.getAuditData(auditId).then(
      auditData => {
        const {
          audit: {
            reports: { nodes }
          }
        } = auditData;
        const report = utils.getReportByName(nodes, this.state.reportName);
        return {
          auditLoaded: true,
          report,
          auditId: auditId,
          showUpdateFailedAlert: false
        };
      },
      error => {
        if (error.code === 404) {
          this.props.history.replace("/404");
        } else {
          return {
            auditLoaded: true,
            auditLoadError: true
          };
        }
      }
    );
  };

  renderMessageState = () => {
    return this.props.timedOuts >= 1 ? this.openNotification() : <div />;
  };

  componentDidMount() {
    const auditId = this.state.auditId || this.props.auditId;
    this.getAuditData(auditId).then(getAuditDataResult =>
      this.setState(getAuditDataResult)
    );
  }

  render() {
    const { report, showUpdateFailedAlert } = this.state;

    let content = null;
    if (this.state.auditLoadError) {
      content = <LoadError />;
    } else if (!this.state.auditLoaded) {
      content = <Loading />;
    } else if (
      !showUpdateFailedAlert &&
      (report.rules.total === 0 && !report.isProcessed)
    ) {
      content = this.renderReloadButton();
    } else {
      content = this.renderReport();
    }

    return <React.Fragment>{content}</React.Fragment>;
  }
}

AbstractReport.propTypes = {
  auditId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

const styles = {
  reloadButton: {
    height: "24px",
    width: "auto",
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "22px",
    cursor: "pointer",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "25px",
    paddingRight: "16px",
    borderColor: "#8A8E9A"
  },
  iconStyles: {
    margin: "0 16px",
    fontSize: "16px",
    fontWeight: "900"
  },
  alternativeText: {
    width: "auto",
    color: "#020065",
    fontFamily: "Avenir",
    fontSize: "13px",
    fontWeight: "700"
  }
};

export default AbstractReport;
