import React from "react";

const collapseBtn = (btnColor, iconColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <pattern
      id="a"
      width="0.6"
      height="0.6"
      x="-0.6"
      y="-0.6"
      patternUnits="userSpaceOnUse"
    >
      <image
        width="6"
        height="6"
        transform="scale(.1)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAABGdBTUEAALGPC/xhBQAAAYRpQ0NQSUNDIFByb2ZpbGUAACiRlZE/SMNAFMa/VKQI9c9QRJwCSqcqtYIoiNB2EKFDLR1UdEivaVqoSUjPqugo6FhwEF0Uuzg461pw1UlRBHFxcS+6aInvmmoUUfGD8H559+7eu+8Az5FimgVPF7Cocys5GZVnZudk7wPaQDkhhRXNSCIRF/wev+r5GpKIlwPirO/rv8qXUYsMkPzEnJkWJ94i7lvmpuBjYr9FQxFXBWsOXwlOO/zYqEklY8SvxB0lptFej484pGfyOvE48QTLKRnideJg+lO99omdeRrqjSmFfNpSuJqRhTUxo2BYRVNh6j8v+Ze4usJFjBnmqpXXclyOkJOqPKWzwaAcDg2NAeJdnOradMNvqfvczRkHwOgT0FJ2c+kd4HQT6Ll1c/37QOcGcHLGlqxSs70E6u541vyXPgg/seNrQyNA5QZIrQHxC2B3Dwhkqc88kGinPE3PF9zPUTE7HHbO8kWB1nvbrgUA7zZQL9v2y6Ft1yt0nzugqr8BKXRvl0Cjze0AAAAWSURBVAgdY2BgYPgPxUAKAZgQTPqzACB/AgGN23nBAAAAAElFTkSuQmCC"
      />
    </pattern>
    <g fill="none" fillRule="evenodd">
      <path fill="url(#a)" d="M0 0h32v32H0z" />
      <path fill={btnColor} d="M4 4h24v24H4z" />
      <path
        fill={iconColor}
        d="M15.167 13.333V12H16.5v1.333zm1.333 1.334v-1.334h1.333v1.334zm-2.667 0v-1.334h1.334v1.334zm4 1.333v-1.333h1.334V16zM12.5 16v-1.333h1.333V16zm6.667 1.333V16H20.5v1.333zm-8 0V16H12.5v1.333zm9.333 1.334v-1.334h1.333v1.334zm-10.667 0v-1.334h1.334v1.334zm12 1.333v-1.333h1.334V20zM8.5 20v-1.333h1.333V20z"
      />
    </g>
  </svg>
);

const expandBtn = (btnColor, iconColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <pattern
      id="a"
      width="0.6"
      height="0.6"
      x="-0.6"
      y="-0.6"
      patternUnits="userSpaceOnUse"
    >
      <image
        width="6"
        height="6"
        transform="scale(.1)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAABGdBTUEAALGPC/xhBQAAAYRpQ0NQSUNDIFByb2ZpbGUAACiRlZE/SMNAFMa/VKQI9c9QRJwCSqcqtYIoiNB2EKFDLR1UdEivaVqoSUjPqugo6FhwEF0Uuzg461pw1UlRBHFxcS+6aInvmmoUUfGD8H559+7eu+8Az5FimgVPF7Cocys5GZVnZudk7wPaQDkhhRXNSCIRF/wev+r5GpKIlwPirO/rv8qXUYsMkPzEnJkWJ94i7lvmpuBjYr9FQxFXBWsOXwlOO/zYqEklY8SvxB0lptFej484pGfyOvE48QTLKRnideJg+lO99omdeRrqjSmFfNpSuJqRhTUxo2BYRVNh6j8v+Ze4usJFjBnmqpXXclyOkJOqPKWzwaAcDg2NAeJdnOradMNvqfvczRkHwOgT0FJ2c+kd4HQT6Ll1c/37QOcGcHLGlqxSs70E6u541vyXPgg/seNrQyNA5QZIrQHxC2B3Dwhkqc88kGinPE3PF9zPUTE7HHbO8kWB1nvbrgUA7zZQL9v2y6Ft1yt0nzugqr8BKXRvl0Cjze0AAAAWSURBVAgdY2BgYPgPxUAKAZgQTPqzACB/AgGN23nBAAAAAElFTkSuQmCC"
      />
    </pattern>
    <g fill="none" fillRule="evenodd">
      <path fill="url(#a)" d="M0 0h32v32H0z" />
      <path fill={btnColor} d="M4 4h24v24H4z" />
      <path
        fill={iconColor}
        d="M16.5 18.667V20h-1.333v-1.333zm-1.333-1.334v1.334h-1.334v-1.334zm2.666 0v1.334H16.5v-1.334zm-4-1.333v1.333H12.5V16zm5.334 0v1.333h-1.334V16zM12.5 14.667V16h-1.333v-1.333zm8 0V16h-1.333v-1.333zm-9.333-1.334v1.334H9.833v-1.334zm10.666 0v1.334H20.5v-1.334zM9.833 12v1.333H8.5V12zm13.334 0v1.333h-1.334V12z"
      />
    </g>
  </svg>
);

const helpBtn = (btnColor, iconColor, size = "32") => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 32 32"
  >
    <defs>
      <pattern
        id="b"
        width="0.6"
        height="0.6"
        x="-0.6"
        y="-0.6"
        patternUnits="userSpaceOnUse"
      >
        <use transform="scale(.1)" xlinkHref="#a" />
      </pattern>
      <image
        id="a"
        width="6"
        height="6"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAABGdBTUEAALGPC/xhBQAAAYRpQ0NQSUNDIFByb2ZpbGUAACiRlZE7SANBEIb/i0gQ4qMIolYHSqooMYIoiJCkECFFDClUtLhXHhDvjssaFS0FLQMWoo1iGgtrbQO2WimKIDY29kEbDefsXTQRUfGHY76bnd2Z/RfwHEummfd0AUs6s5JTUXF2bl70PqINvXAkKQUzkkjEOX/Er3q5gcDj1SA/6/v6r/KpWkEBBD8xU0yLEW8T968wk/MJsd+ioYgrnDMuX3OWXX5yalLJGPEbcUdRydBej484pKs5nXiCeFLJSirxBnFQbqrPNLE7j6O+mJTPyZbENFXk1sSMvGEVTEnR/nnJv8S0VcZjzDDXrFwmy8QIOamJ07oyFBTDoeFxgL+LW12dcfwWui8aOeMQGHsGWkqNnLwLnG0BPXeN3MAB0LkJnJ4ry1ax3l4AdXc9q/8Ln4Sf2PXV0ShQvgVS60D8EtjbBwJp6rMAJNopT9OzxcbnqpAeCbtn+aJA64NtVwOAdweolWz79ci2a2W6zz1Q0d8BQFdvoKquGHwAAAAWSURBVAgdY2BgYPgPxUAKAZgQTPqzACB/AgGN23nBAAAAAElFTkSuQmCC"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      transform="translate(-1191 -430) translate(368 410) translate(823 20)"
    >
      <path fill="url(#a)" d="M0 0H32V32H0z" />
      <path fill={btnColor} d="M4 4H28V28H4z" />
      <path
        fill={iconColor}
        d="M13 16v1h-1v-1h1zm0-4v2h-1v-2h1zm1-2v1h-1v-1h1zm-4-2v2H9V8h1zm5 0v2h-1V8h1zm-1-1v1h-4V7h4z"
        transform="translate(4 4)"
      />
    </g>
  </svg>
);

const LightBulb = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M9.333 12.667H6.667a.333.333 0 000 .666h2.666a.333.333 0 000-.666zm0 1.333H6.667a.333.333 0 000 .667h2.666a.333.333 0 000-.667zm.167 1.333h-3l.792.522a.621.621 0 00.41.145h.597a.622.622 0 00.41-.145l.791-.522zM12 6.134c0 2.38-2.143 3.989-2.143 5.866H6.143C6.143 10.123 4 8.513 4 6.134c0-2.482 1.999-3.848 3.998-3.848 2 0 4.002 1.367 4.002 3.848zm2.667-.46v.914h-1.692c.013-.149.025-.299.025-.454 0-.158-.011-.31-.023-.46h1.69zm-7.099-4.37V0h.914v1.31a5.153 5.153 0 00-.914-.005zm-2.495.863L4.379.874l.806-.432.691 1.289a5.18 5.18 0 00-.803.436zm7.057 1.157l1.295-.93.532.744-1.369.982a4.465 4.465 0 00-.458-.796zm-2.007-1.593l.692-1.289.806.432-.694 1.294a5.175 5.175 0 00-.804-.437zM3.411 4.12l-1.369-.981.533-.744 1.294.929c-.176.244-.33.509-.458.796zm9.138 4.149l1.57.636-.343.847-1.617-.655c.14-.264.272-.541.39-.828zm-8.738.77l-1.571.706-.375-.834 1.56-.701c.116.289.248.563.386.829zm-.786-2.451H1.333v-.915h1.69c-.012.151-.023.303-.023.461 0 .155.012.305.025.454z"
      transform="translate(-1029 -702) translate(768 486) translate(0 16) translate(0 192) translate(260 8)"
    />
  </svg>
);

export { collapseBtn, expandBtn, helpBtn, LightBulb };
