import React, { Component } from "react";
import PropTypes from "prop-types";
import KeywordsOverTimeGraph from "../../../KeywordsOverTimeGraph/KeywordsOverTimeGraph";
import TableDetail from "./components/TableDetail/TableDetail";
import TextDetail from "./components/TextDetail/TextDetail";
import TreeDetail from "./components/TreeDetail/TreeDetail";
import MarkdownDetail from "./components/MarkdownDetail/MarkdownDetail";
import GoogleSERPSimulatorDetail from "./components/GoogleSERPSimulatorDetail/GoogleSERPSimulatorDetail";
import AmpCarouselItemDetail from "./components/AMPCarouselItemDetail/AMPCarouselItemDetail";
import CollapseDetail from "./components/CollapseDetail/CollapseDetail";
import ListDetail from "./components/ListDetail/ListDetail";
import ImageDetail from "./components/ImageDetail/ImageDetail";
import SubtitleDetail from "./components/SubtitleDetail/SubtitleDetail";
import ButtonDetail from "./components/ButtonDetail/ButtonDetail";

class Detail extends Component {
  render() {
    switch (this.props.type) {
      case "text":
        return <TextDetail id="detail-text" data={this.props.data} />;
      case "keywords-graph-detail":
        return (
          <div>
            <KeywordsOverTimeGraph
              keywords={this.props.data.keywords}
              timeRangeOption="one-month"
            />
          </div>
        );
      case "table":
        return (
          <TableDetail
            title={this.props.data.title}
            columns={this.props.data.columns}
            data={this.props.data.data}
          />
        );
      case "tree":
        return (
          <TreeDetail
            parent={this.props.data.request}
            children={this.props.data.children}
          />
        );
      case "markdown":
        return (
          <div style={styles.textStyle}>
            <MarkdownDetail>{this.props.data.markdown}</MarkdownDetail>
          </div>
        );
      case "serp-simulator":
        return <GoogleSERPSimulatorDetail data={this.props.data} />;
      case "collapse":
        return (
          <CollapseDetail
            data={this.props.data}
            exportButton={this.props.exportButton}
          />
        );
      case "image":
        const { src } = this.props.data;
        return <ImageDetail src={src} />;
      case "amp-carousel-item":
        return <AmpCarouselItemDetail data={this.props.data} />;
      case "list":
        const { header, list, footer } = this.props.data;
        return (
          <ListDetail list={list} listHeader={header} listFooter={footer} />
        );
      case "subtitle":
        const { text } = this.props.data;
        return <SubtitleDetail text={text} />;
      case "button":
        return (
          <ButtonDetail
            id="detail-button"
            text={this.props.data.text}
            onClickAction={this.props.data.onClickAction}
            extra={this.props.data.extra}
          />
        );
      default:
        return (
          <span>
            NO RENDER DEFINED FOR {this.props.type || "NO TYPE"} DETAIL
          </span>
        );
    }
  }
}

Detail.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

const styles = {
  textStyle: {
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: "13px",
    lineHeight: "24px",
    backgroundColor: "#FFF",
    padding: "10px",
    marginTop: "10px"
  }
};

export default Detail;
