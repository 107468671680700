import React, { Component } from "react";
import { Modal } from "antd";
import Slack from "../../../utils/SlackUtils";
import PopUpForm from "../PopUpForm";
import FeedbackForm from "./FeedbackForm";
import PropTypes from "prop-types";

class FeedbackModal extends Component {
  state = {
    isVisible: false,
    loading: false,
    fields: {
      name: {
        value: ""
      },
      email: {
        value: ""
      },
      message: {
        value: ""
      },
      images: {
        value: []
      },
      satisfaction: {
        value: ""
      }
    }
  };

  constructor(props) {
    super(props);
    this.setModalVisible = this.setModalVisible.bind(this);
    this.resetModal = this.resetModal.bind(this);
  }

  submitFeedback = () => {
    const { fields } = this.state;
    this.setState({ loading: true });
    Slack.sendMessage(fields).then(() => {
      this.resetModal();
    });
  };

  setModalVisible(isVisible) {
    this.setState({ isVisible });
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };

  resetModal() {
    const newFields = {
      name: {
        value: ""
      },
      email: {
        value: ""
      },
      message: {
        value: ""
      },
      images: {
        value: []
      },
      satisfaction: {
        value: ""
      }
    };
    this.setState({ isVisible: false, loading: false, fields: newFields });
  }

  render() {
    const { fields } = this.state;
    return (
      <Modal
        centered
        maskClosable
        visible={this.state.isVisible}
        onOk={() => this.submitFeedback()}
        onCancel={() => this.resetModal()}
        maskStyle={{ backgroundColor: "rgba(2, 0, 71, 0.8)" }}
        footer={null}
        closable={false}
        bodyStyle={{
          padding: 0,
          margin: 0
        }}
        width="auto"
      >
        <PopUpForm
          headerText="Contact us"
          closeButton
          onCloseClick={this.resetModal}
          height="auto"
        >
          <FeedbackForm
            {...fields}
            onChange={this.handleFormChange}
            submitFunc={this.submitFeedback}
          />
        </PopUpForm>
      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  user: PropTypes.object
};

FeedbackModal.defaultProps = {
  user: {
    nickname: "",
    name: "",
    picture: "",
    updated_at: "",
    email: "",
    email_verified: true,
    sub: ""
  }
};

export default FeedbackModal;
