import React from "react";
import { Alert } from "antd";

const LoadError = () => {
  return (
    <Alert
      style={styles.loadError}
      message="Sever not responding"
      description="There is either problem with Internet connection or with our server. Please, check you connection or try again"
      type="error"
      showIcon
    />
  );
};

const styles = {
  loadError: {
    marginTop: 200
  }
};

export default LoadError;
