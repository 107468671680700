import React from "react";
import { Menu } from "antd";
import PropTypes from "prop-types";

const RoleMenu = props => {
  const showModal = () => {
    props.modalComponent.current.setModalVisible(true);
  };

  return (
    <Menu style={styles.menu}>
      <Menu.Item>
        <div
          onClick={async e => {
            e.preventDefault();
            props.closeAction();
            showModal();
          }}
        >
          <span style={styles.roleMenuText}>Change role</span>
        </div>
      </Menu.Item>
    </Menu>
  );
};

RoleMenu.propTypes = {
  closeAction: PropTypes.func.isRequired
};

const styles = {
  menu: {
    borderColor: "#030075",
    borderStyle: "solid",
    borderWidth: "1px"
  },
  roleMenuText: {
    color: "#000079",
    font: "Avenir",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "24px"
  },
  roleMenuTextDisabled: {
    color: "#8A8E9A",
    font: "Avenir",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "24px"
  }
};

export default RoleMenu;
