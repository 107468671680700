import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

class HeaderAudit extends Component {
  state = {
    title: this.props.title,
    url: this.props.url,
    urlLinkHovered: false
  };

  toggleUrlLinkHover = newState => {
    this.setState({ urlLinkHovered: newState });
  };

  render() {
    return (
      <div style={styles.titleUrl}>
        <span style={styles.titleContent}>{this.state.title}</span>
        <a
          style={
            this.state.urlLinkHovered
              ? styles.URLContentHover
              : styles.URLContent
          }
          href={this.state.url}
          rel="noreferrer noopener"
          target="_blank"
          onMouseEnter={e => {
            e.preventDefault();
            this.toggleUrlLinkHover(true);
          }}
          onMouseLeave={e => {
            e.preventDefault();
            this.toggleUrlLinkHover(false);
          }}
        >
          {this.state.url}
          <Icon style={{ margin: "0 8px" }} type="link" />
        </a>
      </div>
    );
  }
}

HeaderAudit.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

HeaderAudit.defaultPropTypes = {
  title: "No Title Found",
  url: "https://notFound.example"
};

const styles = {
  titleUrl: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center"
  },
  titleContent: {
    fontFamily: "Avenir",
    fontSize: "18px",
    fontWeight: 800,
    color: "#000000"
  },
  URLContentHover: {
    fontFamily: "Avenir",
    fontSize: "15px",
    color: "#010067"
  },
  URLContent: {
    fontFamily: "Avenir",
    fontSize: "15px",
    color: "#000000"
  }
};

export default HeaderAudit;
