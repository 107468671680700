import React from "react";
import { Row } from "antd";
import Report from "../../../audit/Report";
import AbstractReport from "./AbstractReport";
import GoogleSERPSimulatorDetail from "../ruleResult/components/GoogleSERPSimulatorDetail/GoogleSERPSimulatorDetail";

class Google extends AbstractReport {
  state = {
    name: "Google Results",
    reportName: "SERP Report"
  };

  renderReport = () => {
    const { report: reportData } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Report
            name={this.state.name}
            score={reportData.rules.score}
            rulesTotal={reportData.rules.total}
            rulesPassedCount={reportData.rules.passed}
            rulesFailedCount={reportData.rules.failed}
            rulesData={reportData.rules.nodes}
            isProcessed={reportData.isProcessed}
            resultListType="serp"
            preRuleInserts={
              <GoogleSERPSimulatorDetail data={reportData.extras} />
            }
          />
        </Row>
      </React.Fragment>
    );
  };
}

export default Google;
