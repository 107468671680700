import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { closeSquare } from "../../assets/svgInJs/side-menu";

class PopUpForm extends Component {
  state = {
    containerPopUp: {},
    closeButtonHover: false
  };

  componentDidMount() {
    const { height, width } = this.props;
    this.setState({
      containerPopUp: {
        ...styles.containerPopUp,
        height,
        width
      }
    });
  }

  toogleHover = hoverState => {
    this.setState(hoverState);
  };

  render() {
    return (
      <div style={this.state.containerPopUp}>
        <div style={styles.headerContyainer}>
          <span style={styles.headerText}>{this.props.headerText}</span>
          <div style={styles.headerLineContainer}>
            <div style={styles.headerLine} />
            <div style={styles.headerLine} />
          </div>
          {this.props.closeButton ? (
            <div
              style={styles.closeButton}
              onClick={this.props.onCloseClick}
              onMouseEnter={e => {
                e.preventDefault();
                this.toogleHover({ closeButtonHover: true });
              }}
              onMouseLeave={e => {
                e.preventDefault();
                this.toogleHover({ closeButtonHover: false });
              }}
            >
              <Icon
                style={styles.iconStyle}
                component={() => {
                  if (this.state.closeButtonHover) {
                    return closeSquare("#fff", "#030075");
                  }
                  return closeSquare("#030075", "#fff");
                }}
              />
            </div>
          ) : null}
        </div>
        <div style={styles.formContainer}>{this.props.children}</div>
      </div>
    );
  }
}

const styles = {
  containerPopUp: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 21px -2px rgba(0,0,0,0.47)"
  },
  headerContyainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "56px",
    width: "100%",
    backgroundColor: "#30C6D6",
    padding: "16px"
  },
  headerText: {
    color: "#FFFFFF",
    fontFamily: "Avenir",
    fontSize: "24px",
    fontWeight: 800,
    lineHeight: "28px",
    whiteSpace: "nowrap"
  },
  headerLine: {
    boxSizing: "border-box",
    height: "1.75px",
    border: "1px solid #FFFFFF",
    width: "100%"
  },
  headerLineContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    width: "100%",
    padding: "4px 10px 0 10px"
  },
  closeButton: {
    ursor: "pointer",
    border: "none",
    width: "26px"
  },
  formContainer: {
    width: "100%",
    padding: "40px 80px"
  }
};

PopUpForm.propTypes = {
  headerText: PropTypes.string.isRequired,
  closeButton: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  onCloseClick: PropTypes.func
};

PopUpForm.defaultProps = {
  height: "632px",
  width: "480px",
  onCloseClick: () => {}
};

export default PopUpForm;
