import React, { Component } from "react";
import moment from "moment";
import { DatePicker } from "antd";
import PropTypes from "prop-types";

import { getCalendarIcon } from "../../assets/svgInJs/calendar";
import "./DateFilter.css";

const DATE_PRESETS = [
  {
    title: "7 days",
    amount: 7,
    unit: "d"
  },
  {
    title: "14 days",
    amount: 14,
    unit: "d"
  },
  {
    title: "30 days",
    amount: 30,
    unit: "d"
  },
  {
    title: "3 months",
    amount: 3,
    unit: "M"
  },
  {
    title: "6 months",
    amount: 6,
    unit: "M"
  }
];
const DATE_FORMAT = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.onDateSelected = this.onDateSelected.bind(this);
  }

  onDateSelected(fromNow, rangeDate) {
    const { onDateSelect } = this.props;
    let startDate, endDate, preset;

    if (fromNow) {
      const { amount, unit } = fromNow;
      preset = `${amount}-${unit}`;
      const now = moment();
      endDate = now.format(DATE_FORMAT);
      startDate = now.subtract(amount, unit).format(DATE_FORMAT);
    } else if (rangeDate) {
      startDate = rangeDate[0].format(DATE_FORMAT);
      endDate = rangeDate[1].format(DATE_FORMAT);
      preset = `custom=${startDate}_${endDate}`;
    }

    if (fromNow || rangeDate) {
      onDateSelect(preset, startDate, endDate);
    }
  }

  getPresetTimeButtons(selectedPreset) {
    return DATE_PRESETS.map(preset => {
      const style =
        selectedPreset === `${preset.amount}-${preset.unit}`
          ? styles.presedTimeButtonSelected
          : null;
      return (
        <button
          key={`${preset.amount}-${preset.unit}`}
          className={"dateFilterButtonContainer"}
          onClick={() =>
            this.onDateSelected({ amount: preset.amount, unit: preset.unit })
          }
          style={style}
        >
          <span className="dateFilterButtonLabel" style={style}>
            {preset.title}
          </span>
        </button>
      );
    });
  }

  getCalendarIcon() {
    return (
      <i
        aria-label="icon: calendar"
        className="anticon anticon-calendar ant-calendar-picker-icon"
      >
        {getCalendarIcon()}
      </i>
    );
  }

  render() {
    const { style, selectedFilter } = this.props;
    let preset, defaultRange;
    if (selectedFilter && !selectedFilter.includes("custom")) {
      preset = selectedFilter;
    } else if (selectedFilter) {
      const customDates = selectedFilter.replace("custom=", "").split("_");
      const startDate = moment(customDates[0], DATE_FORMAT);
      const endDate = moment(customDates[1], DATE_FORMAT);
      defaultRange = [startDate, endDate];
    }
    const defaultTimes = this.getPresetTimeButtons(preset);

    return (
      <div className="dateFilterRow" style={style || {}}>
        <div className="dateFilterColumn">
          <span className="dateFilterLabel">Time Frame</span>
          <div>{defaultTimes}</div>
        </div>
        <div className="dateFilterColumn" style={{ flexGrow: 1 }}>
          <span className="dateFilterLabel">Custom Range</span>
          <RangePicker
            format="MMM DD, YYYY"
            separator="|"
            style={styles.calendar}
            onChange={dates => this.onDateSelected(null, dates)}
            defaultValue={defaultRange}
            suffixIcon={this.getCalendarIcon()}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  calendar: {
    font: "Space Mono Regular",
    fontSize: "12px",
    color: "#000000",
    border: "1px solid #030075"
  },
  presedTimeButtonSelected: {
    backgroundColor: "#030075",
    color: "white"
  }
};

DateFilter.protoType = {
  onDateSelect: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default DateFilter;
