import React from "react";
import PropTypes from "prop-types";
import DevicesRepository from "../../../services/DevicesRepository";
import cc from "classnames";
import "./GoogleResultsTitle.css";

const deviceIdsList = DevicesRepository.getAllIds();

const GoogleResultTitle = props => {
  const getTitleDeviceClass = deviceId =>
    ({
      desktop: "preview_title_desktop",
      tablet: "preview_title_tablet",
      iPad: "preview_title_ipad",
      iPhone: "preview_title_iphone",
      amp: "amp-simulator"
    }[deviceId]);

  const getURLRoot = url => {
    const match = url.match(/(https:|http:)\/\/([a-zA-Z0-9 | .-]*)\//);
    return match ? match[0] : "";
  };

  const cleanURL = url => url.replace(/\?.*/, "");

  const getBreadcrumbsString = (items = []) => {
    return items.reduce((string, item) => {
      if (item && item !== "") {
        if (items.indexOf(item) === 0) {
          string = item;
        } else {
          string = `${string} › ${item}`;
        }
      }
      return string;
    }, "");
  };

  const getURLBreadcrumbs = props => {
    const { siteUrl, deviceId } = props;
    const root = getURLRoot(siteUrl).replace(/https:\/\/|http:\/\//, "");
    const remainderCleanRegex = new RegExp(`(https://|http://)${root}`, "g");
    const remainderURL = cleanURL(siteUrl).replace(remainderCleanRegex, "");
    const items = [root.slice(0, -1), ...remainderURL.split(/\/|\?/)];

    return (
      <div className={cc("breadcrums", getTitleDeviceClass(deviceId))}>
        {getBreadcrumbsString(items)}
      </div>
    );
  };
  return (
    <div style={styles.titleWrapper}>
      <div id="preview_url" className="url_google">
        {getURLBreadcrumbs(props)}
      </div>
      <div
        id="preview_title"
        className={cc(
          "a_tag_google",
          getTitleDeviceClass(props.deviceId),
          "preview_title"
        )}
      >
        {props.title}
      </div>
    </div>
  );
};

GoogleResultTitle.propTypes = {
  title: PropTypes.string.isRequired,
  device: PropTypes.oneOf(deviceIdsList)
};

const styles = {
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
};

export default GoogleResultTitle;
