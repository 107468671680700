import React, { Component } from "react";
import PropTypes from "prop-types";

class CommonCheck extends Component {
  render() {
    const { isChecked, checkboxStyle, iconStyle, onCheck, name } = this.props;
    return (
      <button className={checkboxStyle} onClick={() => onCheck(name)}>
        {isChecked && <div className={iconStyle} />}
      </button>
    );
  }
}

CommonCheck.propTypes = {
  isChecked: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checkboxStyle: PropTypes.string,
  iconStyle: PropTypes.string.isRequired
};

export default CommonCheck;
