import React from "react";
import CommonButton from "../../../../../common/CommonButton";

const copyToClipboard = text => {
  navigator.clipboard.writeText(text);
};

const other = () => {
  // console.log("other");
};

const ButtonDetail = props => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.buttonDiv}>
        <CommonButton
          onClick={() => {
            if (props.onClickAction === "copyToClipBoard") {
              copyToClipboard(props.extra);
            } else {
              other();
            }
          }}
        >
          {props.text}
        </CommonButton>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    marginBottom: 30
  },
  buttonDiv: {
    width: 615,
    height: 30
  }
};

export default ButtonDetail;
