import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Api } from "../../../../../../utils/Api";
import Loading from "../../../../../common/Loading";
import DateFilter from "../../../../../GscAnalyticsStats/DateFilter";
import HeaderStat from "../../../../../GscAnalyticsStats/HeaderStat";
import AnalyticsGraph from "../../../../../GscAnalyticsStats/AnalyticsGraph";

import "./GscAnalyticsStats.css";

const STATS = {
  clicks: {
    title: "Total Clicks",
    color: "#5085ED"
  },
  impressions: {
    title: "Total Impressions",
    color: "#5639AC"
  },
  ctr: {
    title: "Average CTR",
    color: "#3B867B"
  },
  position: {
    title: "Average Position",
    color: "#B744B6"
  }
};

const DATE_RANGES_IN_DAYS = [
  {
    days: 7,
    key: "7-d"
  },
  {
    days: 14,
    key: "14-d"
  },
  {
    days: 30,
    key: "30-d"
  },
  {
    days: 92,
    key: "3-M"
  },
  {
    days: 184,
    key: "6-M"
  }
];
class GscAnalyticsStats extends Component {
  state = {
    dataGraph: this.props.data.extras.data,
    lines: ["clicks", "impressions"],
    presetDate: this.getPresetDate(this.props.data.extras.data),
    errorLoadingMoreData: false,
    isLoadingMoreData: false
  };

  constructor(props) {
    super(props);
    this.onCheck = this.onCheck.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
  }

  getPresetDate(dataGraph = this.state.dataGraph) {
    if (dataGraph && dataGraph.length > 0) {
      const olderData = dataGraph[0].date;
      const recentData = dataGraph[dataGraph.length - 1].date;
      const dateRange = moment(recentData).diff(moment(olderData), "days");
      if (dateRange < DATE_RANGES_IN_DAYS[0].days) {
        return `custom=${moment(olderData).format("YYYY-MM-DD")}_${moment(
          recentData
        ).format("YYYY-MM-DD")}`;
      }

      const preset = DATE_RANGES_IN_DAYS.find(({ days }) => dateRange < days);
      return preset ? preset.key : "6-M";
    }
    return this.state.presetDate || "6-M";
  }

  onCheck(stat) {
    let { lines } = this.state;
    const index = lines.indexOf(stat);
    if (index > -1) {
      lines.splice(index, 1);
    } else {
      lines.push(stat);
    }
    this.setState({ ...this.state, lines });
  }

  async onDateSelect(presetDate, startDate, endDate) {
    try {
      this.setState({ isLoadingMoreData: true });
      const gscData = await Api.getGSCHistory(
        this.props.auditId,
        `${startDate}_${endDate}`
      );
      if (gscData.extras.message) {
        this.setState({
          isLoadingMoreData: false,
          errorLoadingMoreData: true,
          presetDate
        });
      } else {
        this.setState({
          ...this.state,
          presetDate,
          dataGraph: gscData.extras.data,
          isLoadingMoreData: false,
          errorLoadingMoreData: false
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        isLoadingMoreData: false,
        errorLoadingMoreData: true,
        presetDate
      });
    }
  }

  getStatName(statName) {
    let name;
    const filterStat = Object.keys(STATS).filter(
      statKey => STATS[statKey].title === statName
    );
    if (filterStat && filterStat.length > 0) {
      name = filterStat[0];
    }

    return name;
  }

  getHeaderComponent(lineData) {
    const name = this.getStatName(lineData.name);
    const isChecked = this.state.lines.includes(name);
    const color = STATS[name].color;

    return (
      <HeaderStat
        key={name}
        title={lineData.name}
        value={lineData.details[0].data.text}
        background={color}
        name={name}
        description={lineData.description || ""}
        isChecked={isChecked}
        onCheck={this.onCheck}
      />
    );
  }

  getSerieObject(reportData) {
    const name = this.getStatName(reportData.name);

    return {
      key: name,
      color: STATS[name].color,
      name: STATS[name].title,
      yAxisId: name === "clicks" ? "totalClicks" : "totalImpressions"
    };
  }

  getSeries() {
    const { lines } = this.state;
    return this.props.data.nodes
      .filter(data => {
        const name = this.getStatName(data.name);
        return lines.includes(name);
      })
      .map(data => this.getSerieObject(data));
  }

  render() {
    const {
      dataGraph,
      presetDate,
      errorLoadingMoreData,
      isLoadingMoreData
    } = this.state;
    if (isLoadingMoreData) return <Loading />;
    const series = this.getSeries();
    const statHeaders = this.props.data.nodes.map(data =>
      this.getHeaderComponent(data)
    );
    return (
      <div className="reportContainer">
        <div style={{ textAlign: "left" }}>
          <DateFilter
            style={{ marginBottom: "48px" }}
            selectedFilter={presetDate}
            onDateSelect={this.onDateSelect}
          />
          <div className="statHeaders">{statHeaders}</div>
          <AnalyticsGraph
            data={dataGraph}
            lines={series}
            errorLoadingMoreData={errorLoadingMoreData}
          />
        </div>
      </div>
    );
  }
}

GscAnalyticsStats.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.array,
    extras: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.number.isRequired,
          ctr: PropTypes.number,
          clicks: PropTypes.number,
          impressions: PropTypes.number,
          position: PropTypes.number
        })
      )
    })
  }).isRequired
};

export default GscAnalyticsStats;
