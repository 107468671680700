export const Products = [
  { WSJ: "WSJ" },
  { BRR: "Barrons" },
  { MW: "Marketwatch" }
];

export const Channels = [
  { FB: "Facebook" },
  { TW: "Twitter" },
  { DSP: "Display" },
  { GOOG: "Google" },
  { DSC: "Discover" }
];

export const Teams = [{ BRD: "Brand" }];

export const Campaigns = [{ EL20: "Elections 2020" }];
