import React, { Component } from "react";
import { Popover, Icon } from "antd";
import Proptypes from "prop-types";
import { LightBulb } from "../../assets/svgInJs/panel-btns";
import CommonMarkdown from "./CommonMarkdown";

class CommonTooltip extends Component {
  getLightBulbIcon = () => (
    <Icon component={LightBulb} style={styles.lightBulbIcon} />
  );

  getTooltipFooter = () => (
    <div style={styles.footerContainer}>
      <div style={styles.footerLineContainer}>
        <div style={{ ...styles.footerLine, ...styles.lineTop }} />
        <div style={{ ...styles.footerLine, ...styles.borderBottom }} />
      </div>
      {this.getLightBulbIcon()}
      <div style={styles.footerLineContainer}>
        <div style={{ ...styles.footerLine, ...styles.lineTop }} />
        <div style={{ ...styles.footerLine, ...styles.borderBottom }} />
      </div>
    </div>
  );

  getTooltipTextColumn = (header, content) => (
    <div style={styles.textColumn}>
      <div style={styles.textColumnHeader}>{header}</div>
      <div style={styles.textColumnContent}>
        <CommonMarkdown linkStyle={styles.links}>{content}</CommonMarkdown>
      </div>
    </div>
  );

  getPopoverContent = () => {
    const { columns } = this.props;

    return (
      <div style={styles.tooltipContainer}>
        <div style={styles.contentContainer}>
          {columns.map((column, i) => {
            return (
              <div key={`column${column.header}_${i}`}>
                {this.getTooltipTextColumn(column.header, column.content)}
              </div>
            );
          })}
        </div>
        {this.getTooltipFooter()}
      </div>
    );
  };

  render() {
    const { children } = this.props;
    return (
      <Popover
        placement="bottomRight"
        content={this.getPopoverContent()}
        trigger="click"
        style={styles.popover}
      >
        {children}
      </Popover>
    );
  }
}

CommonTooltip.propTypes = {
  columns: Proptypes.arrayOf(
    Proptypes.shape({
      header: Proptypes.string,
      content: Proptypes.string
    })
  )
};

CommonTooltip.defaultProps = {
  columns: [
    {
      header: "About",
      content:
        "Some placeholder text about a rule. The rule provides a way for webmasters to tell search engines “don’t follow links on this page” or “Don’t follow specific links."
    },
    {
      header: "Best Practice",
      content:
        "Some best practice placeholder. Keep Googlebot on your site, and so your SEO strategy is not only dependent upon boosting your ranking but maintaining it and protecting it."
    }
  ]
};

const styles = {
  popover: {
    padding: 0,
    backgroundColor: "#aae8f0"
  },
  tooltipContainer: {
    maxWidth: "536px",
    display: "flex",
    flexDirection: "column"
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row"
  },
  textColumn: {
    display: "flex",
    flexDirection: "column",
    minWidth: "216px",
    margin: "24px"
  },
  textColumnHeader: {
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontWeight: 800,
    lineHeight: "24px"
  },
  textColumnContent: {
    color: "#000000",
    fontFamily: "Avenir",
    fontSize: "14px",
    lineHeight: "24px"
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "32px",
    width: "100%",
    backgroundColor: "#30C6D6",
    padding: "8px 0"
  },
  footerLine: {
    boxSizing: "border-box",
    height: "1px",
    borderBottom: "1px solid #FFFFFF",
    width: "100%"
  },
  footerLineContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    width: "100%"
  },
  lineTop: {
    marginTop: "3px"
  },
  lineBottom: {
    marginBottom: "3px"
  },
  lightBulbIcon: {
    height: "16px",
    width: "13.33px",
    margin: "0 4px"
  },
  links: {
    color: "#030075",
    textDecoration: "underline"
  }
};

export default CommonTooltip;
