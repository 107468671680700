import React, { Component } from "react";

class SubTitle extends Component {
  render() {
    return (
      <div style={styles.subTitleWrap}>
        <div style={styles.leftLines}>
          <hr style={styles.line1} />
          <hr style={styles.line1} />
        </div>
        <span style={styles.subText}>
          Validate and improve your article's ranking
        </span>
        <div style={styles.rightLines}>
          <hr style={styles.line1} />
          <hr style={styles.line1} />
        </div>
      </div>
    );
  }
}

const styles = {
  subTitleWrap: {
    paddingTop: "2%",
    paddingBottom: "1%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#30c6d6"
  },
  line1: {
    height: 1,
    boxSizing: "border-box",
    border: "1px solid #FFFFFF"
  },
  subText: {
    display: "flex",
    flexWrap: "wrap",
    flex: 2,
    width: 416,
    color: "#FFFFFF",
    fontFamily: "Avenir",
    letterSpacing: 0.2,
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
    justifyContent: "center"
  },
  leftLines: {
    flex: 2.3,
    paddingRight: 0
  },
  rightLines: {
    flex: 2.3,
    paddingLeft: 0
  }
};

export default SubTitle;
