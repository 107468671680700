const createDescriptionValidation = ({ maxDescriptionLength } = {}) => {
  return rawDescription => {
    const isValid =
      rawDescription.length <= maxDescriptionLength &&
      rawDescription.length > 0;
    let description = rawDescription;
    if (!isValid) {
      while (description.length > maxDescriptionLength) {
        description = description.substring(0, description.lastIndexOf(" "));
      }
    }

    return {
      isValid,
      text: description.trim(),
      charactersRemaining: maxDescriptionLength - rawDescription.length
    };
  };
};

export default createDescriptionValidation;
