import React, { Component } from "react";
import PropTypes from "prop-types";
import GoogleTrendsGraph from "./components/GoogleTrendsGraph/GoogleTrendsGraph";

const timeRangeOptions = {
  "one-month": "today 1-m",
  "one-year": "today 12-m"
};

const createGraphComparisonItems = (keywords, timeRangeOption) => {
  const time = timeRangeOptions[timeRangeOption];
  return keywords.map(keyword => ({ keyword, geo: "US", time }));
};

const trendsFilter = {
  exploreQuery: "date=today%201-m&geo=US&q=WSJ",
  guestPath: "https://trends.google.com:443/trends/embed/"
};

const areTheSameKeywords = (list1, list2) => {
  if (list1.length !== list2.length) {
    return false;
  }
  const list1Set = new Set(list1);
  return list2.every(element => {
    return list1Set.has(element);
  });
};

class KeywordsOverTimeGraph extends Component {
  state = {
    comparisonItem: [],
    trendsSpecification: null
  };

  createTrendsSpecification = (keywords, timeRangeOption) => {
    return {
      category: 0,
      property: "",
      comparisonItem: createGraphComparisonItems(keywords, timeRangeOption)
    };
  };

  componentDidMount() {
    this.setState({
      trendsSpecification: this.createTrendsSpecification(
        this.props.keywords,
        this.props.timeRangeOption
      )
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !areTheSameKeywords(this.props.keywords, prevProps.keywords) ||
      this.props.timeRangeOption !== prevProps.timeRangeOption
    ) {
      this.setState({
        trendsSpecification: this.createTrendsSpecification(
          this.props.keywords,
          this.props.timeRangeOption
        )
      });
    }
  }

  render() {
    const { trendsSpecification } = this.state;

    return (
      trendsSpecification && (
        <GoogleTrendsGraph
          trendsGraphType="TIMESERIES"
          trendsSpecification={trendsSpecification}
          trendsFilter={trendsFilter}
        />
      )
    );
  }
}

KeywordsOverTimeGraph.propTypes = {
  keywords: PropTypes.array.isRequired,
  timeRangeOption: PropTypes.oneOf(["one-month", "one-year"]).isRequired
};

export default KeywordsOverTimeGraph;
