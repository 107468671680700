import PropTypes from "prop-types";

export const trendsGraphTypeType = PropTypes.oneOf([
  "TIMESERIES",
  "RELATED_QUERIES_0"
]);

export const trendsSpecificationType = PropTypes.shape({
  comparisonItem: PropTypes.array.isRequired,
  category: PropTypes.number.isRequired,
  property: PropTypes.string.isRequired
});

export const trendsFilterType = PropTypes.shape({
  exploreQuery: PropTypes.string.isRequired,
  guestPath: PropTypes.string.isRequired
});
