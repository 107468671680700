import React from "react";
import AbstractReport from "./AbstractReport";
import PropTypes from "prop-types";
import { Row } from "antd";
import Report from "../../Report";
import SchemaMarkupDetail from "../ruleResult/components/SchemaMarkupDetail/SchemaMarkupDetail";

const RULE_NAME = "Schema Validation Rule";

class Schema extends AbstractReport {
  state = {
    name: "Schema Markup Report",
    reportName: "SEO Report"
  };

  renderReport = () => {
    const { report } = this.state;
    const { url } = this.props;
    let score = 0,
      passed = 0,
      failed = 1;
    const schemaRule = report.rules.nodes.find(rule => rule.name === RULE_NAME);
    if (schemaRule && schemaRule.passed) {
      score = 100;
      passed = 1;
      failed = 0;
    }
    const rule = { ...schemaRule, url };

    return (
      <Row>
        <Report
          name="Schema Markup"
          isProcessed={report.isProcessed}
          score={score}
          rulesData={report.rules.nodes}
          rulesTotal={1}
          rulesPassedCount={passed}
          rulesFailedCount={failed}
          resultListType="serp"
          preRuleInserts={<SchemaMarkupDetail rule={rule} />}
        />
      </Row>
    );
  };
}

Schema.propTypes = {
  auditId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired
};

export default Schema;
