import React from "react";
import PropTypes from "prop-types";
import GoogleSERPSimulator from "../../../../../GoogleSERPSimulator/GoogleSERPSimulator";
import getImageSrcFromSchema from "../../../../../AMPCarouselItemSimulator/services/getImageSrcFromSchema";

const getPublisherWLogo = schema => {
  if (schema.logo && schema.logo.url) {
    return {
      name: "",
      logo: {
        url: schema.logo.url
      }
    };
  }
  if (schema.publisher) {
    const { publisher } = schema;
    if (publisher.logo) {
      return {
        name: publisher.name ? publisher.name : "",
        logo: {
          url: publisher.logo.url
        }
      };
    }
  }
  return {
    name: "",
    logo: {
      url: "No Logo image found"
    }
  };
};

const GoogleSERPSimulatorDetail = props => {
  const { data } = props;
  const {
    rules: { titleMaxLength, descriptionMaxLength },
    data: { title, description, url, date, schema }
  } = data;
  const imageUrl =
    (schema.image
      ? getImageSrcFromSchema(schema.image)
      : getImageSrcFromSchema(schema.thumbnailUrl)) ||
    "No image URL founded in Schema";

  const publisher = getPublisherWLogo(schema);
  const { hasAMP } = schema;
  return (
    <GoogleSERPSimulator
      maxTitleLength={titleMaxLength}
      maxDescriptionLength={descriptionMaxLength}
      defaultTitle={title}
      defaultDescription={description}
      defaultSiteUrl={url}
      date={date}
      schemaImage={imageUrl}
      schemaPublisher={publisher}
      hasAMP={hasAMP}
    />
  );
};

GoogleSERPSimulatorDetail.propTypes = {
  data: PropTypes.shape({
    rules: PropTypes.shape({
      titleMaxLength: PropTypes.number.isRequired,
      descriptionMaxLength: PropTypes.number.isRequired
    }),
    data: PropTypes.shape({
      headline: PropTypes.string,
      descriptionResult: PropTypes.string,
      url: PropTypes.string
    })
  })
};

export default GoogleSERPSimulatorDetail;
