import React from "react";
import { Layout } from "antd";
import { Switch, Route, withRouter } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import "./theme.css";
import isDev from "./utils/isDev";
import Start from "./components/Start";
import Audit from "./components/Audit";
import colors from "./constants/colors";
import CampaignCode from "./components/CampaignCode";
import PageNotFound from "./components/PageNotFound";
import Login from "./components/Login";
import { clientConfig } from "./AuthConfig";
import { Api } from "./utils/Api";
import envVars from "./envVars";
import userAuthInfoConversions from "./utils/userAuthInfoConversions";

const { Header, Content } = Layout;

const oktaAuth = new OktaAuth(clientConfig);

const {
  REACT_APP_GOOGLE_ANALYTCS_ID,
  REACT_APP_GOOGLE_TAG_MANAGER_ID
} = envVars();

const initializeReactGA = () => {
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTCS_ID);
  ReactGA.pageview("/homePage");
};

const tagManagerArgs = {
  gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID,
  dataLayer: {}
};

const App = props => {
  const { history } = props;

  const onAuthRequired = authService => {
    if (isDev()) {
      authService.emitter.e.authStateChange[0].fn({
        isAuthenticated: true,
        idToken: "dev",
        accessToken: "dev"
      });
    } else {
      history.push("/login");
    }
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const getUser = info => {
    if (info.idToken.claims) {
      const { email, name, auth_time } = info.idToken.claims;
      return {
        name: name,
        email: email,
        lastLogin: auth_time,
        company: userAuthInfoConversions.getPublicationNameThroughEmail(email)
      };
    }

    return {
      name: "",
      email: "",
      lastLogin: 0,
      company: ""
    };
  };

  const initTags = info => {
    const userInfo = getUser(info);
    tagManagerArgs.dataLayer = userInfo;
  };

  const info = Api.getUser();
  initTags(info);
  initializeReactGA();
  TagManager.initialize(tagManagerArgs);

  return (
    <React.Fragment>
      <Layout>
        <Header style={styles.header} />
        <Content style={styles.content}>
          <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
            onAuthRequired={onAuthRequired}
          >
            <Switch>
              <Route exact path="/login/callback" component={LoginCallback} />
              <Route exact path="/login" component={Login} />
              <SecureRoute exact path="/" component={Start} />
              <SecureRoute exact path="/start" component={Start} />
              <SecureRoute path="/audit/:auditId/dashboard" component={Audit} />
              <SecureRoute path="/audit/:auditId/trends" component={Audit} />
              <SecureRoute path="/audit/:auditId/google" component={Audit} />
              <SecureRoute path="/audit/:auditId/analytics" component={Audit} />
              <SecureRoute path="/audit/:auditId/social" component={Audit} />
              <SecureRoute path="/audit/:auditId/seo" component={Audit} />
              <SecureRoute
                path="/audit/:auditId/performance"
                component={Audit}
              />
              <SecureRoute path="/audit/:auditId/speed" component={Audit} />
              <SecureRoute path="/audit/:auditId/mobile" component={Audit} />
              <SecureRoute path="/audit/:auditId/sitemaps" component={Audit} />
              <SecureRoute path="/audit/:auditId/schema" component={Audit} />
              <Route path="/campaigns" component={CampaignCode} />
              <Route component={PageNotFound} />
            </Switch>
          </Security>
        </Content>
      </Layout>
    </React.Fragment>
  );
};

const styles = {
  header: {
    zIndex: 1,
    position: "fixed",
    top: 0,
    width: "10%",
    opacity: 0
  },
  content: {
    backgroundColor: colors.mainBackround
  },
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: "16px 50px",
    backgroundColor: "#FCFCFC",
    opacity: "0.3",
    textAlign: "right"
  }
};

export default withRouter(App);
