import React from "react";
import { Row } from "antd";
import Report from "../../Report";
import AbstractReport from "./AbstractReport";

class Social extends AbstractReport {
  state = {
    name: "Social Shares Report",
    reportName: "Social Report"
  };

  renderReport = () => {
    const { report } = this.state;
    return (
      <div>
        <Row>
          <Report
            name="Social Tags"
            isProcessed={true}
            score={report.rules.score}
            rulesData={report.rules.nodes}
            rulesTotal={report.rules.total}
            rulesPassedCount={report.rules.passed}
            rulesFailedCount={report.rules.failed}
            resultListType="seo"
          />
        </Row>
      </div>
    );
  };
}

export default Social;
