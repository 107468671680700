import React, { Component } from "react";
import { Input, DatePicker } from "antd";
import AMPCarouselItem from "./components/AMPCarouselItem/AMPCarouselItem";

import "./AMPCarouselItemSimulator.css";
import DetailForm from "../DetailForm/DetailForm";
import DetailFormItem from "../DetailForm/components/DetailFormItem/DetailFormItem";
import DetailFormLabel from "../DetailForm/components/DetailFormLabel/DetailFormLabel";
import SchemaPropTypes from "./services/SchemaPropTypes";
import moment from "moment";
import getImageSrcFromSchema from "./services/getImageSrcFromSchema";

class AMPCarouselItemSimulator extends Component {
  state = {
    headline: this.props.schema.headline,
    logo: this.props.schema.publisher.logo.url,
    image: getImageSrcFromSchema(this.props.schema.image),
    datePublished: this.props.schema.datePublished
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleDatePublishedChange = value => {
    this.setState({
      datePublished: value.format()
    });
  };

  render() {
    const { headline, image, logo, datePublished } = this.state;
    const schema = {
      ...this.props.schema,
      headline,
      image: {
        url: image
      },
      publisher: {
        ...this.props.schema.publisher,
        logo: {
          url: logo
        }
      },
      datePublished
    };
    const { hasAMP } = this.props.schema;
    const datePublishedWithMoment = moment(datePublished);
    return (
      <div>
        <DetailForm>
          <DetailFormItem>
            <DetailFormLabel htmlFor="headline">Title:</DetailFormLabel>
            <Input
              id="headline"
              onChange={this.handleChange}
              value={headline}
            />
          </DetailFormItem>
          <DetailFormItem>
            <DetailFormLabel htmlFor="image">Image: </DetailFormLabel>
            <Input id="image" value={image} onChange={this.handleChange} />
          </DetailFormItem>
          <DetailFormItem>
            <DetailFormLabel htmlFor="logo">Logo:</DetailFormLabel>
            <Input id="logo" value={logo} onChange={this.handleChange} />
          </DetailFormItem>
          <DetailFormItem>
            <DetailFormLabel htmlFor="timeSincePublished">
              Publish Date:
            </DetailFormLabel>
            <DatePicker
              id="datePublished"
              allowClear={false}
              showTime={true}
              defaultValue={datePublishedWithMoment}
              onOk={this.handleDatePublishedChange}
            />
          </DetailFormItem>
        </DetailForm>
        <div>
          <AMPCarouselItem
            className={"amp-carousel-simulator__previewer"}
            schema={schema}
            hasAMP={hasAMP}
          />
        </div>
      </div>
    );
  }
}

AMPCarouselItemSimulator.propTypes = {
  schema: SchemaPropTypes
};

export default AMPCarouselItemSimulator;
