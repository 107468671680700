import React from "react";
import CommonMarkdown from "../../../../../common/CommonMarkdown";
import { Icon } from "antd";

const TextDetail = props => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.left}>
        <span
          style={
            props.data.passed
              ? styles.testDescriptionPassed
              : styles.testDescriptionFailed
          }
        >
          <CommonMarkdown>{props.data.text}</CommonMarkdown>
        </span>
      </div>
      <div style={styles.right}>
        <Icon
          type={props.data.passed ? "check" : "close"}
          style={props.data.passed ? styles.iconCheck : styles.iconClose}
        />
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    marginBottom: 10
  },
  left: {
    flex: "0 0 85%",
    display: "flex",
    alignItems: "center",
    minHeight: 56,
    backgroundColor: "#FFF",
    padding: 20,
    marginRight: 10
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF"
  },
  testDescriptionPassed: {
    fontFamily: "Space Mono",
    fontSize: 13
  },
  testDescriptionFailed: {
    fontFamily: "Space Mono",
    fontSize: 13,
    color: "#FD093F"
  },
  iconCheck: {
    color: "#52c41a",
    fontSize: 16,
    fontWeigth: "bold"
  },
  iconClose: {
    color: "#FD093F",
    fontSize: 16,
    fontWeigth: "bold"
  }
};

export default TextDetail;
