import React, { Component } from "react";
import scoreGuide from "../../assets/images/scoreGuide.png";

class GoogleScoreGuide extends Component {
  render() {
    const styled = { width: "70%" };
    return <img src={scoreGuide} alt="score guide" style={styled} />;
  }
}

export default GoogleScoreGuide;
