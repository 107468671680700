export default [
  {
    type: "serp",
    isOpenByDefault: true
  },
  {
    type: "seo",
    isOpenByDefault: true
  },
  {
    type: "trends",
    isOpenByDefault: true
  },
  {
    type: "performance",
    isOpenByDefault: false
  }
];
